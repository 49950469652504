import React, { useEffect, useRef } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import './PartPreview.css'
import * as THREE from 'three';


function Model({scene, explosionFactor, positions, onSceneLoaded, setParts, rotateDirection }) {
    const modelRef = useRef();
    const { camera } = useThree();

    useEffect(()=> {
        console.log('rotateDirection',rotateDirection)
        if (camera && !rotateDirection) {
            // Position the camera
            camera.position.set(0, 0, 5);
            camera.lookAt(0, 0, 0);
        }
    })

    // Smoothly interpolate between original and exploded positions on each frame
    // useFrame(() => {
    //     if (!positions || positions.length === 0) return; // Ensure positions exist
    //     positions.forEach(({ child, originalPosition, explodedPosition }) => {
    //         if (!child || !originalPosition || !explodedPosition) return; // Ensure all data exists
    //         // Interpolate between original and exploded positions based on explosion factor
    //         const targetPosition = new THREE.Vector3().lerpVectors(
    //             originalPosition,
    //             explodedPosition,
    //             explosionFactor // This value controls the extent of the explosion
    //         );
    //         child.position.lerp(targetPosition, 0.5); // Smoothly transition toward the target position
    //     });
    // });

    return (
        scene ? <primitive object={scene} ref={modelRef} /> :null
    )
}

export default Model