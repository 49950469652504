import React, { useState, useEffect } from "react";
import "../DepartmentPages/Department.css";
import "./TicketList.css";
import axios from "axios";
import { BASE_URL, PHOTO_LINK, TKTVIEW } from "../../constants/config";
import Cookies from "js-cookie";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import DepartmentTicketPopup from "../DepartmentPages/DepartmentTicketPopup";
import Loading from "../Common/Loading/Loading";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import DepartmentForm from "../DepartmentPages/DepartmentForm";
import { useTour } from "@reactour/tour";
import NoTemplatePopUp from "../TicketComponent/TemplateConfirm/NoTemplatePopUp";
import NameProfile from "../Common/CommonJsx/NameProfile";
import DateFormateForList from "../Common/CommonJsx/DateFormateForList";
import Pagination from "../Common/CommonJsx/Pagination";
import { debounce } from "@mui/material";
import SearchFilter from "../Common/CommonJsx/SearchFilter";



function AllTicketList({ type }) {
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const [departmentList, setDepartmentList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [memberDetails, setMembersDetails] = useState([])
  const [memberId, setMemberiD] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false);
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noTemplate, setNoTemplate] = useState(false);
  const [templateLength, setTemplateLength] = useState("");
  const [fromDepartment, setFromDepartment] = useState(false);
  const { route } = useParams()
  // Check local storage for tour completion status
  // const isTourCompleted = localStorage.getItem('tourCompleted');
  const { setIsOpen, setSteps, isOpen, setCurrentStep } = useTour();

  const handleNextClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousClick = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleDone = () => {
    setIsOpen(false);
    Cookies.set("ticketTourCompleted", true, { expires: 365 });
  };

  const ticketSteps = [
    {
      selector: '[data-tour="step-8"]',
      content: (
        <div>
          <p>Create your new ticket</p>
          {/* <p>
            Create your new ticket by choosing department and fill the fields
          </p> */}
          <div className="tour-btns">
            <button onClick={() => handleNextClick()} className="tour-next">
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="step-9"]',
      content: (
        <div>
          <p>Click to view tickets</p>
          {/* <p>Click to view tickets assigned to you</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleNextClick()} className="tour-next">
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="step-10"]',
      content: (
        <div>
          <p>Filter ticktes by assignee</p>
          {/* <p>Click on assignees to check the tickets assigned </p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleNextClick()} className="tour-next">
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="step-11"]',
      content: (
        <div>
          <p>Reset</p>
          {/* <p>Click on Reset to view all Tickets</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleNextClick()} className="tour-next">
              Next
            </button>
          </div>
        </div>
      ),
    },
    {
      selector: '[data-tour="step-depart"]',
      content: (
        <div>
          <p>Click to view Departments</p>
          {/* <p>Click to view list of Departments created</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleDone()} className="tour-next">
              Done
            </button>
          </div>
        </div>
      ),
    },
  ];
  const emptyTicketSteps = [
    {
      selector: '[data-tour="step-8"]',
      content: (
        <div>
          <p>Create your new ticket</p>

          {/* <p>
            Create your new ticket by choosing department and fill the fields
          </p> */}
          <div className="tour-btns">
            <button onClick={() => handleNextClick()} className="tour-next">
              Next
            </button>
          </div>
        </div>
      ),
    },

    {
      selector: '[data-tour="step-depart"]',
      content: (
        <div>
          <p>Click to view Departments</p>
          <br />
          {/* <p>Click to view list of Departments created</p> */}
          <div className="tour-btns">
            <button onClick={() => handlePreviousClick()} className="tour-back">
              Back
            </button>
            <button onClick={() => handleDone()} className="tour-next">
              Done
            </button>
          </div>
        </div>
      ),
    },
  ];
  const [personId, setPersonId] = useState(
    JSON.parse(localStorage.getItem("memberDetails")).id
  );

  const [hasOpened, setHasOpened] = useState(false);
  // const {member_id}=useParams();

  const nav = useNavigate();
  const { id } = useParams();

  const handeleclose = () => {
    setNoTemplate(false);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setIsPopupFormOpen(false);
  };
  const handleTitleClick = (event, department) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(department.data.defaultFieldsData.ticket_title.value);
  };

  const HandleTitleClose = () => {
    setTicketPopUp(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };



  const allTickets = async () => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };




      // Check if the tour is opening

      const responseTemplate = await axios.get(BASE_URL + "/v1/template/get-list-temp", { headers });
      const requireData = responseTemplate.data.data.template_list.length;

      setTemplateLength(requireData)
      let response;

      response = await axios.post(
        `${BASE_URL}/v1/ticket/get-ticket`,
        {
          department_id: id, member_id: memberId || personId, page: currentPage,  // Default to first page
          limit: itemsPerPage,  // Default limit of 10 items per page
          search: searchTerm
        },
        {
          headers,
        }
      );

      console.log(response)

      const details = response.data.data.ticket_details;
      console.log(details)
      setLength(details.length);
      setDepartmentList(details);
      setMembersDetails(response.data.data.unique_owners);
      setTotalPages(response.data.data.total_pages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleOpenPopup = () => {

    if (templateLength > 0) {
      if (id) {
        setIsPopupFormOpen(true)
        setFromDepartment(true)
      } else
        setIsPopupOpen(true);
    } else {
      setNoTemplate(true)
    }
  }
  // const handleOpenEcForm = () => {
  //   setIsPopupFormOpen(!isPopupFormOpen)
  // }

  // useEffect(() => {
  //   allTickets();
  // }, [id, memberId, personId, isOpen, hasOpened]);

  useEffect(() => {
    if (searchTerm !== "") {
      debounce(allTickets)()
    } else {
      allTickets();
    }
  }, [id, searchTerm, memberId, personId, isOpen, hasOpened, currentPage]);

  // const handleNextPage = () => {
  //   if (currentPage < Math.ceil(departmentList.length / itemsPerPage)) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }; 



  const handleTicketView = (itemId) => {
    // ec-ticket-view

    nav(TKTVIEW(route, itemId));

  };


  const handleHoverTour = () => {
    const tourCompleted = localStorage.getItem("ticketTourCompleted");
    if (!tourCompleted) {
      setIsOpen(true);
      setSteps(length > 0 ? ticketSteps : emptyTicketSteps);

      setHasOpened(true);
    }
    if (tourCompleted === true) {
      setIsOpen(false);
    }

    // Check if the tour is closing after being opened
    if (!isOpen && hasOpened) {
      setIsOpen(false);

      Cookies.set("ticketTourCompleted", true, { expires: 365 });
    }
  };

  const visibleMembers = memberDetails.slice(0, 4);
  const hiddenMembers = memberDetails.slice(4);
  const handleMemberSelect = (selectedOption) => {

    if (memberId === selectedOption) {
      setMemberiD("");
      setPersonId("");
      setReset(!reset);
    } else {
      setMemberiD(selectedOption);
      setPersonId(personId);
      setReset(true);
    }

    // Set the selected member.

    setShowDropdown(false);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleReset = async () => {

    // setDepartmentId('')
    setSearchTerm('')
    setMemberiD('')
    setPersonId('')



    // setReset(false)
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  function getBackgroundColor(priorityValue) {
    switch (priorityValue) {
      case "P0":
        return "#DA2370"; // or any other color you Prefer
      case "P1":
        return "#EE962F"; // another color
      case "P2":
        return "#00B884"; // and so on...
      case "P3":
        return "#5696F2";
      case "P4":
        return "#5696F2";

      default:
        return "white"; // a default color if none of the priorities match
    }
  }
  const { department } = useParams();

  return (
    <>
      {/* <TicketTemplateTopNav/> */}

      <>
        <>
          <div
            className="depart-main"
            style={{ marginTop: "80px" }}

          >
            <>
              {" "}
              <div className="spn-depart">
                {id ? (
                  <>
                    <div className="no-ticket-view-right">
                      {/* <ArrowBackIcon onClick={handleBack} style={{ cursor: 'pointer' }} />
                        <span>Programs / </span> */}
                      <span style={{ color: "#610BEE" }}>{department}</span>
                      <span> Ticket Progress</span>
                    </div>
                    {/* <div className='no-ticket-progress'>
                Ticket Progress
              </div> */}
                  </>
                ) : (
                  <>
                    <span>Tickets</span>
                  </>
                )}

                {/* {length < 1 ? (
                    ""
                  ) : (
                    <> */}
                <button
                  className="tkt-btn"
                  onClick={handleOpenPopup}
                  data-tour="step-8"
                >
                  <AddOutlinedIcon />
                  {/* New Ticket */}
                  New Ticket
                </button>
                {/* </>
                  )} */}
              </div>
            </>

            <div className="tck-fltr">


              {/* <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='tickets..'/> */}


              <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='tickets..' />


              {/* {id ? '' : <> */}
              <div className="members-photo-details" data-tour="step-10">
                {visibleMembers.map((item, id) => (
                  <div
                    key={item.member_id}
                    className={`member-item ${item.member_id === (personId ? personId : memberId)
                      ? "active"
                      : ""
                      }`}
                  >

                    <div onClick={() => {
                      handleMemberSelect(item.member_id);
                    }} >
                      {item.photo ? (
                        <img
                          onClick={() => {
                            handleMemberSelect(item.member_id);
                          }}
                          src={PHOTO_LINK + item.photo}
                          alt={item.name}
                          style={{
                            border: `3px solid ${item.member_id === (personId ? personId : memberId)
                              ? "#610BEE"
                              : "white"
                              }`,
                            borderRadius: "50%",
                            width: "32px",
                            height: "32px",
                            cursor: "pointer",
                          }}
                        />
                      ) :
                        <div style={{
                          border: `3px solid ${item.member_id === (personId ? personId : memberId)
                            ? "#610BEE"
                            : "white"
                            }`, borderRadius: '50%', cursor: 'pointer'

                        }}>
                          <NameProfile userName={item.fullName} width="32px" fontweight='500' borderRadius='50%' />
                        </div>

                      }
                    </div>



                    <span className="member-name">{item.fullName}</span>
                  </div>
                ))}

                {hiddenMembers.length > 0 && (
                  <div className="member-item">
                    <button
                      onClick={toggleDropdown}
                      className="show-more-button"
                    >
                      +{hiddenMembers.length}
                    </button>
                    {showDropdown && (
                      <div className="members-drop-div">
                        {hiddenMembers.map((item, id) => (
                          <div
                            key={id}
                            className="members-dropdown"
                            onClick={() => {

                              handleMemberSelect(item.member_id);
                            }}
                          >

                            <div onClick={() => {
                              handleMemberSelect(item.member_id);
                            }} style={{
                              border: `3px solid ${item.member_id === (personId ? personId : memberId)
                                ? "#610BEE"
                                : "white"
                                }`,

                            }}>
                              <NameProfile userName={item.fullName} memberPhoto={item.photo} width="32px" fontweight='500' />

                            </div>

                            <span>{item.fullName}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* </>} */}
              <div>
                {/* {reset ? <button className="reset-btn" onClick={handleReset}>reset <TuneOutlinedIcon /></button> : ''} */}
                {visibleMembers.length > 0 ? (
                  <button
                    className="reset-btn"
                    onClick={handleReset}
                    data-tour="step-11"
                  >
                    reset <TuneOutlinedIcon />
                  </button>
                ) : (
                  ""
                )}
              </div>

            </div>


            <div className="department-table">
              <table className="department-table-data">
                <thead>
                  <tr>
                    <th className="id-header">ID</th>
                    <th className="depart-header">Ticket title </th>
                    {/* <th className="lead-header">owner</th> */}
                    <th className="tck-header">Assignee</th>
                    {/* <th className="tck-header">Created on</th> */}
                    <th className="tck-header">Due date</th>
                    <th className="tck-header">Priority</th>
                  </tr>
                </thead>
                {loading ? <Loading /> : <tbody>
                  {departmentList
                    .map((department, item) => (
                      <tr
                        key={item}
                        onClick={() => handleTicketView(type === 'template' ? department.sequence_id : department._id)}
                        style={{ cursor: "pointer" }}
                        data-tour="step-9"
                      >
                        <td>{department.sequence_id}</td>
                        <td
                          title={
                            department.data.defaultFieldsData.ticket_title
                              .value
                          }
                        >
                          {/* {department.data.defaultFieldsData.ticket_title.value} */}
                          {department.data.defaultFieldsData.ticket_title
                            .value.length > 30 ? (
                            <>
                              {department.data.defaultFieldsData.ticket_title.value.slice(
                                0,
                                30
                              )}
                              ...
                              <button
                                className="view-more-text"
                                onClick={(event) =>
                                  handleTitleClick(event, department)
                                }
                              >
                                view
                              </button>
                            </>
                          ) : (
                            department.data.defaultFieldsData.ticket_title
                              .value
                          )}
                        </td>

                        <td>
                          <div
                            className="member-option"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <NameProfile userName={department.data.defaultFieldsData.assignee.value.fullName}
                              memberPhoto={department.data.defaultFieldsData.assignee.value
                                .photo} width="26px" fontweight='500' />


                            <span className="member-fullname">
                              {
                                department.data.defaultFieldsData.assignee.value
                                  .fullName
                              }
                            </span>
                          </div>
                        </td>

                        <td>
                          <div
                            className="member-option"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <span className="member-fullname">

                              <DateFormateForList formateTime={department.data.defaultFieldsData.due_date
                                .value} />
                            </span>
                          </div>
                        </td>
                        <td>
                          <div
                            className="member-option"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >

                            <span
                              className="security-icon"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                backgroundColor: getBackgroundColor(
                                  department.data.defaultFieldsData
                                    .priority.value
                                ),
                              }}
                            >
                              {
                                department.data.defaultFieldsData.priority
                                  .value
                              }
                            </span>
                          </div>
                        </td>

                      </tr>
                    ))}
                </tbody>}

              </table>
            </div>


            {(totalPages > 1 && !loading) && (
              <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            )}
          </div>
          {isPopupOpen && (
            <DepartmentTicketPopup onClose={handleClosePopup} type={type} />
          )}
          {(isPopupFormOpen) && (
            <DepartmentForm onClose={handleClosePopup} departmentId={id} fromDepartment={fromDepartment} />
          )}
          {(ticketPopUp) && (
            <TicketTitlePopUp
              header='Ticket title'
              onClose={HandleTitleClose}
              ticketTitle={ticketTitle}
            />
          )}

          {noTemplate && <NoTemplatePopUp onclose={handeleclose} />}
        </>
      </>

    </>
  );
}

export default AllTicketList;