import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

import axios from "axios";
import "../Profile/Profile.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, ASSET_PREFIX_URL, LOGIN, HEADERS } from "../../constants/config";
import RightContainer from "../Common/RightContainer";
import Logo from "../Common/Logo";

function Bussiness() {
  const [photo, setPhoto] = useState(null);
  const [photoBlob, setPhotoBlob] = useState(null);
  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState("");
  const [size, setSize] = useState("");
  // const [isValidSize, setisValidSize] = useState(true);
  const nav = useNavigate();

let isValidSize=true
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setPhoto(URL.createObjectURL(file));
    setPhotoBlob(file)
  };
  const upload = () => {
    document.getElementById("fileupld").click();
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      nav(LOGIN);
    }

    // Cleanup function: This will be called when the component unmounts
    return () => {
      // Clean up any resources or subscriptions here
    };
  }, [nav]); // Empty dependency array means this effect runs only once on component mount

  function arrayBufferToBase64(arrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  }


  const updateDetails = async (photoBlob, token) => {
    // console.log(photoBlob)
    console.log(token)
    const response = await axios.post(
      BASE_URL + "/v1/member/org-details",
      { photo:photoBlob, orgName, size },
      {
        headers: {
          "x-auth-token": `${token}`,
        },
      }
    );
    let { message, success } = response.data.meta;
    if (success) {
      const { redirect } = response.data.data;
      
      await getMenu()
      window.location.href = redirect;
      // window.location.reload()
    } else {
      alert(message);
    }
  }

  const handleBusiness = async (e) => {
    e.preventDefault();
    if (orgName.trim() === "") {
      setOrgNameError("Business name cannot be empty");
    } else {
      setOrgNameError("");
    }
    // if (/^\d+$/.test(size) && Number(size) >= 1 && Number(size) <= 1000) {
    //   setisValidSize(true);
    // } else {
    //   setisValidSize(false);
    // }

    try {
      // let data;
      if(orgName!==''){
        const token = localStorage.getItem("token");
        if (token) {
          if (photoBlob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const blobData = reader.result;
              updateDetails(arrayBufferToBase64(blobData), token);
  
            };
            reader.readAsArrayBuffer(photoBlob);
          } else {
  
            updateDetails('', token);
          }
  
      }
     
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };
  const getMenu = async () => {
    try {
      const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", {
        headers: HEADERS,
      });
      const data = response.data.data;
      localStorage.setItem(
        "orgDetails",
        JSON.stringify(data.org_details.orgDetails)
      );
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(data.profile_details)
      );
      localStorage.setItem(
        "starredDepartments",
        JSON.stringify(data.star_depart)
      );
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  return (
    <div className="profile-page">
      <div className="left-container">
        <div>
          <Logo />
        </div>
        <div
          className="prev"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <a href="/setuppassword" style={{ textDecoration: "none" }}>
            <img src={`${ASSET_PREFIX_URL}previous-btn.svg`} alt="btn" />
          </a>
          <p>Tell us about your business</p>
        </div>
        <div>
          <label>Company logo*</label>
          <div className="profile-con1">
            <div className={photo ? "circle-container1" : "circle-container1 with-background1"}>
              {photo && <img src={photo} alt="Uploaded" className="upd-img" />}
            </div>
            <div className="upload-btn1">
              <button onClick={upload}></button>
              <input
                type="file"
                id="fileupld"
                accept="image/jpeg, image/png, image/gif"
                style={{ display: "none" }}
                onChange={handleFileUpload}
                className="btn-upload"
              />
            </div>
          </div>
        </div>
        <div className="p1">
          <label>Company Name*</label>
          <div className={`inp1 ${orgNameError ? 'error' : ''}`}>
            <input
              type="text"
              placeholder="Enter Your Company Name"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />

          </div>
          <div className="error-cont">
            {orgNameError && (
              <div className="error-message">
                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                {orgNameError}
              </div>
            )}
          </div>

        </div>
        <div className="p1">
          <label>Number of Employees <span style={{fontSize:'12px', fontWeight:'400',color:'grey'}}>(optional)</span></label>
          <div className={`inp1 ${!isValidSize ? 'error' : ''}`}>
            <input
              type="number"
              placeholder="Enter Number of Employees"
              value={size}
              onChange={(e) => setSize(e.target.value)}
            />

          </div>
          <div className="error-cont">
            {!isValidSize && (
              <div className="error-message">
                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                <p>Please Enter Valid Number of Employees </p>
              </div>
            )}
          </div>
        </div>

        <div>
          <button className="nxt-btn" onClick={handleBusiness}>
            Next
          </button>

        </div>
        <div style={{ marginTop: "50px" }}>
          <img src={`${ASSET_PREFIX_URL}track3.svg`} alt="" />
        </div>
      </div>

      {/* <div className="right-container">
        <img src={triangle} alt="" />
      </div> */}
      <RightContainer />
    </div>
  );
}

export default Bussiness;
