import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import "../Signup/Signup.css";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { BASE_URL, ASSET_PREFIX_URL, LOGIN } from "../../constants/config";
import RightContainer from "../Common/RightContainer";
import Logo from "../Common/Logo";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

function Profile() {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [photoBlob, setPhotoBlob] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [country, setCountry] = useState("IN");
  const nav = useNavigate();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setPhotoBlob(file);
    setPhoto(URL.createObjectURL(file));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      nav(LOGIN);
    }
  }, []);

  const handleProfile = async (e) => {
    e.preventDefault();
    if (!fullName) {
      setFullNameError("Full name cannot be empty");
    } else {
      setFullNameError("");
    }

    if (isPhoneNumberEntered) {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, country);
      if (!isValidPhoneNumber(phoneNumber) || !isValidLength(parsedPhoneNumber)) {
        setPhoneNumberError("Please enter a valid phone number");
        return;
      } else {
        setPhoneNumberError("");
      }
    }

    try {
      if (fullName !== "") {
        const token = localStorage.getItem("token");
        if (token) {
          if (photoBlob) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const blobData = reader.result;
              updateDetails(arrayBufferToBase64(blobData), token);
            };
            reader.readAsArrayBuffer(photoBlob);
          } else {
            updateDetails("", token);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const arrayBufferToBase64 = (arrayBuffer) => {
    let binary = "";
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  };

  const getMenu = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", {
        headers,
      });
      const data = response.data.data;
      localStorage.setItem(
        "orgDetails",
        JSON.stringify(data.org_details.orgDetails)
      );
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(data.profile_details)
      );
      localStorage.setItem(
        "starredDepartments",
        JSON.stringify(data.star_depart)
      );
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const updateDetails = async (photoData, token) => {
    const response = await axios.post(
      BASE_URL + "/v1/member/profile-details",
      { photo: photoData, fullName, phoneNumber },
      {
        headers: {
          "x-auth-token": `${token}`,
        },
      }
    );
    let { message, success } = response.data.meta;
    if (success) {
      const { redirect } = response.data.data;
     
      await getMenu();
      window.location.href = redirect;
    } else {
      alert(message);
    }
  };

  const upload = () => {
    document.getElementById("fileupld").click();
  };

  const isValidLength = (parsedPhoneNumber) => {
    if (country === "IN") {
      return parsedPhoneNumber.nationalNumber.length === 10;
    }
    // Add custom length checks for other countries here if necessary
    return true;
  };

  return (
    <div className="profile-page">
      <div className="left-container">
        <div>
          <Logo />
        </div>
        <div className="prev">
          <p>Tell us about yourself</p>
        </div>
        <div>
          <label>Profile photo</label>
          <div className="profile-con">
            <div className={photo ? "circle-container" : "circle-container with-background"}>
              {photo && <img src={photo} alt="Uploaded" className="upd-img" />}
            </div>
            <div className="upload-btn">
              <button onClick={upload}></button>
              <input
                type="file"
                id="fileupld"
                accept="image/jpeg, image/png, image/gif"
                style={{ display: "none" }}
                onChange={handleFileUpload}
                className="btn-upload"
              />
            </div>
          </div>
        </div>
        <div className="p1">
          <label>Full name*</label>
          <div className={`inp1 ${fullNameError ? "error" : ""}`}>
            <input
              type="text"
              placeholder="Enter Your Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="error-cont">
            {fullNameError && (
              <div className="error-message">
                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                {fullNameError}
              </div>
            )}
          </div>
        </div>
        <div className="p1">
          <label>
            Phone number{" "}
            <span style={{ fontSize: "12px", fontWeight: "400", color: "grey" }}>
              (optional)
            </span>
          </label>
          <div className={"inp1"}>
            <PhoneInput
              className="phone-input"
              international
              countryCallingCodeEditable={false}
              defaultCountry="IN"
              value={phoneNumber}
              onChange={(phoneNumber) => {
                setPhoneNumber(phoneNumber || "");
                setIsPhoneNumberEntered(phoneNumber && phoneNumber.length > 0);
              }}
              onCountryChange={setCountry}
            />
          </div>
          <div className="error-cont">
            {phoneNumberError && (
              <div className="error-message">
                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                {phoneNumberError}
              </div>
            )}
          </div>
        </div>
        <div>
          <button className="nxt-btn" onClick={handleProfile}>
            Next
          </button>
        </div>
        <div style={{ marginTop: "50px" }}>
          <img src={`${ASSET_PREFIX_URL}track2.svg`} alt="" />
        </div>
      </div>

      <RightContainer />
    </div>
  );
}

export default Profile;
