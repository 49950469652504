import React, { useState } from 'react';
import { Treebeard } from 'react-treebeard';
import './PartPreview.css'
import { ASSET_PREFIX_URL } from '../../constants/config';

const data = {
  name: 'equa_006-SLDASM',
  toggled: true, // Ensure root node is open
  children: [
    {
      name: 'Défaut',
      toggled: true, // Ensure child node is open
      children: [
        { name: 'Equa_101-1', toggled: true },
        { name: 'Equa_201-1', toggled: true },
        { name: 'Equa_212-1', toggled: true },
        {
          name: 'Equa_203-1',
          toggled: true,
          children: [
            { name: 'Equa_301-1', toggled: true },
            { name: 'Equa_303-9', toggled: true },
          ],
        },
        { name: 'Vis M6x25-5', toggled: true },
      ],
    },
  ],
};

const customStyle = {
  tree: {
    base: {
      backgroundColor: '#ffffff',
      color: 'black',

      padding: '10px',


    },
    node: {
      base: {
        position: 'relative',
      },
      link: {
        cursor: 'pointer',
        position: 'relative',
        padding: '0.25em 0.5em',
        display: 'flex',
        alignItems: 'center',
      },
      activeLink: {
        background: '#b3d4fc',
      },
      toggle: {
        width: 5,
        height: 5,
        marginRight: 10,
      },
      header: {
        base: {
          display: 'inline-block',
          verticalAlign: 'middle',
          color: 'black', // Node text color
        },
        connector: {
          width: '2px',
          height: '12px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0.25em',
          left: '-1em',
        },
        title: {
          lineHeight: '1.5',
          fontSize: '1em',

        },
      },
      subtree: {
        listStyleType: 'none',
        paddingLeft: '1em',
      },
    },
  },
};

const AssemblyTree = ({onclose}) => {
  const [treeData, setTreeData] = useState(data);

  const onToggle = (node, toggled) => {
    node.toggled = toggled;
    setTreeData({ ...treeData });
  };

  return (
    <div className='assembly-parts' >
      <div className='assembly-parts-heads'>
        <span>Assembly Explorer</span>
        <img src={`${ASSET_PREFIX_URL}template-back-btn.svg`} style={{cursor:'pointer'}} onClick={onclose}/>
      </div>

      <Treebeard data={treeData} onToggle={onToggle} style={customStyle} />
    </div>
  );
};

export default AssemblyTree;
