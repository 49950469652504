export const BASE_URL = process.env.REACT_APP_PORT_URL;
export const DEFAULT_PHOTO = "https://marathon-web-assets.s3.ap-south-1.amazonaws.com/profilelogodefault.png"
export const PHOTO_LINK = "https://marathon-org-assets.s3.ap-south-1.amazonaws.com/"
export const DEFAULT_COMPANY_LINK = "https://member-images.s3.ap-south-1.amazonaws.com/upload_1695283928.png"
export const ASSET_PREFIX_URL = "https://marathon-web-assets.s3.ap-south-1.amazonaws.com/"
export const GLB_PREFIX_URL = "https://d1d8a3050v4fu6.cloudfront.net/"
export const  GLB_CHUNK_SIZE = 20971520;
export const TICKET_ATTACHMENT_BUCKET = 'marathon-org-assets'
export const BUCKET = 'marathon-org-assets'
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
export const GOOGLE_CLIENT_SECRET_ID = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;
export const HEADERS = { "x-auth-token": localStorage.getItem('token')}
export const WORKSPACE_VISIBILITY_OPTIONS = [
    { value: "private", label: "Private" },
    { value: "public", label: "Public" },
];
export const viewMap = {
    'template': 'template-fields',
    'ec-template': 'ec-template-fields',
    'supply-template': 'supply-template-fields',
    'po-template': 'purchase-order-template-fields',
    'assets-template': 'assets-template-fields',
    'pc-template': 'pc-template-fields',
    "location":"location-template-fields",
    "warehouse":"warehouse-template-fields"
};




/*
    Add catgory for uploads

*/

export const CATEGORY_UPLOAD_TICKETS = 'tickets';
export const CATEGORY_UPLOAD_PC = 'PC';
export const CATEGORY_UPLOAD_PCI = 'PCI';
export const CATEGORY_UPLOAD_EC = 'EC';
export const CATEGORY_UPLOAD_DEVIATION = 'deviations';
export const CATEGORY_UPLOAD_GRN = 'GRN';
export const CATEGORY_UPLOAD_ASSETS = 'assets';
export const CATEGORY_UPLOAD_PARTS = 'designs';
export const CATEGORY_UPLOAD_SUPPLIER = 'suppliers';
export const CATEGORY_UPLOAD_DESIGNS = 'designs';

export const DETAILS_VIEW_CATEGORY_UPLOAD = {
    'template': CATEGORY_UPLOAD_TICKETS,
    'pc-template': CATEGORY_UPLOAD_PC,
    'pci-view': CATEGORY_UPLOAD_PCI,
    'ec-template': CATEGORY_UPLOAD_EC,
    'ec-deviation': CATEGORY_UPLOAD_DEVIATION
};


/* 
    Add routes for navigation
*/
export const LOGIN = `/login`;
export const PROFILEDETAILS = '/profile-details'
export const DASHBOARD = `/dashboard`;
export const TKTTEMPLATE = `/templates/template`;
export const ECTEMPLATE = `/templates/ec-template`;
export const SUPTEMPLATE = `/templates/supply-chain-templates`;
export const POPTEMPLATE = `/templates/purchase-order-templates`;
export const ASSETPTEMPLATE = `/templates/assets-template`;
export const PCTEMPLATE = `/templates/pc-templates`;
export const PARTNUMBER = `/templates/part-number`;
export const ASSETNUMBER = `/templates/assets-number`;
export const PONUMBER = "/templates/po-number";
export const MBOMATTRIBUTES = "/templates/mbom-attributes";
export const WAREHOUSETEMPLATE = "/templates/warehouse-templates";
export const LOCATIONTEMPLATE = "/templates/location-templates";
export const ORGHOME = "/hr/org-home";
export const INVENTORY = "/supplychain/all-inventory";
export const PRODUCTIONLIST = `/production/production-list`;
export const WORKORDER = "/production/work-order-list";
export const MATERIALLIST = "/production/material-list";
export const GRNLIST = "/supplychain/all-grn-parts";
export const SUPLIST = "/supplychain/all-supplier-list";
export const PRODUCTS = "/production/mbom-list";
export const PCILIST = "/manufacturing/pci-list";
export const LAUNCHEDPARTS = "/manufacturing/launched-parts";
export const PCLIST = "/manufacturing/pc-list";
export const POLIST = "/supplychain/all-po-list";
export const ASSETLIST = "/supplychain/assets-list"
export const ECLIST = "/engineering/all-ec-list"
export const DEVLIST ="/engineering/all-deviation-list";
export const OVERVIEW = "/overview";
export const PARTLIST = "/engineering/part-list";
export const ASSETPARTS = "/assets-parts-list"
export const CREATEASSETPO = '/supplychain/create-asset'
export const WORKSPACES = '/engineering/workspaces'

// export const WAREHOUSETEMPLATEVIEW = 'templates/warehouse-template-fields'



export const WORKSPACE = (id) => `/engineering/workspace/${id}`;
export const DEPLIST = (route) => {
    return `/${route}/depart-list`;
};
export const TKTLIST = (route) => {
    return `/${route}/tickets`;
};
export const SETTINGS = (route) => {
    return `/${route}/settings`;
};
export const TEMPLATEVIEW = (type, params) => {
    return `/templates/${type}/${params}`;
};
export const DEVIATIONVIEW = (id) => {
    return `/engineering/deviation-ticket-view/${id}`;
};
export const ECVIEW = (id) => {
    return `/engineering/ec-ticket-view/${id}`;
};
export const SUPVIEW = (id) => {
    return `/supplychain/supplier-view/${id}`;
};
export const TKTVIEW =(route,id) => {
    return `/${route}/ticket-view/${id}`;
};
export const GRNVIEW =(id) => {
    return `/supplychain/parts-grn-view/${id?id:''}`;
};
export const INTVENTORYVIEW =(id) => {
    return `/supplychain/inventory-log/${id}`;
};
export const MBOMVIEW =(id) => {
    return `/manufacturing/mbom-view/${id}`;
};
export const PCVIEW =(id) => {
    return `/manufacturing/pc-view/${id}`;
};
export const ASSETVIEW =(id) => {
    return `/asset-view/${id}`;
};
export const PARTVIEW =(id) => {
    return `/engineering/part-view/${id}`;
};
export const DEPTTKTVIEW =(route,dept,id) => {
    return `/${route}/department-ticket/${dept}/${id}`;
};
export const MBOMMODIFICATION =(id) => {
    return `/manufacturing/mbom-modification/${id}`;
};
export const EBOMMODIFICATION =(id) => {
    return `/engineering/ebom-modification/${id}`;
};
export const PCIVIEW =(id) => {
    return `/manufacturing/pci-view/${id}`;
};
export const ASSETPOVIEW =(id) => {
    return `/supplychain/po-asset-view/${id}`;
};
export const POVIEW =(id) => {
    return `/supplychain/po-view/${id}`;
};
export const GRNPARTSVIEW =(id) => {
    return `/supplychain/all-grn-parts/${id}`;
};
export const WAREHOUSEVIEW =(id) => {
    return `/wms/warehouse-view/${id}`;
}; 
export const LOCATIONVIEW =(id) => {
    return `/wms/location-view/${id}`;
};
export const LINELOCATIONVIEW =(id) => {
    return `/wms/line-location-view/${id}`;
};
export const INTERNALTRANSFERVIEW =(id) => {
    return `/wms/internal-transfer-view/${id}`;
};
export const WAREHOUSETEMPLATEVIEW =(id) => {
    return `/templates/warehouse-template-fields/${id}`;
};
export const TEMPLATETEMPLATEVIEW =(id) => {
    return `/templates/template-fields/${id}`;
};
export const ECTEMPLATEVIEW =(id) => {
    return `/templates/ec-template-fields/${id}`;
};
export const SUPPLIERTEMPLATEVIEW =(id) => {
    return `/templates/supply-template-fields/${id}`;
};
export const POTEMPLATEVIEW =(id) => {
    return `/templates/purchase-order-template-fields/${id}`;
};
export const ASSETTEMPLATEVIEW =(id) => {
    return `/templates/assets-template-fields/${id}`;
};
export const PCTEMPLATEVIEW =(id) => {
    return `/templates/pc-template-fields/${id}`;
};
export const LOCATIONTEMPLATEVIEW =(id) => {
    return `/templates/location-template-fields/${id}`;
};
export const POCREATE = '/supplychain/create-po'
export const WAREHOUSELIST = '/wms/warehouse-list'
export const LOCATIONLIST = '/wms/location-list'
export const LINELOCATIONLIST = '/wms/line-location-list'
export const ZONESLIST = '/wms/zones-list'
export const LOCATIONINVENTORY = '/wms/inventory-list'
export const INTERNALTRANFERS = '/wms/internaltransfer-list'
export const ENG_WORKSPACES = '/engineering/workspaces'
// `/supplychain/all-grn-parts/${sequence_id.sequence_id}`

// if (type === 'template') {
//     nav(`/template-fields/${id}`);
// } else if (type === 'ec-template') {
//   nav(`/ec-template-fields/${id}`);
// } else if (type === 'supply-template') {
//   nav(`/supply-template-fields/${id}`);
// } else if (type === 'po-template') {
//   nav(`/purchase-order-template-fields/${id}`);
// } else if (type === 'assets-template') {
//   nav(`/assets-template-fields/${id}`);
// } else if (type === 'pc-template') {
//   nav(`/pc-template-fields/${id}`);
// }else if (type === 'warehouse') {
//   nav(`/warehouse-template-fields/${id}`);
// }else if (type === 'location') {
//   nav(`/location-template-fields/${id}`);
// }



