import React, { useState } from 'react'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import MemberDropdown from '../Common/MemberDropdown'
import { BASE_URL, HEADERS, LINELOCATIONVIEW } from '../../constants/config'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function CreateLineLocation({ handleClose }) {
    const [locationName, setLocationName] = useState('')
    const [selectedMember, setSelectedMember] = useState(null)
    const nav = useNavigate()
    const handlePersonSelectChange = (event, selected) => {
        setSelectedMember(selected.value);
    };
    

    const handleCreate = async () => {
        
        try {
            const response = await axios.post(
                `${BASE_URL}/v1/location/create-line-location`,
                {
                    line_location_name:locationName,
                    line_location_manager:selectedMember
                },
                { headers: HEADERS }
            );

            if (response.data.meta.success === false) {
                console.error(response.data.meta.message);
                toast.error(response.data.meta.message);
            } else {
                nav(LINELOCATIONVIEW(response.data.data.line_loc_id))


                handleClose();

            }
        } catch (error) {
            console.error("An error occurred during the request:", error);
            toast.error("An error occurred during the request. Please try again.");
        }


    };
    return (
        <div className='create-part-number-popup-page' >
            <div className='create-part-num-div' >
                <div className='create-part-num-div-top'>
                    <span>Create Location</span>
                    <PopupCloseButton handleClose={handleClose} />
                </div>


                <div className='create-part-num-div-form' style={{height:'auto', overflowY: 'scroll' }}>




                    <div className="create-part-num-div-form-dynamic">

                        <div className='create-asset-num-div-form-label-input'>
                            <span>Line location name</span>
                            <input
                                type='text'
                                // disabled={!inputDisable}
                                placeholder='Enter Location name'

                                onChange={(e) => setLocationName(e.target.value)}
                            />
                        </div>
                        <div className='create-asset-num-div-form-label-input'>
                            <span>Select Line location manager</span>
                            <div style={{width:'250px'}}>
                            <MemberDropdown field={{ fieldName: 'Line location manager',is_multi:false, inputType: 'person', placeholder: 'Select manager', }}
                                handlePersonSelectChange={handlePersonSelectChange}
                            />
                            </div>
                            
                        </div>


                    </div>



                </div>

                <div className='create-part-num-div-btns'>
                    <button onClick={handleCreate}>Create</button>
                    <button onClick={handleClose}>Cancel</button>

                </div>
            </div>
        </div>
    )
}

export default CreateLineLocation