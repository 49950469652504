import React from 'react'
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function PaginationBtns({setCurrentPageIndex,currentPageIndex,nextPageTokens}) {
    console.log(nextPageTokens)

    const handleNextPage = () => {
        if (nextPageTokens[currentPageIndex + 1]) {
            setCurrentPageIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex((prevIndex) => prevIndex - 1);
        }
    };
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <button onClick={handlePreviousPage} style={{opacity: currentPageIndex === 0 ? 0.5 : 1,}} disabled={currentPageIndex === 0} className="prev-button"
    >
        <KeyboardBackspaceIcon /> Prev
    </button>
    <button onClick={handleNextPage} style={{opacity: !nextPageTokens[currentPageIndex + 1] ? 0.5 : 1,}} disabled={!nextPageTokens[currentPageIndex + 1]} className="next-button"
    >
        next
        <KeyboardBackspaceIcon
            style={{ transform: "rotate(180deg)" }}
        />
    </button>
</div>
  )
}

export default PaginationBtns