import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as THREE from 'three';
import { RotateCw, RotateCcw, ArrowUp, ArrowDown, ArrowLeft, ArrowRight, ZoomIn, ZoomOut } from 'lucide-react';

// Constants
const TEXTURE_COUNT = 72;  // Number of textures per axis
const ANGLE_STEP = 5;     // Rotation step in degrees
const AXES = ['x', 'y', 'z', 'xy', 'yz', 'xz'];  // Standard axis order
const ZOOM_STEP = 0.5;
const MIN_ZOOM = 2;
const MAX_ZOOM = 10;

export default function PartDesignView() {
    //https://grabcad.com/library/engine-v-twin-4-valve-heads-1
    // Refs
    const mountRef = useRef(null);
    const rendererRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const planeRef = useRef(null);
    const animationFrameRef = useRef(null);
    const intervalRef = useRef(null);

    // State
    const [materials, setMaterials] = useState({});
    const [lastValidMaterial, setLastValidMaterial] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentAxis, setCurrentAxis] = useState('z');
    const [currentAngle, setCurrentAngle] = useState(0);
    const [error, setError] = useState(null);
    const [currentZoom, setCurrentZoom] = useState(5);

    // Setup textures
    const setupTextures = useCallback(async () => {
        if (!rendererRef.current) {
            console.error('Renderer not ready');
            return {};
        }

        const loadingManager = new THREE.LoadingManager(
            () => console.log('Loading complete'),
            (url, loaded, total) => {
                console.log(`Loading file: ${url}. Loaded ${loaded} of ${total}`);
            },
            (url) => {
                console.error('Error loading', url);
                setError(`Failed to load texture: ${url}`);
            }
        );

        const textureLoader = new THREE.TextureLoader(loadingManager);
        const newMaterials = {};
        const loadingPromises = [];

        for (const axis of AXES) {
            newMaterials[axis] = [];
            for (let i = 0; i < TEXTURE_COUNT; i++) {
                const angle = i * ANGLE_STEP;
                const formattedAngle = angle.toString().padStart(3, '0');
                const textureUrl = `https://d1d8a3050v4fu6.cloudfront.net/Engine/sprite_${axis}_rot${formattedAngle}.png`;
                
                loadingPromises.push(
                    new Promise((resolve) => {
                        textureLoader.load(
                            textureUrl,
                            (texture) => {
                                texture.minFilter = THREE.LinearFilter;
                                texture.magFilter = THREE.LinearFilter;
                                texture.anisotropy = rendererRef.current.capabilities.getMaxAnisotropy();

                                const material = new THREE.MeshBasicMaterial({
                                    map: texture,
                                    transparent: true,
                                    side: THREE.DoubleSide,
                                    depthTest: true,
                                    depthWrite: true
                                });

                                newMaterials[axis][i] = material;
                                resolve();
                            },
                            undefined,
                            () => {
                                console.error(`Failed to load texture: ${textureUrl}`);
                                newMaterials[axis][i] = new THREE.MeshBasicMaterial({
                                    color: 0xff0000,
                                    transparent: true,
                                    opacity: 0.5,
                                    side: THREE.DoubleSide
                                });
                                resolve();
                            }
                        );
                    })
                );
            }
        }

        try {
            await Promise.all(loadingPromises);
            return newMaterials;
        } catch (error) {
            console.error('Error loading textures:', error);
            setError('Failed to load textures');
            return {};
        }
    }, []);

    // Rotation handler
    const rotateView = useCallback((direction) => {
        if (!planeRef.current || !materials || Object.keys(materials).length === 0) return;

        let newAngle = currentAngle;
        let newAxis = currentAxis;

        switch (direction) {
            case 'left':
                newAngle = (currentAngle - ANGLE_STEP + 360) % 360;
                break;
            case 'right':
                newAngle = (currentAngle + ANGLE_STEP) % 360;
                break;
            case 'up':
                const prevIndex = (AXES.indexOf(currentAxis) - 1 + AXES.length) % AXES.length;
                newAxis = AXES[prevIndex];
                newAngle = 0; // Reset angle when changing axis
                break;
            case 'down':
                const nextIndex = (AXES.indexOf(currentAxis) + 1) % AXES.length;
                newAxis = AXES[nextIndex];
                newAngle = 0; // Reset angle when changing axis
                break;
        }

        // Calculate texture index
        const textureIndex = Math.floor(newAngle / ANGLE_STEP) % TEXTURE_COUNT;
        
        // Get the new material for the current axis and angle
        const newMaterial = materials[newAxis]?.[textureIndex];

        // Update material if available
        if (newMaterial?.map) {
            if (planeRef.current.material !== newMaterial) {
                // Dispose of old material if necessary
                if (planeRef.current.material && 
                    planeRef.current.material !== lastValidMaterial && 
                    planeRef.current.material !== newMaterial) {
                    planeRef.current.material.dispose();
                }
                planeRef.current.material = newMaterial;
                setLastValidMaterial(newMaterial);
            }
        } else if (lastValidMaterial) {
            planeRef.current.material = lastValidMaterial;
        }

        // Update state
        setCurrentAngle(newAngle);
        setCurrentAxis(newAxis);

    }, [currentAngle, currentAxis, materials, lastValidMaterial]);

    // Scene initialization
    useEffect(() => {
        let mounted = true;
        let cleanup = null;

        const initializeScene = async () => {
            if (!mountRef.current) return;
            
            try {
                // Initialize renderer
                const renderer = new THREE.WebGLRenderer({
                    antialias: true,
                    powerPreference: "high-performance",
                    alpha: true
                });
                renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
                renderer.setSize(window.innerWidth, window.innerHeight);
                mountRef.current.appendChild(renderer.domElement);
                rendererRef.current = renderer;

                // Initialize scene
                const scene = new THREE.Scene();
                scene.background = new THREE.Color(0xffffff);
                sceneRef.current = scene;

                // Add axes helper for debugging
                // const axesHelper = new THREE.AxesHelper(5);
                // scene.add(axesHelper);

                // Setup camera with correct orientation
                const camera = new THREE.PerspectiveCamera(
                    40,
                    window.innerWidth / window.innerHeight,
                    0.1,
                    1000
                );
                
                // Set proper isometric view
                const distance = 5;
                const angle = Math.PI / 4; // 45 degrees
                camera.position.set(
                    distance * Math.cos(angle),  // X
                    distance,                    // Y (up vector)
                    distance * Math.sin(angle)   // Z
                );
                camera.up.set(0, 1, 0);  // Set Y as up vector
                camera.lookAt(0, 0, 0);
                cameraRef.current = camera;

                // Create plane with temporary material
                const geometry = new THREE.PlaneGeometry(2, 2);
                const tempMaterial = new THREE.MeshBasicMaterial({
                    color: 0xff0000,
                    transparent: true,
                    opacity: 0.5,
                    side: THREE.DoubleSide
                });

                const plane = new THREE.Mesh(geometry, tempMaterial);
                plane.lookAt(camera.position);
                planeRef.current = plane;
                scene.add(plane);

                // Load materials
                const newMaterials = await setupTextures();
                if (!mounted) return;
                
                // Initialize with first material of current axis
                if (newMaterials[currentAxis]?.[0]) {
                    const initialMaterial = newMaterials[currentAxis][0];
                    plane.material = initialMaterial;
                    setLastValidMaterial(initialMaterial);
                }
                
                setMaterials(newMaterials);

                // Animation loop
                const animate = () => {
                    animationFrameRef.current = requestAnimationFrame(animate);
                    renderer.render(scene, camera);
                };
                animate();

                setIsLoading(false);

                cleanup = () => {
                    console.log('Cleaning up scene');
                    scene.clear();
                    renderer.dispose();
                    geometry.dispose();
                    tempMaterial.dispose();
                    if (mountRef.current?.contains(renderer.domElement)) {
                        mountRef.current.removeChild(renderer.domElement);
                    }
                    if (animationFrameRef.current) {
                        cancelAnimationFrame(animationFrameRef.current);
                    }
                };

            } catch (error) {
                console.error('Scene initialization error:', error);
                setError('Failed to initialize scene');
                setIsLoading(false);
            }
        };

        initializeScene();

        return () => {
            mounted = false;
            if (cleanup) cleanup();
        };
    }, [setupTextures, currentAxis]);

    // Effect to handle material updates when axis changes
    useEffect(() => {
        if (!planeRef.current || !materials || Object.keys(materials).length === 0) return;

        const textureIndex = Math.floor(currentAngle / ANGLE_STEP) % TEXTURE_COUNT;
        const newMaterial = materials[currentAxis]?.[textureIndex];

        if (newMaterial?.map) {
            if (planeRef.current.material !== newMaterial) {
                if (planeRef.current.material && 
                    planeRef.current.material !== lastValidMaterial && 
                    planeRef.current.material !== newMaterial) {
                    planeRef.current.material.dispose();
                }
                planeRef.current.material = newMaterial;
                setLastValidMaterial(newMaterial);
            }
        }
    }, [currentAxis, currentAngle, materials, lastValidMaterial]);

    // Keyboard controls
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch(event.key) {
                case 'ArrowLeft':
                    rotateView('left');
                    break;
                case 'ArrowRight':
                    rotateView('right');
                    break;
                case 'ArrowUp':
                    rotateView('up');
                    break;
                case 'ArrowDown':
                    rotateView('down');
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [rotateView]);

    // Window resize handler
    useEffect(() => {
        const handleResize = () => {
            if (!cameraRef.current || !rendererRef.current) return;
            
            const width = window.innerWidth;
            const height = window.innerHeight;
            
            cameraRef.current.aspect = width / height;
            cameraRef.current.updateProjectionMatrix();
            
            rendererRef.current.setSize(width, height);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Add zoom function
    const handleZoom = useCallback((direction) => {
        if (!cameraRef.current) return;

        const newZoom = direction === 'in' 
            ? Math.max(currentZoom - ZOOM_STEP, MIN_ZOOM)
            : Math.min(currentZoom + ZOOM_STEP, MAX_ZOOM);

        setCurrentZoom(newZoom);

        // Update camera position while maintaining direction
        const direction3D = new THREE.Vector3();
        cameraRef.current.getWorldDirection(direction3D);
        direction3D.multiplyScalar(-newZoom);
        
        // Set new position relative to look-at point (origin)
        cameraRef.current.position.copy(direction3D);
    }, [currentZoom]);

    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100vh'
        }}>
            {/* Three.js Canvas Container */}
            <div 
                ref={mountRef} 
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1
                }}
            />
            
            {/* Rotation Controls */}
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                paddingBottom: '2rem',
                zIndex: 1
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                    {/* Control buttons */}
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '0.5rem'
                    }}>
                        <button 
                            onClick={() => rotateView('up')}
                            style={{
                                width: '3rem',
                                height: '3rem',
                                borderRadius: '9999px',
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                border: '1px solid #e5e7eb',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                transition: 'background-color 0.2s',
                                cursor: 'pointer'
                            }}
                            onMouseOver={e => e.currentTarget.style.backgroundColor = '#ffffff'}
                            onMouseOut={e => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'}
                        >
                            <ArrowUp style={{ width: '1.5rem', height: '1.5rem' }} />
                        </button>
                        <div style={{
                            display: 'flex',
                            gap: '0.5rem'
                        }}>
                            <button
                                onClick={() => rotateView('left')}
                                style={{
                                    width: '3rem',
                                    height: '3rem',
                                    borderRadius: '9999px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    border: '1px solid #e5e7eb',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.2s',
                                    cursor: 'pointer'
                                }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = '#ffffff'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'}
                            >
                                <ArrowLeft style={{ width: '1.5rem', height: '1.5rem' }} />
                            </button>
                            <button
                                onClick={() => rotateView('right')}
                                style={{
                                    width: '3rem',
                                    height: '3rem',
                                    borderRadius: '9999px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    border: '1px solid #e5e7eb',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                    transition: 'background-color 0.2s',
                                    cursor: 'pointer'
                                }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = '#ffffff'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'}
                            >
                                <ArrowRight style={{ width: '1.5rem', height: '1.5rem' }} />
                            </button>
                        </div>
                        <button
                            onClick={() => rotateView('down')}
                            style={{
                                width: '3rem',
                                height: '3rem',
                                borderRadius: '9999px',
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                border: '1px solid #e5e7eb',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                                transition: 'background-color 0.2s',
                                cursor: 'pointer'
                            }}
                            onMouseOver={e => e.currentTarget.style.backgroundColor = '#ffffff'}
                            onMouseOut={e => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'}
                        >
                            <ArrowDown style={{ width: '1.5rem', height: '1.5rem' }} />
                        </button>
                    </div>

                    {/* Current view info */}
                    <div style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        padding: '0.5rem 1rem',
                        borderRadius: '9999px',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                        border: '1px solid #e5e7eb'
                    }}>
                        <span style={{ fontWeight: 500 }}>
                            Axis: {currentAxis.toUpperCase()} | Angle: {currentAngle}° | Zoom: {currentZoom.toFixed(1)}x
                        </span>
                    </div>
                </div>
            </div>

            {/* Zoom Controls */}
            <div style={{
                position: 'absolute',
                right: '2rem',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                zIndex: 2
            }}>
                <button
                    onClick={() => handleZoom('in')}
                    style={{
                        width: '3rem',
                        height: '3rem',
                        borderRadius: '9999px',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        border: '1px solid #e5e7eb',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.2s',
                        cursor: 'pointer',
                        opacity: currentZoom <= MIN_ZOOM ? 0.5 : 1
                    }}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#ffffff'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'}
                    disabled={currentZoom <= MIN_ZOOM}
                >
                    <ZoomIn style={{ width: '1.5rem', height: '1.5rem' }} />
                </button>
                <button
                    onClick={() => handleZoom('out')}
                    style={{
                        width: '3rem',
                        height: '3rem',
                        borderRadius: '9999px',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        border: '1px solid #e5e7eb',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.2s',
                        cursor: 'pointer',
                        opacity: currentZoom >= MAX_ZOOM ? 0.5 : 1
                    }}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#ffffff'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)'}
                    disabled={currentZoom >= MAX_ZOOM}
                >
                    <ZoomOut style={{ width: '1.5rem', height: '1.5rem' }} />
                </button>
            </div>

            {/* Loading and Error states remain the same */}
            {isLoading && (
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 3
                }}>
                    <span style={{
                        fontSize: '1.25rem',
                        fontWeight: 500
                    }}>
                        Loading textures...
                    </span>
                </div>
            )}

            {error && (
                <div style={{
                    position: 'absolute',
                    top: '1rem',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: '#fee2e2',
                    border: '1px solid #ef4444',
                    color: '#b91c1c',
                    padding: '0.5rem 1rem',
                    borderRadius: '0.375rem',
                    zIndex: 3
                }}>
                    {error}
                </div>
            )}
        </div>
    );
}