import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import {
    BASE_URL,
    HEADERS,
    PARTVIEW
} from "../../constants/config";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import { toast } from "react-toastify";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


function DesignDeletePopUp({ row, onClose, onClosePopUp }) {
    const [linkedParts, setLinkedParts] = useState([]);
    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        checkEligibility();
    }, []);
    const checkEligibility = async () => {
        try {
            const params = {
                design_id: row._id
            };
            const response = await axios.get(BASE_URL + "/v1/pdm/check-design-delete", {
                params,
                headers: HEADERS,
            });
            if (!response.data?.meta?.success) {
                toast.error(response.data?.meta.message)
            } else {
                setLinkedParts(response.data.data.linked_parts);
                if (response.data.data.linked_parts.length > 0) {
                    const hasReleasedStatus = response.data.data.linked_parts.some(item => item.ec_approval_status === 'Released');
                    if (hasReleasedStatus) {
                        setIsDelete(false);
                    } else {
                        setIsDelete(true);
                    }
                } else {
                    setIsDelete(true);
                }
            }
            // setSelectMember(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        }
    };
    const handleConfirm = async () => {
        const formData = {
            design_id: row._id
        };

        try {
            const response = await axios.post(`${BASE_URL}/v1/pdm/delete-design`, formData, {
                headers: HEADERS,
            });
            if (!response.data?.meta?.success) {
                toast(response.data?.meta.message);
            } else {
                onClosePopUp('', `${row.name} was deleted successfully!`);
            }
            // navigate to details page
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    }


    return (
        <>
            <div className='create-part-number-popup-page'>
                <div className='create-part-num-div' style={{ width: '45%' }}>
                    <div className='create-part-num-div-top'>
                        <span>Delete {row.name}?</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    {

                        (linkedParts.length > 0) ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                                <span >{row.name} is linked to parts</span>
                                <Stack direction="row" spacing={1} style={{ flexWrap: 'wrap', paddingLeft: '4%', paddingRight: '4%' }}>
                                    {linkedParts.map((item) => (
                                        <Chip key={item.part_number} label={`${item.part_number}: ${item.ec_approval_status}`} component="a" href={PARTVIEW(item.part_number)} target="_blank" clickable style={{ margin: '0.5%' }} />
                                    ))}
                                </Stack>
                                <span style={{ fontSize: '12px', color: 'red' }}>{(isDelete) ? 'If you confirm, the design will be removed from the parts along with the files.' : "Some parts linked to this design are currently in the 'Released' state, so the design cannot be deleted"}</span>
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                <span >Are you sure you want to delete this design?</span>
                                <span style={{ fontSize: '12px', color: 'red' }}>This action cannot be undone.</span>
                            </div>
                    }
                    <div className='create-part-num-div-btns'>
                        <button
                            onClick={onClose}
                        >Close</button>
                        {
                            (isDelete) &&  <button
                            disabled={!isDelete} // Disable button if isDelete is true
                            onClick={isDelete ? handleConfirm : null} // Only call handleConfirm if isDelete is false
                        >Confirm</button>
                        }
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default DesignDeletePopUp;
