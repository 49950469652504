import React, { useState, useEffect } from "react";
import "../Signup/Signup.css";
import Cookies from "js-cookie";
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL, GOOGLE_CLIENT_ID, HEADERS } from "../../constants/config";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { toast } from "react-toastify";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LeftContainer from "../Common/LeftContainer";
// import { GoogleLogin } from "react-google-login";
import Logo from "../Common/Logo";
import { MicrosoftLogin } from "react-microsoft-login";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

function Login({ toastStyle }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedError, setIsCheckedError] = useState("");


  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const nav = useNavigate();

  const getMenu = async (token) => {
    try {

      const response = await axios.get(BASE_URL + "/v1/onboard/get-menu", {
        headers: { "x-auth-token": token }
      });

      const data = response.data.data;
      localStorage.setItem(
        "orgDetails",
        JSON.stringify(data.org_details.orgDetails)
      );
      localStorage.setItem(
        "memberDetails",
        JSON.stringify(data.profile_details)
      );
      localStorage.setItem(
        "starredDepartments",
        JSON.stringify(data.star_depart)
      );
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    let isValidated = true;
    if (!isChecked) {
      setIsCheckedError("Please check the checkbox to proceed.");
      isValidated = false;
    } else {
      setIsCheckedError("");
    }
    if (
      email.includes("@") &&
      email.includes(".") &&
      email.indexOf("@") !== 0 &&
      email.length - email.lastIndexOf(".") >= 3
    ) {
      setEmailError("");
    } else {
      setEmailError("Please enter your work email");
      isValidated = false;
    }
    if (!password) {
      setPasswordError("Password should not be empty");
      isValidated = false;
    } else {
      setPasswordError("");
    }
    if (isValidated) {
      CallLoginApi(email, password, "");

    }
  };
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };
  async function CallLoginApi(email, password, token, isMsalSSO) {

    let data;
    if (token) {
      data = await axios.post(BASE_URL + "/v1/member/login", {
        idToken: token, isMsalSSO
      });
    } else {
      data = await axios.post(BASE_URL + "/v1/member/login", {
        email: email.toLowerCase(),
        password,
      });

    }
    let { message, success } = data.data.meta;
    if (success) {
      const { token, isSSO, redirect } = data.data.data;
      // Cookies.set("token", token);
      localStorage.setItem("token", token);

      await getMenu(token);

      window.location.href = redirect;
      console.log(redirect)

      // toast.success("Successfully Login!", toastStyle);
      // window.location.reload();
    } else {
      if (message.includes("email")) {
        setEmailError(message);
      } else if (message.includes("password")) {
        setPasswordError(message);
      } else {
        toast.error(message, toastStyle);
      }
      //need to show error



    }


  }

  const responseGoogle = async (response) => {
    try {
      CallLoginApi(response.profileObj.email, "", response.tokenId);
    } catch (error) {
      console.log(error)
    }


  };



  const handleAuth = (err, authResponse) => {


    if (!err) {
      CallLoginApi('', '', authResponse.account.username, 1);
    } else {
      toast.error(err, toastStyle);
    }

  };

  const handleError = (error) => {
    // Handle the authentication error
    console.error(error);
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <div className="login-page">
        <LeftContainer />
        <div className="right-con">
          <div className="right">
            <Logo />
            {/* <span className="account">Login your account</span> */}

            <div className="social-login">
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  CallLoginApi("", "", credentialResponse.credential);
                }}
                onError={() => {
                  toast.error("Google Login Failed!", toastStyle);
                }}
                useOneTap={false} // Disable default button popup
              >
                <button
                  onClick={() => document.querySelector(".google-login button").click()}
                  className="google-button"
                  style={{ paddingTop: '7.8px', paddingBottom: '7.8px', fontWeight: '600' }}
                >
                  <img
                    src={`${ASSET_PREFIX_URL}google_logo.svg`}
                    alt="google"
                    width="24px"
                    height="24px"
                  />
                  Sign In with Googles
                </button>
              </GoogleLogin>

              <MicrosoftLogin
                clientId="8ef57ff0-a2bf-423d-8400-fe3fdf571177"
                authCallback={handleAuth}
                authError={handleError}
                className="microsoft-btn"
              >
                <button className="microsoft-button" style={{ paddingTop: '7.8px', paddingBottom: '7.8px', fontWeight: '600' }}>
                  <img
                    src={`${ASSET_PREFIX_URL}microsoft_logo.svg`}
                    alt="google" width='24px' height='24px' />Sign In with Microsoft
                </button>
              </MicrosoftLogin>
            </div>

            <div className="or">
              <span>or</span>
            </div>

            <div className="form">
              <div className={`input-container ${emailError ? "error" : ""}`}>
                <input
                  type="email"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="email-inp"
                  onKeyUp={handleKeyUp}
                />
              </div>
              <div className="error-cont">
                {emailError && (
                  <div className="error-message">
                    <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                    {emailError}
                  </div>
                )}
              </div>
              <div
                className={`password-container ${passwordError ? "error" : ""}`}
              >
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your Password"
                  onKeyUp={handleKeyUp}
                />
                <button onClick={handleTogglePasswordVisibility}>
                  {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                </button>
              </div>
              {passwordError && (
                <div className="error-message">
                  <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                  {passwordError}
                </div>
              )}
              <div className={`check-box ${isCheckedError ? "error" : ""}`}>
                <input
                  type="checkbox"
                  className="inp-check"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  onKeyUp={handleKeyUp}
                />
                <div className="check-box-para">
                  By logging in, you agree to our <a href="/terms-and-conditions">Terms and Conditions</a> and our <a href="/privacy-policy">Privacy Policy</a>.
                  {/* By logging in means you agree to the <a href="/">Terms and Conditions</a>, and our Privacy Policy */}
                </div>
              </div>
              {isCheckedError && (
                <div className="error-message">
                  <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                  {isCheckedError}
                </div>
              )}
              <button className="sign-up" onClick={handleLogin}>
                Login
              </button>


              {/* <div className="login-route">
              <p>
                Not a member?{" "}
                <span>
                  <a href="/register" style={{ textDecoration: "none" }}>
                    Sign Up
                  </a>
                </span>
              </p>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default Login;