import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton';
import Select from 'react-select';
import { BASE_URL, HEADERS } from '../../constants/config';
import { toast } from 'react-toastify';

function CreateInternalTransfer({ handleClose,setUpdateData }) {
  const [locationList, setLocationList] = useState([]);
  const [partList, setPartList] = useState([]);
  const [quantityData, setQuantityData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreLocations, setHasMoreLocations] = useState(true); // New flag for locations
  const [partPage, setPartPage] = useState(1);
  const [hasMoreParts, setHasMoreParts] = useState(true); // New flag for parts
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState('');
  const [destinationLocation, setDestinationLocation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch locations with pagination and stop when hasMore is false
  const getLocations = async (page) => {
    if (loading || !hasMoreLocations) return;
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/v1/location/get-loc-line-loc`, {
        params: { page: page },
        headers: HEADERS,
      });
      console.log(response)
      const { locations, hasMore } = response.data.data; // Adjusted for hasMore flag
      setLocationList((prevList) => [...prevList, ...locations]);
      setHasMoreLocations(hasMore); // Update the hasMore flag
      setCurrentPage(page);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuScrollToBottom = () => {
    if (hasMoreLocations) {
      getLocations(currentPage + 1);
    }
  };

  const handleSelectLocation = (selected) => {
    setLocation(selected.value);
    setPartList([]);
    setPartPage(1);
    setHasMoreParts(true); // Reset for new location
    getPartsList(selected.value, 1);
  };

  const handleSelectDestination = (selected) => {
    setDestinationLocation(selected.value);
  };

  const handleFocus = () => {
    if (locationList.length === 0) {
      getLocations(1);
    }
  };

  const getPartsList = async (location_id, page) => {
    if ( !hasMoreParts) return;

    try {
      const response = await axios.get(`${BASE_URL}/v1/location/get-part-list`, {
        params: { location_sequence_id: location_id, page: page },
        headers: HEADERS,
      });
      const { parts_list, hasMore } = response.data.data; // Adjusted for hasMore flag
  
      // Append unique parts only
      setPartList((prevList) => {
        const newParts = parts_list.filter(
          (newPart) => !prevList.some((part) => part.part_number === newPart.part_number)
        );
        return [...prevList, ...newParts];
      });
  
      setHasMoreParts(hasMore); // Update the hasMore flag
      setPartPage(page);
    } catch (error) {
      console.error(error);
    } 
  };

  const handleQuantityChange = (part_number, value) => {
    const quantity = parseInt(value, 10) || 0;
    setQuantityData((prevData) => ({ ...prevData, [part_number]: quantity }));
  };

  const handleSave = async () => {
    // Create transferData array with valid quantities
    const transferData = partList
      .filter((item) => quantityData[item.part_number] > 0 && quantityData[item.part_number] <= item.quantity)
      .map((item) => ({
        part_number: item.part_number,
        quantity: quantityData[item.part_number],
      }));
      if (!location) {
        setErrorMessage('Please select source location.');
        return;
      } 
       if (!destinationLocation) {
        setErrorMessage('Please select destination location.');
        return;
      }
    // Check if no quantities are entered
    if (Object.values(quantityData).every(quantity => quantity <= 0)) {
      setErrorMessage('Please enter quantity to transfer.');
      return;
    } 

    // Check for valid transfer data
    if (transferData.length < Object.keys(quantityData).length) {
      setErrorMessage('Entered quantity should not exceed inventory quantity.');
      return;
    } 

    
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const payload = {
        data: JSON.stringify(transferData),
        source_seq_id: location,
        destination_seq_id: destinationLocation,
      };
      const response = await axios.post(
        `${BASE_URL}/v1/location/create-internal-transfer`,
        payload,
        { headers: headers }
      );
      if (response.data.meta.success) {
        setUpdateData(payload);
        handleClose();
      } else {
        toast.error(response.data.meta.message);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } 
};


  useEffect(() => {
    if (location) {
      getPartsList(location, partPage);
    }
  }, [location, partPage]);

  const handlePartScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (hasMoreParts) {
        getPartsList(location, partPage + 1);
      }
    }
  };

  return (
    <div className='create-grn-popup'>
      <div className="create-grn-div">
        <div className="modal-header">
          <span>Create Transfer</span>
          <PopupCloseButton handleClose={handleClose} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '24px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span>Source location:</span>
            <Select
              options={locationList.map((location) => ({
                value: location.seq_id,
                label: location.name,
              }))}
              placeholder='Select source location'
              onChange={handleSelectLocation}
              onFocus={handleFocus}
              onMenuScrollToBottom={handleMenuScrollToBottom}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <span>Destination location:</span>
            <Select
              isDisabled={!location}
              options={locationList.filter((item) => item.seq_id !== location).map((location) => ({
                value: location.seq_id,
                label: location.name,
              }))}
              placeholder='Select destination location'
              onChange={handleSelectDestination}
              onFocus={handleFocus}
              onMenuScrollToBottom={handleMenuScrollToBottom}
            />
          </div>
        </div>
        <div className="department-table" style={{ minHeight: '56vh', overflowY: 'scroll' }} onScroll={handlePartScroll}>
          <table className="department-table-data">
            <thead>
              <tr>
                <th className="depart-header">Part number</th>
                <th className="depart-header">Part title</th>
                <th className="depart-header">Inventory quantity</th>
                <th className="depart-header">Quantity to transfer</th>
              </tr>
            </thead>
            <tbody>
              {partList.map((item, i) => (
                <tr key={i}>
                  <td>{item.part_number}</td>
                  <td title={item.part_title}>
                    {item.part_title.length > 30 ? (
                      <>
                        {item.part_title.slice(0, 30)}...
                      </>
                    ) : (
                      item.part_title
                    )}
                  </td>
                  <td>{item.quantity}</td>
                  <td>
                    <input
                      type="number"
                      placeholder='Enter quantity'
                      value={quantityData[item.part_number] || ''}
                      onChange={(e) => handleQuantityChange(item.part_number, e.target.value)}
                      style={{ padding: '10px 5px', border: '1px solid #edf2f7' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="error-message" style={{ visibility: errorMessage ? 'visible' : 'hidden' }}>
          {errorMessage}
        </div>
        <div className="modal-footer" style={{ justifyContent: 'flex-end' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <button className="btn partial-receive" onClick={handleSave}>Create transfer</button>
            <button className="btn go-back" onClick={handleClose}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateInternalTransfer;
