import React, { useState, useEffect } from "react";
import { BASE_URL, PARTVIEW, PCVIEW } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Loading from "../Common/Loading/Loading";
import NameProfile from "../Common/CommonJsx/NameProfile";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { debounce } from "../../constants/helper";
import Pagination from "../Common/CommonJsx/Pagination";
// import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { toast } from "react-toastify";
import CreateAttributes from './../MbomPages/CreateAttributes';

function LaunchedPartsList() {
 
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [partLengthOfList, setPartLengthOfList] = useState(0); 
  const itemsPerPage = 10;
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [resetButton, setResetButton] = useState(false);
  const [updatedData, setUpdatedData] = useState(false);
  const [loading, setLoading] = useState(false)
  const [partNumbers, setPartNumbers] = useState([]);
  const [editPartPopUp, setEditPartPopUp] = useState(false);
  const [rightSectionItems, setRightSectionItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [partAction, setPartAction] = useState('')
  const [varientFrom, setVarientFrom] = useState('')
  const [attributeOptions, setAttributeOptions] = useState({});

  const [nomenCondition, setNomenCondition] = useState(false);

  // const [partName, setPartName] = useState("");

  const [id, setId] = useState("");

  const nav = useNavigate();



 
  

 

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setResetButton(true)
  };
 
  const handleReset = async () => {

    // setDepartmentId('')

    setSelectedStatus(null)
    setSearchTerm('')
    setPartAction('')
    setResetButton(false)


    // setReset(false)
  };
  const getPartNumberList = async (sortField, sortOrder) => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const listofPartNumber = await axios.get(
        `${BASE_URL}/v1/manufac-bom/get-approved-pc-parts-list`,
        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            part_action: partAction,
            search: searchTerm,
            selected_status: selectedStatus,
            sort_by: sortField,
            sort_order: sortOrder,
          },
          headers: headers,
        }
      );

      setPartNumbers(listofPartNumber.data.data.part_number_list);
      
      setPartLengthOfList(listofPartNumber.data.data.part_count);
      setTotalPages(listofPartNumber.data.data.total_pages);
      setNomenCondition(listofPartNumber.data.data.part_nomen)
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
 


  useEffect(() => {
    if (searchTerm !== '' || selectedStatus) {
      debounce(getPartNumberList)()
    }
    else {
      getPartNumberList();
    }

  }, [currentPage, itemsPerPage, partAction, searchTerm,updatedData]);




  useEffect(() => {
    getAttributes();
  }, []);

  const getAttributes = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const fields = await axios.get(
        `${BASE_URL}/v1/part-nomen/get-attributes`,
        {
          params: {
              type:'mbom'
          },
          headers: headers,
      }
      );
      setAttributeOptions(fields.data.data.attribute_options);
    } catch (error) {
      console.log(error);
    }
  }

  const openeditPartPopUp = (e, id) => {
    if(attributeOptions.length){
      setId(id)
      e.stopPropagation();
      // getActiveFields();
      setEditPartPopUp(true);
      // getAttributes()
    }else{
      toast.error('Please create attributes for adding')
    }
   
  };

  
  

  




  const handleTitleClick = (event, department) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(department);
  };
  const HandleTitleClose = () => {
    setTicketPopUp(false);
  };
 
  

  


  const handleNavigate = (e, link,type) => {
    e.stopPropagation();
    let url;
    if(type === 'pc'){
        url=(PCVIEW(link))
    }else if(type === 'parts'){
        url=PARTVIEW(link)
    }
   
    
    window.open(url, '_blank');
  };
  return (
    <>

      <div className="part-number-list-container">
        <div className="part-number-list-title">
          <span>Launched parts</span>
          <div className="part-number-list-btns">
           
           
          </div>
        </div>
        <div className="part-number-list-filters">
         
          {/* <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='parts'/> */}
         
          {resetButton && <button
            className="reset-btn"
            onClick={handleReset}
         
          >
            reset <TuneOutlinedIcon />
          </button>}

        </div>
        <div>


          <table className="part-number-list-table-container">
            <thead>
              <tr>
                <th className="part-number-list-th1"  >Part number 
               </th>
                <th className="part-number-list-th1"   >Part title</th>
                <th className="part-number-list-th1" >PC </th>
                <th  >Created by</th>
               
                {/* ec_dev_reference */}
                <th>Actions</th>
                
              </tr>
            </thead>
            {loading ? <Loading /> :
              <tbody>
                {partNumbers.map((num, index) => {
                  
                  return (
                    <tr  key={index}>
                      <td style={{ color: 'blue', textDecoration: 'underline' }} onClick={(e) => handleNavigate(e,num.part_number,'parts')}>{num.part_number}</td>
                      <td>

                        {/* {department.data.defaultFieldsData.ticket_title.value} */}
                        {num.part_title && num.part_title.length > 30 ? (
                          <>
                            {num.part_title.slice(
                              0,
                              24
                            )}
                            ...
                            <button
                              className="view-more-text"
                              onClick={(event) =>
                                handleTitleClick(event, num.part_title)
                              }
                            >
                              view
                            </button>
                          </>
                        ) : (
                          num.part_title
                        )}</td>
                      <td style={{ color: 'blue', textDecoration: 'underline' }} onClick={(e) => handleNavigate(e,num.pc_sequence_id,'pc')}>
                       
                        {num.pc_sequence_id}
                      </td>
                      <td className="part-number-photo">
                        <NameProfile userName={num.created_by.fullName} width='26px' memberPhoto={num.created_by.photo} fontweight='500' />
                        <span>{num.created_by.fullName}</span>
                      </td>
                      
                      <td>
                       
                          <button
                            className="part-number-list-table-container-btn"
                            onClick={(e) => openeditPartPopUp(e, num.part_number)}
                          >
                            Add attributes
                          </button>
                       
                      </td>
                     
                    </tr>
                  );
                })}


              </tbody>
            }
          </table>

        </div>
        {(totalPages > 1 && !loading)  && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        )}
      </div>


     
      
    
      {(ticketPopUp) && (
        <TicketTitlePopUp
        header='Part title'
          onClose={HandleTitleClose}
          ticketTitle={ticketTitle}
        />
      )}
      {editPartPopUp && (<CreateAttributes setUpdatedData={setUpdatedData} handleClose={()=>setEditPartPopUp(!editPartPopUp)} partNumber={id} attributeOptions={attributeOptions}/>)}
    </>
  );
}

export default LaunchedPartsList;
