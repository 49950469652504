import React, { useState, useEffect } from 'react';
import { ASSET_PREFIX_URL, BASE_URL, HEADERS } from '../../constants/config';
import axios from 'axios';
import MemberDropdown from '../Common/MemberDropdown';
import { toast } from 'react-toastify';

function LinelocationDetails({ sequence_id }) {
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({});
  const [editField, setEditField] = useState(null); // Track which field is in edit mode
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchLocationDetails();
  }, [sequence_id]);

  const fetchLocationDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${BASE_URL}/v1/location/get-line-loc-details`,
        {
          headers: HEADERS,
          params: { line_loc_seq_id: sequence_id }
        }
      );
      console.log(response);
      setData(response.data.data.line_loc_details);
      setFormData(response.data.data.line_loc_details);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching location details:', error);
      setLoading(false);
    }
  };

  const handleEditClick = (fieldKey) => {
    setEditField(fieldKey);
  };

  const handleSaveClick = async (fieldKey) => {
    console.log({ [fieldKey]: formData[fieldKey] })
    try {
     const response= await axios.post(
        `${BASE_URL}/v1/location/edit-line-location`,
        { line_loc_seq_id: sequence_id, updates: { [fieldKey]: fieldKey==='line_location_manager'?formData[fieldKey].value:formData[fieldKey] } },
        { headers: HEADERS }
      );
      console.log(response)
      if(response.data.meta.success){
        setData((prevData) => ({
          ...prevData,
          [fieldKey]: formData[fieldKey]
        }));
        setEditField(null); 
      }else{
        toast.error(response.data.meta.message)
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelClick = (fieldKey) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: data[fieldKey]
    }));
    setEditField(null); // Exit edit mode for this field
  };

  const handleChange = (fieldKey, value) => {
    console.log(value.value)
    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]:  value
    }));
  };

  return (
    <div className='supplier-page-bottom-page'>
      <div className='supplier-page-default-fields'>

        {/* Location Name Field */}
        <div className='supplier-mandatory-field-container'>
          <span className='supply-custom-label-span'>Location name</span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <input
              type='text'
              value={formData.line_location_name || ''}
              onChange={(e) => handleChange('line_location_name', e.target.value)}
              disabled={editField !== 'line_location_name'}
            />
            {editField === 'line_location_name' ? (
              <>
                <img onClick={() => handleSaveClick('line_location_name')} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                <img onClick={() => handleCancelClick('line_location_name')} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />

              </>
            ) : (
              <img
                onClick={() => handleEditClick('line_location_name')}
                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                alt="Edit"
                width="24px"
                height="24px"
                style={{ cursor: 'pointer' }}
              />

            )}
          </div>

        </div>

        {/* Location Manager Field */}
        <div className='supplier-mandatory-field-container'>
          <span className='supply-custom-label-span'>Location manager</span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            <MemberDropdown
              field={{
                fieldName: 'line_location_manager',
                is_multi: false,
                inputType: 'person',
                placeholder: 'Select manager',
                value: formData.line_location_manager
              }}
              handlePersonSelectChange={handleChange}
              disabled={editField !== 'line_location_manager'}
            />
            {editField === 'line_location_manager' ? (
              <>

                <img onClick={() => handleSaveClick('line_location_manager')} src={`${ASSET_PREFIX_URL}save-details.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
                <img onClick={() => handleCancelClick('line_location_manager')} src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} />
              </>
            ) : (
              <img
                onClick={() => handleEditClick('line_location_manager')}
                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                alt="Edit"
                width="24px"
                height="24px"
                style={{ cursor: 'pointer' }}
              />

            )}
          </div>

        </div>
      </div>
    </div>
  );
}

export default LinelocationDetails;
