import React, { useState } from 'react';
import Select from "react-select";
import axios from "axios";
import NameProfile from './CommonJsx/NameProfile';
import { BASE_URL, HEADERS, PHOTO_LINK } from '../../constants/config';


function MemberDropdown({ field, handlePersonSelectChange, disable }) {

    // // {
    //     fieldName: field.fieldName,
    //     placeholder: field.placeholder,
    //     isMulti:  true / false,
    //     value: if details pages send value else no need value key
    // }
    const [allMembers, setAllMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);


    const fetchMembers = async (pageNumber = 1) => {
        if (loading || !hasMore) return;
        setLoading(true);
        try {
            const response = await axios.get(`${BASE_URL}/v1/setting/get-mem`, {
                headers: HEADERS,
                params: {
                    page: pageNumber,
                    limit: 10
                }
            });


            const newMembers = response.data.data.memberList;


            if (newMembers.length === 0) {
                setHasMore(false);
            } else {
                setAllMembers(prevMembers => {
                    const existingMemberIds = new Set(prevMembers.map(member => member._id));
                    const filteredNewMembers = newMembers.filter(member => !existingMemberIds.has(member._id));
                    return [...prevMembers, ...filteredNewMembers];
                });
                setPage(prevPage => prevPage + 1);
            }
        } catch (error) {
            console.error("Error fetching members:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleMenuScrollToBottom = () => {
        if (hasMore) {
            fetchMembers(page);
        }
    };


    const customStyles = {
        control: (provided) => ({
            ...provided,
            maxWidth: "369px",
            minWidth: "150px",
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),
        menu: (provided) => ({
            ...provided,
            maxWidth: "369px",
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),
    };


    const formatOptionLabel = (option) => (
        <div style={{ display: 'flex', alignItems: 'center' }} className="option-label">
            {option.photo ? (
                <img
                    src={`${PHOTO_LINK}${option.photo}`}
                    alt="options"
                    className="owner-image"
                />
            ) : (
                <div className="owner-image-span">
                    <NameProfile userName={option.fullName} />
                </div>
            )}
            {option.fullName.length < 15 ? option.fullName : `${option.fullName.slice(0, 12)}...`}
        </div>
    );




    return (
        <Select
            placeholder={field.placeholder}
            styles={customStyles}
            isMulti={field.isMulti}
            options={allMembers.map((member) => ({
                value: member._id,
                fullName: member.fullName,
                photo: member.photo,
            }))}
            formatOptionLabel={formatOptionLabel}
            onChange={(selectedOption) => {
                handlePersonSelectChange(
                    field.fieldName,
                    selectedOption
                );
            }}
            value={field.value&&(Array.isArray(field.value) ? field.value : [field.value]).map((option) => ({
                value: option.member_id,
                fullName: option.fullName,
                photo: option.photo
            }))}
            onFocus={() => fetchMembers(1)}
            onMenuScrollToBottom={handleMenuScrollToBottom}
            isDisabled={disable}
            isLoading={loading}
        />
    );
}


export default MemberDropdown;
