import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
import {
    ASSET_PREFIX_URL,
    BASE_URL,
    BUCKET,
    CATEGORY_UPLOAD_DESIGNS,
    HEADERS,GOOGLE_API_KEY
} from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper"
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import NameProfile from "../Common/CommonJsx/NameProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AttachmentsFunction from "../Common/CommonJsx/AttachmentsFunction";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileUplaoding from "../Common/Loading/FileUplaoding";



function DesignCheckinPopUp({ row, onClose, onClosePopUp, workspace_sequence_id }) {
    console.log(row)
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [uploadError, setUploadError] = useState('');

    const [minorVersion, setMinorVersion] = useState(row.latest_version.version.minor);
    const [majorVersion, setMajorVersion] = useState(row.latest_version.version.major);

    const [addUploadSelect, setAddUplaodSelect] = useState(false);
    const [isPickerReady, setIsPickerReady] = useState(false);
    const [isFileUploading, setIsFileLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});
    const [errors, setErrors] = useState('');
    const [comment, setComment] = useState('');

    const revisionOptions = [
        { value: "major", label: "Major" },
        { value: "minor", label: "Minor" },
    ];

    const nav = useNavigate();

    const handleChangeVersion = (source) => {
        if (source == 'major') {
            setMajorVersion(row.latest_version.version.major + 1);
            setMinorVersion(0)
        } else {
            setMajorVersion(row.latest_version.version.major);
            setMinorVersion(row.latest_version.version.minor + 1)
        }
        setErrors('');
    }

    const handleFileDrop = async (event) => {
        event.preventDefault();


        console.log(event)
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const selectedFiles = Array.from(files);
            const updatedFiles = selectedFiles.map((file) => ({
                name: file.name,
                file: file,
                key: URL.createObjectURL(file), // Create blob URL for the file
            }));

            setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
            // handleFile(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
    };
    const handleFileUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const updatedFiles = selectedFiles.map((file) => ({
            name: file.name,
            file: file,
            key: URL.createObjectURL(file), // Create blob URL for the file
        }));

        setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    };

    const upload = () => {
        document.getElementById("fileupld_stp").click();
    };
   

    const validateForm = () => {
        if (row.latest_version.version.major == majorVersion && row.latest_version.version.minor == minorVersion) {
            setErrors("Increment either major or minor version");
            return false;
        }
        // if (uploadedFiles.length === 0) {
        //     setUploadError("At least one file should be uploaded.");
        //     return false;
        // }
        return true;
    };

    const submitForm = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        setFieldErrors({});
        setErrors('');
        setUploadError('');
        if (!validateForm()) {
            return false;
        } else {
            const formData = new FormData();
            formData.append("design_id", row.checkout_version.design_id);
            formData.append("comment", comment);
            formData.append("version_id", row.checkout_version._id);
            formData.append("minor_version", minorVersion);
            formData.append("major_version", majorVersion);
            formData.append("headers", JSON.stringify(HEADERS));
            const selectedFilesFormatted = uploadedFiles
            .filter((file) => file.drive_file_id) // Filter files with a valid drive_file_id
            .map((file) => ({
                drive_file_id: file.drive_file_id,
                name: file.name,
                drive_version: file.version,
            }));
            formData.append("selected_files", JSON.stringify(selectedFilesFormatted));
            // Append each file
            // uploadedFiles.forEach((fileObj) => {
            //     formData.append("files", fileObj.file);
            // });
            const filesWithoutFileId = uploadedFiles.filter((fileObj) => !fileObj.fileId);

            // Example: Append only files without fileId to formData
            filesWithoutFileId.forEach((fileObj) => {
                formData.append("files", fileObj.file);
            });
            // const formData = {
            //     files: uploadedFiles,
            //     design_id: row.checkout_version.design_id,
            //     comment,
            //     version_id: row.checkout_version._id,
            //     minor_version: minorVersion,
            //     major_version: majorVersion,
            // };

            try {
                setIsFileLoading(true)
                const response = await axios.post(`${BASE_URL}/v1/pdm/checkin-design`, formData, {
                    headers: HEADERS,
                });
                console.log("Form submitted successfully:", response.data);
                if (!response.data?.meta?.success) {
                    toast(response.data?.meta.message);
                } else {
                    
                    onClosePopUp('', 'Checkin was successful!');
                }
                setIsFileLoading(false)
                // navigate to details page
                // nav(WORKSPACE(response.data?.data.sequence_id))
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        }
    }
    const fetchDriveDetails = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(BASE_URL + "/v1/pdm/get-drive-files-version", {
                params: {
                    workspace_sequence_id,
                    design_id: row.checkout_version.design_id
                },
                headers: HEADERS,
            });
            
            if (!response.data?.meta?.success) {
                toast.error(response.data?.meta.message);
            } else {
                
                
                setUploadedFiles(prevFiles => [
                    ...prevFiles, // Add previous files to the array
                    ...response.data.data.files, // Add new files
                ]);
            }
        } catch (error) {
            console.error("Error fetching options data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    

   

    

   
    




    useEffect(() => {
        // Load Google Picker API
        const loadPickerApi = () => {
          const script = document.createElement('script');
          script.src = 'https://apis.google.com/js/api.js';
          script.onload = () => {
            window.gapi.load('picker', () => {
              setIsPickerReady(true); // Picker is ready to use
            });
          };
          document.body.appendChild(script);
        };
    
        if (!window.gapi) {
          loadPickerApi();
        } else if (!window.gapi.picker) {
          window.gapi.load('picker', () => setIsPickerReady(true));
        } else {
          setIsPickerReady(true);
        }
      }, []);
    
      const openPicker = (driveFolerId,accessToken) => {
        console.log(accessToken,driveFolerId)
        if (!isPickerReady) {
          console.error('Google Picker API is not ready.');
          return;
        }
    
        const picker = new window.google.picker.PickerBuilder()
        
         .setOAuthToken(accessToken)  
        .setDeveloperKey(GOOGLE_API_KEY)
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED) // Enable multi-select
          .addView(
            new window.google.picker.DocsView()
              .setIncludeFolders(true)
              .setParent(driveFolerId)
            
          )
          .setCallback((data) => {
            if (data.action === window.google.picker.Action.PICKED) {
              const files = data.docs.map(doc => ({
                drive_file_id: doc.id, // Use `drive_file_id` as the ID
                name: doc.name || doc.title, // Use the name of the file (or title if name is undefined)
              }));
              console.log('Selected files:', files);
              setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
            
            } else if (data.action === window.google.picker.Action.CANCEL) {
              console.log('Picker closed without selection.');
            }
          })
          .build();
          console.log('Access Token:', accessToken);
        picker.setVisible(true);
      };
    

    const uploadFromDrive =async()=>{
        try {
            const response = await axios.get(BASE_URL + "/v1/pdm/get-access-token", {
                params: { workspace_seq_id:workspace_sequence_id },
                headers: HEADERS,
            });
            if (!response.data?.meta?.success) {
                toast.error(response.data?.meta.message);
            } else {
                // setAccessToken(response.data.data.access_token)
                // setDriveFolderId(response.data.data.folder_id)
                openPicker(response.data.data.folder_id,response.data.data.access_token)
                setAddUplaodSelect(false)
            }
        } catch (error) {
            console.error("Error fetching files:", error);
        }
        
    }

    const openUpload=(e)=>{
        e.stopPropagation()
        setAddUplaodSelect(!addUploadSelect)
    }
    return (
        <>
            <div className='create-part-number-popup-page' onClick={()=>setAddUplaodSelect(false)}>
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Checkin design</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    
                        <div className='create-part-num-div-form' style={{ overflowY: 'scroll' }}>
                        {isFileUploading ? <FileUplaoding/> :  <>
                            <div className='create-part-num-div-form-label-input' >
                                <span>Checkin Version: v{majorVersion}.{minorVersion}</span>
                                {/* <span>Drive Version: v{row.drive_version}</span> */}
                            </div>
                            <div className='create-part-num-div-form-label-input' style={{ flexDirection: 'row' }}>
                                <div style={{ flex: 1 }}>
                                    <span>Major version*</span>
                                    <div className='create-part-num-div-form-input'>
                                        <input
                                            type='text'
                                            value={
                                                majorVersion
                                            }
                                            className='create-part-num-div-counter-input'
                                            style={{ width: '75%' }}
                                            disabled
                                        // onChange={(e) =>
                                        //     handlemeaningChange(
                                        //         index,
                                        //         e.target.value,
                                        //         item.meaning,
                                        //         item.default_value
                                        //     )
                                        // }
                                        />

                                        {/* <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Minus Icon' /> */}
                                        <img src={`${ASSET_PREFIX_URL}create-count-plus.png`} alt='Plus Icon' onClick={(option) => handleChangeVersion('major')} />
                                    </div>
                                    {errors && <div className="error"><span className="error-message"
                                        style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {errors}</span></div>}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <span>Minor version*</span>
                                    <div className='create-part-num-div-form-input'>
                                        <input
                                            type='text'
                                            value={
                                                minorVersion
                                            }
                                            className='create-part-num-div-counter-input'
                                            style={{ width: '75%' }}
                                            disabled
                                        // onChange={(e) =>
                                        //     handlemeaningChange(
                                        //         index,
                                        //         e.target.value,
                                        //         item.meaning,
                                        //         item.default_value
                                        //     )
                                        // }
                                        />

                                        {/* <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Minus Icon' /> */}
                                        <img src={`${ASSET_PREFIX_URL}create-count-plus.png `} alt='Plus Icon' onClick={(option) => handleChangeVersion('minor')} />
                                    </div>

                                </div>
                            </div>
                         
                            <div>
                                <div className="create-part-num-div-form-label-attahment">
                                    <span>ADD files*</span>
                                    <div
                                        onDrop={handleFileDrop}
                                        onDragOver={handleDragOver}
                                        className="filedropzone"
                                    >
                                        <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                        {isLoading ? <p>Uploading ....</p> :
                                                <div style={{display:'flex',alignItems:'center',gap:'10px',position:'relative'}}>Drop files to attach or<button style={{display:'flex',alignItems:'center',gap:'10px'}} className="add-upload-btn"
                                                onClick={(e)=>openUpload(e)}
                                                > Add <img src={`${ASSET_PREFIX_URL}upload-add-icon.svg`} alt="" style={{ width: '24px', height: '24px' }}/></button>
                                                {addUploadSelect &&  <div className="upload-type" >
                                                <div className="upload-type-btn" onClick={upload}>
                                                    <img src={`${ASSET_PREFIX_URL}computer-logo.png`} alt="" style={{ width: '24px', height: '24px' }}/>
                                                    <span style={{ color: 'black' }}>Upload from computer</span>
                                                </div>

                                                <div className="upload-type-btn" onClick={uploadFromDrive}>
                                                    <img src={`${ASSET_PREFIX_URL}google-drive-logo.png`} alt="" style={{ width: '24px', height: '24px' }}/>
                                                    <span style={{ color: 'black' }}>Add from Google drive</span>
                                                </div>
                                            </div>}
                                                
                                                
                                                </div>} 

                                    </div>
                                    <input
                                        type="file"
                                        id="fileupld_stp"
                                        style={{ display: "none" }}
                                        onChange={handleFileUpload}
                                        className="btn-upload"
                                        multiple
                                    />
                                    {uploadError && <div className="error"><span className="error-message"
                                        style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {uploadError}</span></div>}
                                </div>
                                <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' uploadType='designs'/>
                                {/* <DriveFilesPreview uploadedFiles={selectedDriveFiles} setUploadedFiles={setSelectedDriveFiles} type='checkin' /> */}
                            </div>


                            {/* {!uploadError && <div className="error">{uploadError}</div>} */}
                            <div className="create-part-num-div-form-label-input" style={{ marginTop: '1%', flexDirection: 'column', width: '100%', height: '100px' }}>
                                <span>Add comment: </span>
                                <div className="create-part-num-div-form-input" style={{ height: '80px' }}>
                                    <textarea
                                        style={{ width: '100%', height: '80px' }}
                                        type="text"
                                        placeholder="Add comment (optional)"
                                        value={comment} onChange={(e) => setComment(e.target.value)}
                                    />
                                </div>
                            </div>
                            </>}
                        </div>
                   
                   

                    <div className='create-part-num-div-btns'>
                    {!isFileUploading && <button onClick={fetchDriveDetails} disabled={isFileUploading}>Get drive files</button>}    
                     {!isFileUploading && <button onClick={submitForm} disabled={isFileUploading}>Checkin</button>}   
                    </div>
                </div>

            </div>
        </>
    )
}
export default DesignCheckinPopUp;
