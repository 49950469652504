

import React, { useState, useEffect } from "react";
import { BASE_URL, HEADERS, LINELOCATIONVIEW, } from "../../constants/config";
import Loading from "../Common/Loading/Loading";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Pagination from "../Common/CommonJsx/Pagination";
import NameProfile from "../Common/CommonJsx/NameProfile";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./TicketList.css";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import CreateLineLocation from "../LineLocations/CreateLineLocation";

function LineLocationList() {
  const [locations, setLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [resetButton, setResetButton] = useState(false);
  const nav = useNavigate();
  const [createLineLocation, setCreateLineLocation] = useState(false);
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const fetchLocations = async () => {
    try {
      setLoading(true);


      const response = await axios.get(
        `${BASE_URL}/v1/location/get-line-loc-list`,
        {
          params: {
            page: currentPage,
            limit: 10,
            search: searchTerm,
          },
          headers: HEADERS,
        }
      );

      const details = response.data.data.line_loc_list;
     
      setLocations(details);
      setTotalPages(response.data.data.total_pages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchLocations();
  }, [currentPage, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setResetButton(true);
  };

  const handleReset = () => {
    setSearchTerm("");
    setResetButton(false);
    setCurrentPage(1);
  };

  const openCreatePartPopUp = () => {
    setCreateLineLocation(!createLineLocation);
  };
  const handleTitleClick = (event, linelocation) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(linelocation.line_location_name);
  };
  return (
    <>

      <div className="depart-main" style={{ marginTop: "80px" }}>
        <div className="part-number-list-title">
          <span>Line locations</span>
          <div className="part-number-list-btns">
            <button onClick={openCreatePartPopUp}>
              <AddOutlinedIcon />
              Create new line Location
            </button>
          </div>
        </div>

        <div className="tck-fltr">
          <SearchFilter
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            listType="location..."
          />
          {resetButton && (
            <button className="reset-btn" onClick={handleReset} data-tour="step-11">
              reset <TuneOutlinedIcon />
            </button>
          )}
        </div>

        
              <div className="department-table">
                <table className="department-table-data">
                  <thead>
                    <tr>
                      <th className="depart-header">ID</th>
                      <th className="depart-header">Line location name</th>
                     
                    </tr>
                  </thead>
                  {loading ? <Loading/> :  <tbody>
                    {locations.map((linelocation) => {
                      return (
                        <tr key={linelocation._id} onClick={() => nav(LINELOCATIONVIEW(linelocation.line_loc_seq_id))}>
                          <td>{linelocation.line_loc_seq_id}</td>
                         
                          <td title={linelocation.line_location_name}>
                            {linelocation.line_location_name.length > 30 ? (
                              <>
                                {linelocation.line_location_name.slice(0, 30)}
                                ...
                                <button className="view-more-text" onClick={(event) => handleTitleClick(event, linelocation)}>
                                  view
                                </button>
                              </>
                            ) : (
                              linelocation.line_location_name
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>}
                 
                </table>
              </div>
            

        {(totalPages > 1 && !loading)  && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        )}
      </div>
      {ticketPopUp && (
        <TicketTitlePopUp
          header='location'
          onClose={()=>setTicketPopUp(!ticketPopUp)}
          ticketTitle={ticketTitle}
        />
      )}
      {createLineLocation && <CreateLineLocation handleClose={() => setCreateLineLocation(!createLineLocation)} />}
    </>
  );
}

export default LineLocationList;
