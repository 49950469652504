import React, { useEffect, useState } from 'react';
import { BASE_URL, HEADERS } from '../../constants/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from './../Common/Loading/Loading';
import { textLettersLimit } from '../../constants/helper';
import CreateDesignPopUp from './CreateDesignPopUp';
import PaginationBtns from '../Common/CommonJsx/PaginationBtns';


function WorkspaceDriveFiles({ workspace_sequence_id }) {
    const [loading, setLoading] = useState(false);
    const [createDesignPopup, setCreateDesignPopup] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [nextPageTokens, setNextPageTokens] = useState([]); // Store tokens
    const [currentPageIndex, setCurrentPageIndex] = useState(0); // Track the index of the token

    useEffect(() => {
        fetchDriveFiles();
    }, [currentPageIndex]);

    const fetchDriveFiles = async () => {
        try {
            setLoading(true);
            const pageToken = nextPageTokens[currentPageIndex]; // Use the current token from the stored tokens
            const response = await axios.get(`${BASE_URL}/v1/pdm/get-drive-files`, {
                params: {
                    workspace_sequence_id,
                    limit: 10,
                    pageToken,
                },
                headers: HEADERS,
            });

            if (!response.data?.meta?.success) {
                toast.error(response.data?.meta?.message);
            } else {
                const { files, next_page_token } = response.data.data;
                setFiles(files); // Update files to the new page's files
                if (next_page_token) {
                    // Add nextPageToken to the array if it's not already present
                    setNextPageTokens((prevTokens) => {
                        const updatedTokens = [...prevTokens];
                        updatedTokens[currentPageIndex + 1] = next_page_token; // Update or add token
                        return updatedTokens;
                    });
                }
            }
        } catch (error) {
            console.error("Error fetching drive files:", error);
            toast.error("Failed to fetch files.");
        } finally {
            setLoading(false);
        }
    };

    const createDesign = (file) => {
        setSelectedFile(file);
        setCreateDesignPopup(!createDesignPopup);
    };

    

    return (
        <>
            <div>
                <table className="part-number-list-table-container">
                    <thead>
                        <tr>
                            <th className="part-number-list-th1">File Name</th>
                            <th className="part-number-list-th1">File Link</th>
                            <th className="part-number-list-th1">File Type</th>
                            <th className="part-number-list-th1">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="4">
                                    <Loading />
                                </td>
                            </tr>
                        ) : (
                            files.map((file, id) => (
                                <tr key={id} style={{ cursor: 'pointer' }}>
                                    <td>{file.name}</td>
                                    <td>
                                        <a
                                            href={file.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: 'blue' }}
                                        >
                                            {textLettersLimit(file.link, 20)}
                                        </a>
                                    </td>
                                    <td>.{file.fileExtension}</td>
                                    <td>
                                        <button
                                            className="tkt-btn"
                                            onClick={() => createDesign(file)}
                                        >
                                            Add to Design
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                {!loading &&   <PaginationBtns setCurrentPageIndex = {setCurrentPageIndex} 
                currentPageIndex ={currentPageIndex} nextPageTokens={nextPageTokens}/>}

              
            </div>

            {createDesignPopup && (
                <CreateDesignPopUp
                    selectedFile={selectedFile}
                    onClose={() => setCreateDesignPopup(false)}
                    workspace_sequence_id={workspace_sequence_id}
                />
            )}
        </>
    );
}

export default WorkspaceDriveFiles;
