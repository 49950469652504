import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ASSET_PREFIX_URL, BASE_URL, HEADERS, WORKSPACE_VISIBILITY_OPTIONS } from "../../constants/config";
import { toast } from "react-toastify";
import NameProfile from "../Common/CommonJsx/NameProfile";
import { formatDateToIST } from "../../constants/helper";
import Loading from "../Common/Loading/Loading";
import MemberDropdown from "../Common/MemberDropdown";

function WorkspaceDetails({ workspace_sequence_id }) {
    const [workspaceDetails, setWorkspaceDetails] = useState({});
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [enabled, setEnabled] = useState({});
    const [fetch, setFetch] = useState(false);
    const nameRef = useRef(null); 
    const descriptionRef = useRef(null);


    useEffect(() => {
        fetchWorkspaceDetails();
    }, [fetch]);

    const fetchWorkspaceDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${BASE_URL}/v1/pdm//get-workspace-detail`,
                {
                    params: {
                        workspace_sequence_id
                    },
                    headers: HEADERS,
                }
            );
            if (!response.data?.meta?.success) {
                toast.error(response.data?.meta.message)
            } else {
                setWorkspaceDetails(response.data?.data.workspace);
                setFormData(response.data?.data.workspace)
            }
        } catch (error) {
            console.error("Error fetching options data:", error);
        } finally {
            setLoading(false);
        }
    }

    const handleSaveChanges = async (key) => {
        setLoading(true);
        let value = '';
        if (key == 'name') {
            value = nameRef.current.value;
        } else if (key == 'description') {
            value = descriptionRef.current.value;
        } else if (key === 'team_members') {
            value = formData[key]
        }
        console.log(formData[key])
        try {
            const response = await axios.post(`${BASE_URL}/v1/pdm/update-workspace-details`, { key, value, workspace_sequence_id }, {
                headers: HEADERS,
            });
            console.log("Form submitted successfully:", response.data);
            if (!response.data?.meta?.success) {
                toast(response.data?.meta.message);
            } else {
                setFetch((prev) => !prev);
                setEnabled({});
            }
        } catch (error) {
            console.error("Error fetching options data:", error);
        } finally {
            setLoading(false);
        }
    }
    const handleEdit = (field) => {
        setEnabled({ [field]: true }); // Enable only the specified field
        console.log(enabled);
    };
    const handleCancelEdit = () => {
        setEnabled({});
    }

    const handleChange = (fieldName, value) => {
        console.log(fieldName, value)
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            border: "1px solid #edf2f7",
            boxShadow: "none",
            color: 'black',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
        option: (provided, state) => ({
            ...provided,
            background: "white",
            color: "black",
            cursor: "pointer",
            ":hover": {
                background: "#610BEE",
                color: "white",
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "black",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            width: "292px",
            backgroundColor: "white",
            color: "black",
        }),
        menuList: (provided) => ({
            ...provided,
            backgroundColor: "white",
        }),
    };


    const watchersChange = (key, selected) => {
        console.log(key, selected)
        // setFormData((prevData) => ({
        //     ...prevData,
        //     [fieldName]: value,
        // }));
        const labelValue = selected.map((item) => {

            return { photo: item.photo ? item.photo : '', member_id: item.value, fullName: item.fullName, _id: item.value };
        });
        console.log(key)
        setFormData(prevState => ({
            ...prevState,
            [key]: labelValue
        }));
    };
    return (
        <>
            <div className='supplier-page-view' style={{ marginTop: '0px', height: '100vh' }}>
                <div className='supplier-page-bottom-page'>
                    <>
                        {
                            (loading) ?
                                <Loading />
                                :
                                <div className='supplier-page-default-fields'>
                                    <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Name:</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                                            <input
                                                ref={nameRef}
                                                type='text'
                                                disabled={!enabled.name}
                                                // placeholder={field.placeholder || ''}
                                                value={formData.name}
                                                onChange={(e) => handleChange('name', e.target.value)}
                                            />
                                            {
                                                (workspaceDetails.is_editable && !enabled.name) && <img
                                                    onClick={() => handleEdit('name')}
                                                    src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                    alt="Edit"
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />

                                            }
                                            {
                                                (enabled.name) && <div style={{ display: 'flex', gap: '5px' }}><img
                                                    onClick={() => handleSaveChanges('name')}
                                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                                    alt=""
                                                    width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                    <img
                                                        onClick={handleCancelEdit}
                                                        src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /></div>
                                            }
                                        </div>
                                    </div>

                                    

                                    <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Created By:</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <NameProfile userName={workspaceDetails.created_by?.fullName} width='32px' memberPhoto={workspaceDetails.created_by?.photo} fontweight='800' />
                                            <span>{workspaceDetails.created_by?.fullName}</span></div>
                                        <span className='supply-custom-label-span' style={{ marginTop: '2%' }}>Created On:</span>
                                        <span>{formatDateToIST(workspaceDetails.createdAt)}</span>
                                    </div>
                                    <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Description:</span>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                                            <textarea
                                                style={{ height: '10vh' }}
                                                className='supply-textarea'
                                                // placeholder={field.placeholder || ''}
                                                ref={descriptionRef}
                                                disabled={!enabled.description}
                                                // placeholder={field.placeholder || ''}
                                                value={formData.description}
                                                onChange={(e) => handleChange('description', e.target.value)}
                                            // onChange={(e) => handleChange(fieldKey, e.target.value)}
                                            />
                                            {
                                                (workspaceDetails.is_editable && !enabled.description) && <img
                                                    onClick={() => handleEdit('description')}
                                                    src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                    alt="Edit"
                                                    width="24px"
                                                    height="24px"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            }
                                            {
                                                (enabled.description) && <div style={{ display: 'flex', gap: '5px' }}><img
                                                    onClick={() => handleSaveChanges('description')}
                                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                                    alt=""
                                                    width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                    <img
                                                        onClick={handleCancelEdit}
                                                        src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /></div>
                                            }
                                            {/* <img
                                    // onClick={() => handleSaveChanges(fieldKey)} 
                                    src={`${ASSET_PREFIX_URL}save-details.png`}
                                    alt=""
                                    width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                <img
                                    // onClick={handleCancelEdit} 
                                    src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /> */}
                                        </div>
                                    </div>
                                    
                                        
                                        <div className='supplier-mandatory-field-container' >
                                            <div style={{ flexDirection: 'row',width:'340px' }}>
                                                <span className='supply-custom-label-span'>Team Members:</span>
                                                <div style={{ display: 'flex', alignItems: 'center',boxSizing:'border-box'  }}>
                                                    <div style={{width:'340px'}}>
                                                        <MemberDropdown field={{
                                                            fieldName: 'team_members',
                                                            value: formData['team_members'] ? formData['team_members'] : workspaceDetails.team_members, isMulti: true
                                                        }}
                                                            handlePersonSelectChange={watchersChange} disable={!enabled.team_members} />
                                                    </div>

                                                    {/* <MemberDropdown fieldName='Team Members' placeholder='select team members' isMulti={true} value={workspaceDetails.team_members}/> */}
                                                    <span style={{ marginLeft: '27%' }}>

                                                        {
                                                            (workspaceDetails.is_editable && !enabled.team_members) && <img
                                                                onClick={() => handleEdit('team_members')}
                                                                src={`${ASSET_PREFIX_URL}edit-ticket.png`}
                                                                alt="Edit"
                                                                width="24px"
                                                                height="24px"
                                                                style={{ cursor: 'pointer' }}
                                                            />

                                                        }
                                                        {
                                                            (enabled.team_members) && <div style={{ display: 'flex', gap: '5px' }}><img
                                                                onClick={() => handleSaveChanges('team_members')}
                                                                src={`${ASSET_PREFIX_URL}save-details.png`}
                                                                alt=""
                                                                width='24px' height='24px' style={{ cursor: 'pointer' }} />
                                                                <img
                                                                    onClick={handleCancelEdit}
                                                                    src={`${ASSET_PREFIX_URL}cancel-detail.png`} alt="" width='24px' height='24px' style={{ cursor: 'pointer' }} /></div>
                                                        }
                                                    </span>
                                                </div>


                                            </div>

                                            {/* {workspaceDetails.team_members?.map((member) => (
                                                <div
                                                    key={member._id} // Always provide a unique key when rendering lists
                                                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                                                >
                                                    <NameProfile
                                                        userName={member.fullName}
                                                        width='32px'
                                                        memberPhoto={member.photo}
                                                        fontweight='800'
                                                    />
                                                    <span>{member.fullName}</span>
                                                </div>
                                            ))} */}

                                        </div>
                                    

                                    {/* <div className='supplier-mandatory-field-container'>
                                        <span className='supply-custom-label-span'>Created On:</span>
                                        <span>{formatDateToIST(workspaceDetails.createdAt)}</span>
                                    </div> */}

                                </div>
                        }
                    </>
                </div>
            </div>
        </>
    );
}
export default WorkspaceDetails;