import React, { useState, useEffect } from "react";
import PopupCloseButton from "../../Common/PopupCloseButton/PopupCloseButton";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, HEADERS } from "../../../constants/config";



function OdooExportPopUp({part_number, setOdoo}) {
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState('');
    const validateForm = () => {
        // if (row.latest_version.version.major == majorVersion && row.latest_version.version.minor == minorVersion) {
        //     setErrors("Increment either major or minor version");
        // }
        // return errors == '';
    };

    const onClose = async (e) => {
        setOdoo(false);
    }

    const submitForm = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        if (!url) {
            toast('URL is mandatory');
            return;
        }
        setLoading(true);
        try {
            const formData = {
                'part_number': part_number,
                "webhook_url": url
            }
            const response = await axios.post(`${BASE_URL}/v1/part-nomen/export-to-odoo`, formData, {
                headers: HEADERS,
            });
            console.log("Form submitted successfully:", response.data);
            setOdoo(false);
            if (response.data?.meta?.success) {
                toast('Data exported successfully');
            } else {
                toast(response.data?.meta?.message);
            }
            // navigate to details page
            // nav(WORKSPACE(response.data?.data.sequence_id))
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            // setIsSubmitting(false); // Re-enable submit button
        }
    };


    return (
        <>
            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' style={{ width: '40%' }}>
                    <div className='create-part-num-div-top'>
                        <span>Export to Odoo</span>
                        <PopupCloseButton
                        handleClose={onClose} 
                        />
                    </div>
                    <div className='create-part-num-div-form' style={{ height: 'auto' }}>
                        <div className="create-part-num-div-form-label-input" style={{ marginTop: '1%', flexDirection: 'row', width:'100%' }}>
                            <div className="create-part-num-div-form-input">
                                <span>Webhook URL: </span>
                                <input
                                    type="text"
                                    placeholder="URL"
                                    value={url} onChange={(e) => setUrl(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='create-part-num-div-btns'>
                        <button 
                        onClick={submitForm}
                         disabled={loading} // Disable button while loading
                        style={{
                        cursor: loading ? "not-allowed" : "pointer",
                        }}
                        
                        >{(loading) ? 'Exporting' : 'Export'}</button>
                    </div>
                </div>

            </div>
        </>
    )
}
export default OdooExportPopUp;
