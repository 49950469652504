import React from "react";
import "./HomeTopNav.css"
import { ASSET_PREFIX_URL } from "../../../constants/config";



function HomeLanding() {
  return (
    <div className="home-landing-page">
      <div className="home-landing-container">
        <div className="home-landing-content" >
          <h5 className="home-landing-teams">FOR HARDWARE TEAMS</h5>
          <div className="home-landing-title">
         Hardware Lifecycle management OS
          </div>
          
          <div className="home-landing-description">
          
              A no-nonsense software suite tailor-made to run hardware
              engineering and manufacturing companies effectively.
          
          </div>
          {/* <div className="home-landing-btns">
            <button className="home-landing-wait-list">Join the Waitlist</button>
            <button className="home-landing-try-demo">Try Demo</button>
          </div> */}
        </div>
        <img src={`${ASSET_PREFIX_URL}girl_img.webp`} alt="" className="home-landing-girl-img" loading="lazy" />
        {/* <img src={`${ASSET_PREFIX_URL}girl_img_mobile.webp`} alt="" className="home-landing-girl-img-mobile" style={{height:'250px', width:'275px'}}/> */}
      </div>
      <div className="home-landing-bottom-img">
        <img src={`${ASSET_PREFIX_URL}desktop-ticket-list.webp`} alt="" />
      </div>
      <div className="home-landing-bottom-img-mobile">
        <img src={`${ASSET_PREFIX_URL}desktop-ticket-list-fullimg.webp`} alt="" />
      
      </div>
    </div>
  );
}

export default HomeLanding;
