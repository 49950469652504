import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./PartNumberView.css";
import {

    BASE_URL,
    HEADERS,
    PARTVIEW
} from "../../constants/config";
import Loading from "../Common/Loading/Loading";
import { textLettersLimit } from "../../constants/helper";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { debounce } from 'lodash';
import { toast } from "react-toastify";

function PartDesignTab({ partNumber, shouldEdit, updatedDetails,
    setUpdatedDetails }) {
    const [hasMore, setHasMore] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedDesign, setSelectedDesign] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [error, setError] = useState('');
    const [quantityUpdate, setQuantityUpdate] = useState(false);
    const [designs, setDesigns] = useState([]);
    const containerRef = useRef(null);
    const LIMIT = 20;


    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearch(value);
        }, 10),
        []
    );
    const handleSearch = (e) => {

        debouncedSearch(e.target.value);
        if (e.target.value !== '') {
            setFetching(true)
            setDesigns([])

            // setActivePage(1); // Reset active page to 1 when searching
            // Call the function to fetch data based on search query
            setActivePage(1);
            //   getPartNumberDesignList() 

        } else {
            setFetching(true)
            setDesigns([])

            //   getPartNumberDesignDetails()
        }
    };
    const getPartNumberDesignList = async () => {
        try {
            setFetching(true)
            // const listofPartNumber = await axios.get(
            //     `${BASE_URL}/v1/part-nomen/get-list`,
            //     {
            //         params:
            //             { search: search, size: LIMIT, page: activePage, partNumber },
            //         headers: headers,
            //     }
            // );
            const designResponse = await axios.get(BASE_URL + "/v1/pdm/get-designs-parts", {
                params: {
                    page: activePage,
                    limit: LIMIT,
                    search,
                },
                headers: HEADERS,
            });
            if (!designResponse.data?.meta?.success) {
                toast.error(designResponse.data?.meta.message)
            } else {
                const designs = designResponse.data.data.designs;
                setDesigns((prev) => {
                    return [
                        ...new Set([...prev, ...designs]),
                    ];
                });
            }
            // setHasMore(listofPartNumber.data.data.non_existing_parts.length === LIMIT);
            setFetching(false)
        } catch (error) {
            console.log(error);
        }
    };
    const getPartNumberDesignDetails = async () => {
        try {
            setFetching(true)
            const designResponse = await axios.post(
                `${BASE_URL}/v1/pdm/get-part-linked-design`,
                {
                    part_number: partNumber,
                },
                { headers: HEADERS }
            );
            if (designResponse.data.meta.success) {
                setDesigns(designResponse.data.data.designs)
            } else {
                toast(designResponse.data.meta.message);
            }
            // Increment activePage only after successful response
            setFetching(false)
        } catch (error) {
            console.log(error);
        }
    };
    // Update parts when the active page or search query changes
    useEffect(() => {
        if (search !== '') {

            getPartNumberDesignList()
        } else {
            getPartNumberDesignDetails();
        }
    }, [activePage, search, updatedDetails]);
    const handleScroll = debounce(() => {
        const container = containerRef.current;
        if (!hasMore || fetching) {

            return;
        }


        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 20) {
            setActivePage(prevPage => prevPage + 1);

        }
    }, 500);

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [containerRef, handleScroll]);


    const handleAction = async (action) => {
        // const updatedParts = selectedParts.map(({ part_number, quantity, part_title }) => ({
        //     part_number,
        //     quantity,
        //     part_title
        // }));
        try {
            setFetching(true)
            const res = await axios.post(
                `${BASE_URL}/v1/pdm/update-part-linked-design`,
                {
                    part_number: partNumber,
                    design_id: selectedDesign._id,
                    version_id: selectedDesign.latest_version._id,
                    action
                },
                { headers: HEADERS }
            );
            if (!res.data.meta.success) {
                toast(res.data.meta.message);
            } else {
                toast('Design added successfully!!');
                setUpdatedDetails([]);
            }
            //     setUpdatedDetails(updatedParts)
            setFetching(false)
        } catch (error) {
            console.log(error);
        } finally {
            setFetching(false)
        }
    };

    const handleQuantityChange = (e, partId) => {

        // setQuantityUpdate(true)
        // const newQuantity = e.target.value;
        // // Update the partOptions array with the new quantity
        // setPartOptions((prevPartOptions) =>
        //     prevPartOptions.map((part) =>
        //         part.part_number === partId ? { ...part, quantity: newQuantity } : part
        //     )
        // );
        // setError('')
    };
    return (
        <div style={{ width: '100%', boxSizing: 'border-box' }} >
            {shouldEdit &&

                <SearchFilter searchTerm={search} handleSearchChange={handleSearch} listType='design name to replace...' />
            }

            {/* {fetching ? <Loading smallScreen={true} /> : */}
            <>

                <div className="part-number-parts-divs" ref={containerRef} onScroll={handleScroll}>
                    {designs.length === 0 === 0 ? <div className='data-not-found-img'>
                        <span>No design Linked!</span>
                    </div> : <>
                        {designs.map((design, index) => (
                            <div key={design._id} className="part-numbers-views">
                                <div className="part-numbers-views-details">
                                    <div style={{ display: 'flex', gap: '15px' }}>
                                        {shouldEdit && (
                                            <div className="part-numbers-views-checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedDesign._id === design._id}
                                                    onChange={(e) => {
                                                        // const isChecked = e.target.checked;
                                                        setSelectedDesign(design)
                                                        // if (item.quantity) {
                                                        //     if (isChecked) {
                                                        //         setSelectedParts([...selectedParts, item]);
                                                        //     } else {
                                                        //         setSelectedParts(selectedParts.filter((part) => part.part_number !== item.part_number));
                                                        //     }
                                                        //     setError('');  // Clear error if present
                                                        // } else {
                                                        //     setError("Please enter quantity for the part before selecting.");
                                                        //     e.target.checked = false;  // Prevent selecting without quantity
                                                        // }
                                                    }}
                                                />
                                            </div>
                                        )}



                                        <div className="part-numbers-views-content">
                                            {/* <a href={PARTVIEW(item.part_number)} target='_blank' className='link-to-view-ec'>
                                            {textLettersLimit(item.part_number, 24)}</a> */}
                                            {/* <span className="part-number-view-number">{item.part_number}</span> */}
                                            <span className="part-number-view-title" style={{ fontSize: '16px' }}>
                                                {design.name}- v{design.latest_version.version.major}.{design.latest_version.version.minor}
                                            </span>
                                        </div>
                                    </div>

                                    {/* <div>
                                    <input type="number" value={item.quantity}
                                        placeholder="+Qty"
                                        onChange={(e) => handleQuantityChange(e, item.part_number)}
                                        disabled={shouldEdit && selectedParts.some(part => part.part_number === item.part_number)}
                                    />
                                </div> */}
                                </div>
                            </div>
                        ))}
                    </>}



                </div>
                <>
                    {error !== '' && <span className="error-message">{error}</span>}
                    {shouldEdit && (
                        <>
                            {search !== '' && (
                                <button
                                    className="part-view-delete-parts"
                                    onClick={() => handleAction('add')}
                                    disabled={Object.values(selectedDesign).length === 0}
                                >
                                    Add
                                </button>
                            )}
                            {(search === '') && (
                                <button
                                    className="part-view-delete-parts"
                                    onClick={() => handleAction('remove')}
                                    disabled={designs.length < 1}
                                >
                                    Remove
                                </button>
                            )}
                            <div>{/* Content here */}</div>
                        </>
                    )}
                </>

            </>

            {/* } */}


        </div>
    )
}

export default PartDesignTab