import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls, Environment } from '@react-three/drei';
import * as THREE from 'three';
import { GLB_PREFIX_URL } from '../../constants/config'
import PartDesignPreview from '../PartDesignPDM/PartDesignPreview';
import Model from './Model'
import PDMLoader from './PDMLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLB_CHUNK_SIZE } from '../../constants/config'

// Utility function for logging
const log = (message, object) => {
    console.log(message, object);
    if (object instanceof Error) {
        console.error(object);
    }
};

// Camera controls component for rotating and zooming
function CameraController({ zoomIn, zoomOut, rotate }) {
    const { camera , invalidate} = useThree();
    useEffect(() => {
        // const rotationStep = Math.PI / 12; // Rotation step size
        // const zoomStep = 4; // Zoom step size
        const target = new THREE.Vector3(0, 0, 0); // Assuming you're looking at the origin
        const distanceToTarget = camera.position.distanceTo(target);
        const zoomStep = Math.max(0.01, distanceToTarget * 0.10);
        // Handle Zoom In/Out
        if (zoomIn) {
            camera.position.add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(zoomStep)); // Zoom in
        }
        if (zoomOut) {
            camera.position.add(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(-zoomStep)); // Zoom out
        }

        // // Get current spherical coordinates
        // const spherical = new THREE.Spherical();
        // spherical.setFromVector3(camera.position);

        // // Adjust spherical coordinates for rotation
        // if (rotate === 'up') spherical.phi = Math.max(0.1, spherical.phi - rotationStep); // Prevent flipping over at phi = 0
        // if (rotate === 'down') spherical.phi = Math.min(Math.PI - 0.1, spherical.phi + rotationStep); // Prevent flipping over at phi = π
        // if (rotate === 'left') spherical.theta -= rotationStep; // Rotate left
        // if (rotate === 'right') spherical.theta += rotationStep; // Rotate right

        // // Convert back to Cartesian coordinates and update camera position
        // camera.position.setFromSpherical(spherical);
        // camera.lookAt(0, 0, 0); // Ensure camera looks at the center of the scene
        if (rotate) {
            const distance = 5; // Adjust the zoom level based on your model's size
            if (!camera) return; // Ensure camera is ready
            switch (rotate) {
                case 'up':
                    camera.position.set(0, 0, distance);
                    break;
                case 'left':
                    camera.position.set(0, 0, -distance);
                    break;
                case 'right':
                    camera.position.set(-distance, 0, 0);
                    break;
                case 'down':
                    camera.position.set(distance, 0, 0);
                    break;
                case 'ISOMETRIC':
                    camera.position.set(distance, distance, distance);
                    break;
                default:
                    camera.position.set(0, 0, distance); // Default to front view
            }
        }
        camera.lookAt(0, 0, 0); // Ensure the camera looks at the center of the model
        // invalidate();
    }, [zoomIn, zoomOut, rotate]);

    return null; // No UI component
}



// Component that updates camera based on selected view
function CameraControls({ currentView }) {
    const { camera, invalidate } = useThree(); // Access the camera and trigger re-render

    useEffect(() => {
        const distance = 5; // Adjust the zoom level based on your model's size

        if (!camera) return; // Ensure camera is ready

        // Update camera position based on the selected view
        switch (currentView) {
            case 'FRONT':
                camera.position.set(0, 0, distance);
                break;
            case 'BACK':
                camera.position.set(0, 0, -distance);
                break;
            case 'LEFT':
                camera.position.set(-distance, 0, 0);
                break;
            case 'RIGHT':
                camera.position.set(distance, 0, 0);
                break;
            case 'TOP':
                camera.position.set(0, distance, 0);
                break;
            case 'BOTTOM':
                camera.position.set(0, -distance, 0);
                break;
            case 'ISOMETRIC':
                camera.position.set(distance, distance, distance);
                break;
            default:
                camera.position.set(0, 0, distance); // Default to front view
        }

        camera.lookAt(0, 0, 0); // Ensure the camera looks at the center of the model
        invalidate(); // Trigger a re-render in the Canvas
    }, [currentView, camera, invalidate]);

    return null; // No UI needed for this component
}

export default function PartDesign() {
    const [explosionFactor, setExplosionFactor] = useState(0.0000); // State for explosion factor
    const [positions, setPositions] = useState([]);
    const [loadedScene, setLoadedScene] = useState(null); // Store scene reference here
    const [currentView, setCurrentView] = useState('FRONT'); // Keep track of the current view
    const [parts, setParts] = useState([]); // Store parts for the assembly tree
    const { filekey } = useParams();
    const url = `${GLB_PREFIX_URL}${filekey}`;
    const [zoomIn, setZoomIn] = useState(false);
    const [zoomOut, setZoomOut] = useState(false);
    const [rotateDirection, setRotateDirection] = useState('');
    const [scene, setScene] = useState(null);
    // Handle zoom and rotation controls
    const handleZoomIn = () => setZoomIn(true);
    const handleZoomOut = () => setZoomOut(true);
    const handleRotate = (direction) => setRotateDirection(direction);
    // Define a common button style for the controls
    const buttonStyle = {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'white',
        fontSize: '20px',
        cursor: 'pointer',
        padding: '5px',
    };

    // Reset zoom/rotate triggers
    // useEffect(() => {
    //     if (zoomIn || zoomOut || rotateDirection) {
    //         // Reset zoom/rotate triggers after they are handled by the CameraController
    //         const timeout = setTimeout(() => {
    //             setZoomIn(false);
    //             setZoomOut(false);
    //             setRotateDirection('');
    //         }, 100); // Reset after 100ms to allow smooth updates

    //         return () => clearTimeout(timeout); // Cleanup timeout
    //     }
    // }, [zoomIn, zoomOut, rotateDirection]);


    // Handle part visibility toggle
    const handlePartVisibilityChange = (id) => {
        setParts((prevParts) =>
            prevParts.map((part) =>
                part.id === id ? { ...part, visible: !part.visible } : part
            )
        );
        const updatedPart = parts.find((part) => part.id === id);
        if (updatedPart) {
            updatedPart.mesh.visible = !updatedPart.mesh.visible; // Toggle visibility in the 3D scene
        }
    };
    // Handle part hover to highlight the part
    const handlePartHover = (id, isHovering) => {
        const part = parts.find((part) => part.id === id);
        if (part) {
            if (isHovering) {
                part.mesh.material = new THREE.MeshBasicMaterial({
                    color: 'yellow', // Highlight with yellow
                });
            } else {
                part.mesh.material = part.originalMaterial; // Restore original material
            }
        }
    };


    // Function to compute exploded positions dynamically based on the model structure
    const computeExplodedPositions = (scene) => {
        if (!scene) return; // Ensure scene is loaded
        const originalPositions = [];
        const modelBox = new THREE.Box3().setFromObject(scene);
        const modelCenter = modelBox.getCenter(new THREE.Vector3()); // Get the center of the model

        scene.traverse((child) => {
            if (child.isMesh) {
                const childBox = new THREE.Box3().setFromObject(child);
                const childCenter = childBox.getCenter(new THREE.Vector3());
                const direction = childCenter.clone().sub(modelCenter).normalize(); // Get direction from model center to child

                // Store original position (child's bounding box center)
                const originalPosition = child.position.clone();

                // Calculate the exploded position once based on a maximum explosion factor
                const explodedPosition = originalPosition.clone().add(direction.multiplyScalar(1)); // Exploded position at max explosion

                originalPositions.push({
                    child,
                    originalPosition: originalPosition, // Store original position
                    explodedPosition: explodedPosition  // Store exploded position
                });
            }
        });
        setPositions(originalPositions); // Save the original and exploded positions
    };

    // Reset functionality to return the model to its original position
    const handleResetClick = () => {
        setExplosionFactor(0.0000); // Reset explosion factor to initial value
        // if (loadedScene) {
        //     computeExplodedPositions(loadedScene); // Reset positions
        // }
    };

    // Callback to receive the loaded scene from the Model component
    const handleSceneLoaded = (scene) => {
        setLoadedScene(scene); // Store scene reference when the model loads
        computeExplodedPositions(scene); // Initially compute positions based on the model structure
    };

    // Handle slider change and immediately recompute exploded positions
    const handleSliderChange = (event) => {
        const newExplosionFactor = parseFloat(event.target.value);
        setExplosionFactor(newExplosionFactor); // Update explosion factor based on slider position
    };

    // Handle the increment and decrement of explosion factor via buttons
    const incrementExplosionFactor = () => {
        setExplosionFactor((prev) => {
            const newFactor = Math.min(prev + 0.0002, 1); // Cap the maximum value at 1
            return newFactor;
        });
    };

    const decrementExplosionFactor = () => {
        setExplosionFactor((prev) => {
            const newFactor = Math.max(prev - 0.0002, 0.0000); // Set minimum value at 0.0001
            return newFactor;
        });
    };
    const fetchChunk = async (chunk) => {
        const response = await fetch(url, {
            headers: {
                Range: `bytes=${chunk.start}-${chunk.end}`
            }
        });
        if (!response.ok) {
            throw new Error(`Failed to fetch chunk ${chunk.start}-${chunk.end}`);
        }
        return response.arrayBuffer();
    };

    const fetchAndAssembleModel = async () => {
        const headResponse = await fetch(url, { method: 'HEAD' });
        const contentLength = headResponse.headers.get('content-length');
        const fileSize = parseInt(contentLength, 10);
        if (!fileSize) {
            throw new Error('Failed to retrieve file size');
        }

        // Step 2: Determine the range for each chunk
        const chunks = [];
        for (let start = 0; start < fileSize; start += GLB_CHUNK_SIZE) {
            const end = Math.min(start + GLB_CHUNK_SIZE - 1, fileSize - 1);
            chunks.push({ start, end });
        }
        const chunkData = await Promise.all(chunks.map(fetchChunk));
        // Step 4: Concatenate all chunks into a single ArrayBuffer
        const totalBuffer = new Uint8Array(fileSize);
        let offset = 0;
        chunkData.forEach(chunkBuffer => {
            totalBuffer.set(new Uint8Array(chunkBuffer), offset);
            offset += chunkBuffer.byteLength;
        });

        // Step 3: Parse the assembled buffer with GLTFLoader
        const loader = new GLTFLoader();
        // Set up the DRACOLoader
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/'); // Path to the Draco decoder
        loader.setDRACOLoader(dracoLoader);
        loader.parse(totalBuffer.buffer, '', (gltf) => {
            // Center and scale the model
            const box = new THREE.Box3().setFromObject(gltf.scene);
            const center = box.getCenter(new THREE.Vector3());
            const size = box.getSize(new THREE.Vector3());
            const maxDim = Math.max(size.x, size.y, size.z);
            const scale = 2 / maxDim;
            gltf.scene.scale.setScalar(scale);
            gltf.scene.position.sub(center.multiplyScalar(scale));
            // Optionally, populate parts for the assembly tree
            const parts = [];
            gltf.scene.traverse((child) => {
                if (child.isMesh) {
                    parts.push({
                        id: child.uuid,
                        name: child.name || 'Unnamed Part',
                        mesh: child,
                        visible: true,
                        originalMaterial: child.material, // Store original material
                    });
                }
            });
            setScene(gltf.scene); // Set the loaded scene
            setParts(parts); // Store the parts in state for the assembly tree
        });
    };

    useEffect(() => {
        fetchAndAssembleModel()
    }, []);
    return (
        <PartDesignPreview handleRotate={handleRotate} handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut}>
            {
                (scene) ?
                    <Canvas
                        dpr={Math.min(window.devicePixelRatio, 1.5)}
                        frameloop="demand" // Disable continuous animation loop
                        shadows={false}  // Disable shadows if not critical
                        gl={{ antialias: true, powerPreference: "high-performance" }}
                    >
                        {/* <ambientLight />
                        <Environment preset="studio" /> */}
                        <Environment preset="studio" intensity={0.5} />
                        <directionalLight position={[5, 5, 5]} intensity={0.6} />
                        <ambientLight intensity={0.2} />
                        <Suspense fallback={<mesh><boxGeometry args={[1, 1, 1]} /><meshStandardMaterial color="red" /></mesh>}>
                            <Model  scene={scene} url={url} explosionFactor={explosionFactor} positions={positions} onSceneLoaded={handleSceneLoaded} setParts={setParts} rotateDirection={rotateDirection} />
                        </Suspense>
                        {/* <CameraControls currentView={currentView} /> */}
                        {/* Camera Controller */}
                        <CameraController zoomIn={zoomIn} zoomOut={zoomOut} rotate={rotateDirection} />
                        {/* <OrbitControls /> */}
                    </Canvas>
                    : <PDMLoader />
            }

        </PartDesignPreview>
    );
}
