import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL, PHOTO_LINK, WORKSPACE, ASSET_PREFIX_URL, HEADERS, WORKSPACES } from "../../constants/config";

import Loading from "../Common/Loading/Loading";
import Pagination from "../Common/CommonJsx/Pagination";
import { toast } from "react-toastify";
import NameProfile from "../Common/CommonJsx/NameProfile";
import "./PDM.css";
import DesignCheckoutPopUp from "./DesignCheckoutPopUp";
import DesignCheckinPopUp from "./DesignCheckinPopUp";
import DownloadFilesPopUp from "./DownloadFilesPopUp";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import TuneOutlined from "@mui/icons-material/TuneOutlined";
import Tooltip from '@mui/material/Tooltip';
import DesignDeletePopUp from "./DesignDeletePopup";
import { useNavigate } from "react-router-dom";


function WorkspaceFiles({ workspace_sequence_id, openActivityLog }) {
    const [loading, setLoading] = useState(false);
    const nav = useNavigate()
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [designs, setDesigns] = useState([]);
    const [currentModal, setCurrentModal] = useState(null); // Track which modal to show
    const [currentRow, setCurrentRow] = useState(null); // Track the selected row
    const [fetch, setFetch] = useState(false); // Track the selected row
    const [searchTerm, setSearchTerm] = useState(""); // Search filter state
    const [resetButton, setResetButton] = useState(false); // Reset button visibility
    const itemsPerPage = 10;
    useEffect(() => {
        fetchDesigns();
    }, [currentPage, itemsPerPage, fetch, searchTerm]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setResetButton(true);
    };

    const handleReset = () => {
        setSearchTerm("");
        setResetButton(false);
        setCurrentPage(1);
    };

    // Dynamically generate options based on row status
    const getOptions = (design) => {
        console.log(design)
        const memberDetails = JSON.parse(localStorage.getItem("memberDetails"));
        return [
            { value: 'checkout', label: 'Checkout', path: `${ASSET_PREFIX_URL}ic-checkout.svg`, isDisabled: (design.status == 'checked_out') ? true : false },
            { value: 'checkin', label: 'Checkin', path: `${ASSET_PREFIX_URL}ic-checkin.svg`, isDisabled: (design.status == 'checked_out' && design.checkout_version.checked_out_by === memberDetails.id) ? false : true },
            { value: 'download', label: 'Download Files', path: `${ASSET_PREFIX_URL}ic-downld.svg` },
            { value: 'history', label: 'Activity Log', path: `${ASSET_PREFIX_URL}ic-activity-log.svg` },
            { value: 'delete', label: 'Delete', path: `${ASSET_PREFIX_URL}ic-delete.svg` },
            // { value: 'download', label: 'Download Files' }
        ];
       

    };
    const onClosePopUp = (modal, message) => {
        if (modal) {
            setFetch((prev) => !prev);
            setCurrentModal(modal);
            toast(message)
        } else {
            setFetch((prev) => !prev);
            if (message) toast(message);
            setCurrentRow(null);
            setCurrentModal(null);
        }
        setSelectedOptions({});
    }

    const onClose = () => {
        setCurrentModal(null);
        setCurrentRow(null);
        setSelectedOptions({});
    }
    // Handle selection change for each row
    const handleChange = (selectedOption, row) => {
        console.log(selectedOption);
        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [row._id]: selectedOption,
        }));
        // Determine which modal to show based on the selected option
        switch (selectedOption.value) {
            case 'checkout':
                setCurrentModal('checkout');
                break;
            case 'checkin':
                setCurrentModal('checkin');
                break;
            case 'download':
                setCurrentModal('download');
                break;
            case 'delete':
                setCurrentModal('delete');
                break;
            case 'history':
                openActivityLog(row._id);
                break;
            default:
                setCurrentModal('checkout');
        }
        // Set the selected row for the modal
        setCurrentRow(row);
    };
    const fetchDesigns = async () => {
        try {
            setLoading(true);
            const designResponse = await axios.get(BASE_URL + "/v1/pdm/get-designs", {
                params: {
                    page: currentPage,
                    limit: itemsPerPage,
                    workspace_sequence_id,
                    search: searchTerm,
                },
                headers: HEADERS,
            });
            if (!designResponse.data?.meta?.success) {
             
                nav(WORKSPACES)
                toast.error(designResponse.data?.meta.message)
            } else {
                setDesigns(designResponse.data.data.designs);
                setTotalPages(designResponse.data.data.pagination.totalPages);
                setCurrentPage(designResponse.data.data.pagination.currentPage);
            }
            // setSelectMember(response.data.data);
        } catch (error) {
            console.error("Error fetching options data:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="workspace-file-list-container" style={{ marginTop: '0px !important' }}>
                <div className="tck-fltr">
                    <SearchFilter
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        listType="name" />
                    {resetButton && (
                        <button className="reset-btn" onClick={handleReset} data-tour="step-11">
                            reset <TuneOutlined />
                        </button>
                    )}
                </div>
                <div>
                    <table className="part-number-list-table-container">
                        <thead>
                            <tr>
                                <th className="part-number-list-th1">Name</th>
                                <th className="part-number-list-th1">Latest Version</th>
                                <th className="part-number-list-th1">Status</th>
                                <th className="part-number-list-th1">Last Checkout/Checkin By</th>
                                <th className="part-number-list-th1" >Actions </th>
                                {/* <th className="part-number-list-th1" >Last Checkout Time </th>
                                <th className="part-number-list-th1">Last Checkin By</th>
                                <th className="part-number-list-th1" >Last Checkin Time </th>
                                <th className="part-number-list-th1" >Action </th> */}
                            </tr>
                        </thead>
                        <>
                            {
                                (loading) ?
                                    <Loading />
                                    :
                                    (designs?.map((design) => (
                                        <tr
                                            key={design._id}
                                            style={{ cursor: 'pointer' }}>
                                            <td>{design.name}</td>
                                            <td>{design.latest_version.version.major}.{design.latest_version.version.minor}</td>
                                            <td>{(design.status == 'checked_in') ? 'Checked In' : 'Checked Out'}</td>
                                            <td className="part-number-photo">
                                                {
                                                    (design.latest_version.checked_out_by_details) ?
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}><NameProfile userName={design.latest_version.checked_out_by_details.fullName} width='26px' memberPhoto={design.latest_version.checked_out_by_details.photo} fontweight='500' />
                                                            <span>{design.latest_version.checked_out_by_details.fullName}</span></div> :
                                                        <div></div>
                                                }

                                            </td>
                                            <td>
                                                {getOptions(design).map((option) => (
                                                    
                                                        (option.isDisabled) ?
                                                        <Tooltip key={option.value} title={option.label} arrow>
                                                            <img
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px',
                                                                    cursor: 'pointer',
                                                                    marginRight: '10px',
                                                                    padding: '8px !important',
                                                                    opacity: 0.24,
                                                                    transition: 'opacity 0.3s', // Smooth transition
                                                                }}
                                                                // onMouseOver={(e) => (e.currentTarget.style.opacity = 1)} // Full opacity on hover
                                                                // onMouseOut={(e) => (e.currentTarget.style.opacity = 0.8)} // Reset opacity on mouse out
                                                                src={option.path}
                                                                alt={option.label}
                                                                // onClick={(e) => handleChange(option, design)}
                                                            />
                                                        </Tooltip> :
                                                        <Tooltip key={option.value} title={option.label} arrow>
                                                            <img
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px',
                                                                    cursor: 'pointer',
                                                                    marginRight: '10px',
                                                                    padding: '8px !important',
                                                                    opacity: 0.8,
                                                                    transition: 'opacity 0.3s', // Smooth transition
                                                                }}
                                                                onMouseOver={(e) => (e.currentTarget.style.opacity = 1)} // Full opacity on hover
                                                                onMouseOut={(e) => (e.currentTarget.style.opacity = 0.8)} // Reset opacity on mouse out
                                                                src={option.path}
                                                                alt={option.label}
                                                                onClick={(e) => handleChange(option, design)}
                                                            />
                                                        </Tooltip>
                                                
                                                   
                                                ))}
                                            </td>
                                            {/* <td>{formatDateToIST(design.latest_version.checked_out_at)}</td>
                                            <td className="part-number-photo">
                                                {
                                                    (design.latest_version.checked_in_by_details) ?
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}><NameProfile userName={design.latest_version.checked_in_by_details.fullName} width='26px' memberPhoto={design.latest_version.checked_in_by_details.photo} fontweight='500' />
                                                            <span>{design.latest_version.checked_in_by_details.fullName}</span></div> :
                                                        <div></div>
                                                }

                                            </td>
                                            <td>{formatDateToIST(design.latest_version.checked_in_at)}</td>
                                            <td>
                                                <Select
                                                    value={selectedOptions[design._id] || null}
                                                    options={getOptions(design.status)} // Generate options based on row status
                                                    onChange={(option) => handleChange(option, design)}
                                                    placeholder="Select an action"
                                                />
                                            </td> */}
                                        </tr>
                                    )))
                            }

                        </>
                    </table>

                </div>
                {totalPages > 1 && !loading && (
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                )}
            </div>
            {
                (currentModal === 'checkout') && <DesignCheckoutPopUp row={currentRow} onClose={onClose} onClosePopUp={onClosePopUp} />
            }
            {
                (currentModal === 'checkin') && <DesignCheckinPopUp row={currentRow} onClose={onClose} onClosePopUp={onClosePopUp} workspace_sequence_id={workspace_sequence_id} />
            }
            {
                (currentModal === 'download')
                &&
                <DownloadFilesPopUp
                    row={{
                        major_version: currentRow.latest_version.version.major,
                        minor_version: currentRow.latest_version.version.minor,
                        files: currentRow.latest_version.files,
                     
                        name: currentRow.name
                    }}
                    onClose={onClose} workspace_sequence_id={workspace_sequence_id}/>
            }
            {
                (currentModal === 'delete') && <DesignDeletePopUp row={currentRow} onClose={onClose} onClosePopUp={onClosePopUp} />
            }
        </>
    );
}
export default WorkspaceFiles;