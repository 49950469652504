import React, { useState } from 'react'
import { ASSET_PREFIX_URL, BUCKET, PHOTO_LINK, BASE_URL, HEADERS } from '../../../constants/config';
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useParams } from 'react-router-dom';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import axios from 'axios';
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

function DownloadAttachment({ uploadedFiles,workspace_sequence_id }) {
  const { workspace_id } = useParams()
  const getFileIcon = (fileName, name) => {

    const extension = name.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <div
          style={{ overflow: "hidden", border: "none", }}
          className="extension-type-div"
        >
          <iframe
            src={`${PHOTO_LINK}${fileName}`}
            title="CSV Preview"
          //   width= '75px'
          // height= '75px'
          // border= 'none'
          // style={{ overflowY: 'hidden', border: 'none',padding:'0px' }}
          // scrolling="no"
          />
        </div>
      );
    } else if (extension === "xls" || extension === "xlsx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xlsFile.png`}
          alt="Excel"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "xml") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}xmlFile.png`}
          alt="XML"
          width="75px"
          height="75px"
        />
      );
    } else if (
      extension === "jpg" ||
      extension === "png" ||
      extension === "jpeg" ||
      extension === "svg"
    ) {
      return <img src={`${PHOTO_LINK}${fileName}`} alt="" className="png-img-div" />;
    } else if (extension === "pptx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}pptxFile.png`}
          alt="ppt"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "csv") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}csvFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else if (extension === "docx") {
      return (
        <img
          src={`${ASSET_PREFIX_URL}wordFile.png`}
          alt="csv"
          width="75px"
          height="75px"
        />
      );
    } else {
      return (
        <img
          src={`${ASSET_PREFIX_URL}filesImage.png`}
          alt="File"
          width="75px"
          height="75px"
        />
      );
    }
  };

  //   const handleDownload = async (file, index, uploadedFiles) => {
  //     try {
  //         const response = await axios.post(
  //             `${BASE_URL}/v1/pdm/download-drive-file`,
  //             {
  //                 workspace_sequence_id: workspace_id,
  //                 file_id: file.drive_file_id,
  //                 revisionHead: file.drive_version,
  //             },
  //             {
  //                 headers: HEADERS,
  //                 responseType: "blob", // Ensure binary data is returned
  //             }
  //         );
  //         console.log(response)
  //         // Check success from the response
  //         if (response.data.meta && response.data.meta.success === false) {
  //             console.log("File download via Drive failed, falling back to S3");
  //             handleS3Download(index, uploadedFiles);
  //             return;
  //         }
  //         else{
  //           const blob = new Blob([response.data], { type: response.headers["content-type"] });
  //           const link = document.createElement("a");
  //           link.href = window.URL.createObjectURL(blob);
  //           link.download = file.name || `revision-${file.drive_version}.bin`;
  //           document.body.appendChild(link); // Append to ensure compatibility
  //           link.click();
  //           link.remove(); // Cleanup the link
  //           window.URL.revokeObjectURL(link.href); // Cleanup the Blob URL
  //         }

  //         // Create a downloadable Blob and trigger the download

  //     } catch (error) {
  //         console.error("Error downloading file:", error);
  //     }
  // };


  const handleDownload = async (file, index, uploadedFiles) => {
    console.log(file)
    try {
      const response = await axios.get(BASE_URL + "/v1/pdm/get-access-token", {
        params: { workspace_seq_id: workspace_sequence_id },
        headers: HEADERS,
      });
      if (!response.data?.meta?.success) {
        toast.error(response.data?.meta.message);
      } else {
        downloadRevision(file.drive_file_id,file.drive_version,response.data.data.access_token,index, uploadedFiles)
       
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
  const downloadRevision = async (fileId, revisionId, accessToken, index, uploadedFiles) => {
    try {
      const url = `https://www.googleapis.com/drive/v3/files/${fileId}/revisions/${revisionId}?alt=media`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (!response.ok) {
        console.error(`Error fetching revision: ${response.status} - ${response.statusText}`);
        handleS3Download(index, uploadedFiles); // Fallback to S3 download
        return; // Stop further execution
      }
  
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'downloaded-file'; // You can specify the file name dynamically if needed
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl); // Free memory
    } catch (error) {
      console.error('Download error:', error.message); // Log the error message
      console.log('Full error details:', error); // Log full error details for debugging
    }
  };
  
  

  const handleS3Download = async (index, uploadedFiles) => {
    const { key, name } = uploadedFiles[index];
    const preSignedURL = await axios.post(
      `${BASE_URL}/v1/member/get-presigned-url-for-download`,
      { bucket_name: BUCKET, key, file_name: name },
      { headers: HEADERS }
    );
    if (
      preSignedURL.data.meta.code === 200 &&
      preSignedURL.data.meta.message === "SUCCESS" &&
      preSignedURL.data.data.url
    ) {
      const link = document.createElement('a');
      link.href = preSignedURL.data.data.url; // File URL from the uploaded files list
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <div className="uploaded-files-view" style={{ flexDirection: 'row', width: '100%' }}>
      {uploadedFiles.map((file, index) => (
        <div key={index} className="uploaded-file-view" style={{ width: '12%' }}>
          <div className="file-details-view">
            <div className="file-image-preview">
              {getFileIcon(file.key, file.name)}
            </div>


            <div className="upld-file-details-view">
              <span title={file.name} className="file-view-name">
                {file.name.length > 20
                  ? file.name.substring(0, 15) + "..."
                  : file.name}
              </span>
            </div>

            <div className="file-details-view-hover">
              <a onClick={() => handleDownload(file, index, uploadedFiles)} target="_blank">
                <div className="file-box-link">
                  <span
                    title={file.name}
                    className="file-view-name-view"
                  >
                    {file.name.length > 20
                      ? file.name.substring(0, 18) + "..."
                      : file.name}
                  </span>
                </div>
              </a>

              <div className="file-delete">
                <CloudDownloadOutlinedIcon
                  onClick={() => handleDownload(file, index, uploadedFiles)}
                // onClick={() => handleDownload(index, uploadedFiles)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default DownloadAttachment