import React, { useState } from 'react';
import Papa from 'papaparse';
import { ASSET_PREFIX_URL,BASE_URL, HEADERS } from '../../constants/config';
import Logo from '../Common/Logo';
import RightContainer from '../Common/RightContainer';
import axios from 'axios';
import './Addmembers.css';

function AddMembers() {
  const [memberCount, setMemberCount] = useState(1); // Initial count of input boxes
  const [emailList, setEmailList] = useState([]); // Store extracted emails

  const addMember = () => {
    setMemberCount(memberCount + 1);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Use papaparse to parse CSV file
    Papa.parse(file, {
      complete: (result) => {
        // Extract emails and update state
        const emails = result.data
          .map((row) => row[4]) // Assuming the email is in the first column
          .filter((email) => email);
        setEmailList(emails);
        setMemberCount(emails.length); // Set member count based on the number of emails
      },
      header: false, // Assuming there's no header row
    });
  };

  const renderInputBoxes = () => {
    const inputBoxes = [];
    for (let i = 0; i < memberCount; i++) {
      inputBoxes.push(
        <div key={i} className="input-container-div">
          <input type="text" placeholder={`Enter email for Member ${i + 1}`} value={emailList[i] || ''} />
        </div>
      );
    }
    return inputBoxes;
  };

  const upload = () => {
    document.getElementById('fileupld').click();
  };

  
  const addMemberSendInvite = async () => {
    try {
      
      // const data = selectedOption.value === "Admin" ? true : false;
      await axios.post(
        BASE_URL + "/v1/setting//add-invite-link",
        { email:emailList },
        { headers:HEADERS }
      );
      // console.log(response)
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="add-mem-page-div">
      <div className="add-mem-page">
        <div>
          <Logo />
        </div>
        <div className="prev">
          <a href="/setuppassword">
            <img src={`${ASSET_PREFIX_URL}previous-btn.svg`} alt="btn" />
          </a>
          <p>Add team members</p>
        </div>
        <div className="big-team-file">
          <span>Big team?</span>
          <input type="file" id="fileupld" accept=".csv" style={{ display: 'none' }} onChange={handleFileChange} />
          <span onClick={upload} className="bulk-upl">
            Bulk upload
          </span>
        </div>
        <div className="inputs-array">{renderInputBoxes()}</div>
        <div className="add-mem-div" onClick={addMember}>
          <img src={`${ASSET_PREFIX_URL}add_icon_logo.svg`} alt="Add member" />
          <span>Add member</span>
        </div>
        <div className="btns-add-mem">
          <button className="button-1" onClick={addMemberSendInvite}>
            Next
          </button>
          <button className="button-2">Add later</button>
        </div>
      </div>
      <RightContainer />
    </div>
  );
}

export default AddMembers;
