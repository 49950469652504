import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL, LOGIN, PROFILEDETAILS } from "../../constants/config";
import { useNavigate, useLocation } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./SetupPassword.css";
import RightContainer from "../Common/RightContainer";
import Logo from "../Common/Logo";

function SetupPassword({toastStyle}) {
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");


  const nav = useNavigate();
  const location = useLocation();

  async function verifyMember(token) {
    try {
      const response = await axios.post(
        BASE_URL + "/v1/member/verify-member",
        {},
        {
          headers: {
            "x-auth-token": `${token}`,
          },
        }
      );
      setEmail(response.data.data.email)
      // toast.success(JSON.stringify(response.data.meta.message));
      if (response.data.meta.success) {
        localStorage.setItem("token", token);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    // first get the access token from url
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      verifyMember(token);
    } else {
      nav(LOGIN);
    }

    // Cleanup function: This will be called when the component unmounts
    return () => {
      // Clean up any resources or subscriptions here
    };
  }, [location.search, nav]);

  const handlePassword = async (event) => {
    event.preventDefault();

    if (password !== confirm_password) {
      setPasswordError("Passwords do not match");
    } else if (password === "" || confirm_password === "") {
      setPasswordError("Password should not be empty");
    } else {
      setPasswordError("");
      // Perform signup logic or submit the form
     
    }

    try {
      if (password === confirm_password) {
        const token = localStorage.getItem("token");
        if (token) {
          const response = await axios.post(
            BASE_URL + "/v1/member/setup-password",
            { password, confirm_password },
            {
              headers: {
                "x-auth-token": `${token}`,
              },
            }
          );
          console.log(response)
          let { message, success } = response.data.meta;
          if (success) {
            // toast.success(message, toastStyle);
            nav(PROFILEDETAILS);
          } else {
            toast.error(message, toastStyle);
          }
        }
      } else {
        toast.error("The passwords do not match", toastStyle);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="set-up-pass">
      <div className="left-container">
        <div>
          <Logo />
        </div>
        <div
          className="prev"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          {/* <a href="/" style={{ textDecoration: "none" }}>
            <img src={`${ASSET_PREFIX_URL}previous-btn.svg`} alt="btn" />
          </a> */}
          <p>Create your password </p>
        </div>
         <span style={{color:'#610bee'}}  className="prev-text">{email}</span>
        <div className="p1">
          <label>Password</label>
          <div className={`inp1 ${passwordError ? "error" : ""}`}>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
            />
            <button onClick={handleTogglePasswordVisibility}>
              {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
            </button>
          </div>
        </div>
        <div className="p2">
          <label>Confirm Password</label>
          <div className={`inp1 ${passwordError ? "error" : ""}`}>
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Enter Confirm Password"
            />
            <button onClick={handleToggleConfirmPasswordVisibility}>
              {showConfirmPassword ? (
                <VisibilityOffIcon />
              ) : (
                <RemoveRedEyeIcon />
              )}
            </button>
          </div>
        </div>
        {passwordError && (
          <div className="error-message">
            <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
            {passwordError}
          </div>
        )}
        <div>
          <button className="nxt-btn" onClick={handlePassword}>
            Next
          </button>
        </div>
        <div style={{ marginTop: "50px" }}>
          <img src={`${ASSET_PREFIX_URL}tracking.svg`} alt="" />
        </div>
      </div>
      <RightContainer />
    </div>
  );
}

export default SetupPassword;
