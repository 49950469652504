import React, { useState, useEffect } from "react";
import { BASE_URL, DEVIATIONVIEW, ECVIEW, PARTNUMBER, PARTVIEW } from "../../constants/config";
import Cookies from "js-cookie";
import axios from "axios";
import "./PartNumberList.css";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import EditPartNumber from "./EditPartNumber";
import Loading from "../Common/Loading/Loading";
import CreatePartBOM from "./CreatePartBOM";
import NameProfile from "../Common/CommonJsx/NameProfile";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import Select from "react-select";
import { debounce } from "../../constants/helper";
import DateFormateForList from "../Common/CommonJsx/DateFormateForList";
import Pagination from "../Common/CommonJsx/Pagination";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { toast } from "react-toastify";

function PartNumberList() {
 
  const [searchTerm, setSearchTerm] = useState('');
  const [createPartBOM, setCreatePartBOM] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [partLengthOfList, setPartLengthOfList] = useState(0); 
  const itemsPerPage = 10;
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [resetButton, setResetButton] = useState(false);
  const [loading, setLoading] = useState(false)
  const [partNumbers, setPartNumbers] = useState([]);
  const [editPartPopUp, setEditPartPopUp] = useState(false);
  const [rightSectionItems, setRightSectionItems] = useState([]);
  const [uniqueDefaultValue, setUniqueDefaultValue] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [partAction, setPartAction] = useState('')
  const [varientFrom, setVarientFrom] = useState('')
  const [attributeOptions, setAttributeOptions] = useState({});
  const [approvalStatus, setApprovalStatus] = useState({});
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [nomenCondition, setNomenCondition] = useState(false);

  // const [partName, setPartName] = useState("");

  const [id, setId] = useState("");

  const nav = useNavigate();

  const openCreatePartPopUp = () => {
    if (attributeOptions !== null && nomenCondition) {
     
      getActiveFields();
      setCreatePartBOM(!createPartBOM);
    } else {
       if(!nomenCondition){
        toast.info('Please create part nomenclature')
      }else if(!attributeOptions){
        toast.error('Please create attributes')
      }
      // nav(PARTNUMBER)
    }

    // getAttributes()
  };

 
  const closeCreatePartBOM = async () => {
    
    setCreatePartBOM(!createPartBOM);
    // getAttributes()
  };

  const handleFilter = (action) => {
    setPartAction(action)
    setSearchTerm('')
    setCurrentPage(1)
    setResetButton(true)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setResetButton(true)
  };
  const handleStatusChange = (selectedOption) => {

    setSelectedStatus(selectedOption.value);
    // setCurrentPage(1)
    setResetButton(true)
  };
  const handleReset = async () => {

    // setDepartmentId('')

    setSelectedStatus(null)
    setSearchTerm('')
    setPartAction('')
    setResetButton(false)


    // setReset(false)
  };
  const getPartNumberList = async (sortField, sortOrder) => {
    try {
      setLoading(true);
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const listofPartNumber = await axios.get(
        `${BASE_URL}/v1/part-nomen/get-list-part-number`,
        {
          params: {
            page: currentPage,
            limit: itemsPerPage,
            part_action: partAction,
            search: searchTerm,
            selected_status: selectedStatus,
            sort_by: sortField,
            sort_order: sortOrder,
          },
          headers: headers,
        }
      );

      setPartNumbers(listofPartNumber.data.data.part_number_list);
      setApprovalStatus(listofPartNumber.data.data.part_number_list);
      setPartLengthOfList(listofPartNumber.data.data.part_count);
      setTotalPages(listofPartNumber.data.data.total_pages);
      setNomenCondition(listofPartNumber.data.data.part_nomen)
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSort = (field) => {
    const order = (sortField === field && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(order);
    getPartNumberList(field, order);
  };


  useEffect(() => {
    if (searchTerm !== '' || selectedStatus) {
      debounce(getPartNumberList)()
    }
    else {
      getPartNumberList();
    }

  }, [currentPage, itemsPerPage, partAction, searchTerm, selectedStatus]);




  useEffect(() => {
    getAttributes();
  }, []);

  const getAttributes = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const fields = await axios.get(
        `${BASE_URL}/v1/part-nomen/get-attributes`,
        {
          params: {
              type:'ebom'
          },
          headers: headers,
      }
      );
      setAttributeOptions(fields.data.data.attribute_options);
    } catch (error) {
      console.log(error);
    }
  }

  const openeditPartPopUp = (e, id, partnumber, action) => {
    console.log(partnumber, action)
    setVarientFrom(partnumber)
    setId(id)
    e.stopPropagation();
    // getActiveFields();
    setEditPartPopUp(true);
    // getAttributes()
  };
  const closeOpenPopUpEdit = () => {
    setEditPartPopUp(false);
  };
  function incrementValue(item) {

    const newCounterValue = Number(item.default_value) + partLengthOfList + 1;
            const paddedCounterValue = newCounterValue.toString().padStart(item.default_value.length, '0');
            console.log(paddedCounterValue, 'return count');
            return paddedCounterValue;
   
  }
  const getActiveFields = async () => {
    try {
      const headers = {
        "x-auth-token": localStorage.getItem("token"),
      };
      const fields = await axios.get(
        `${BASE_URL}/v1/part-nomen/active-part-nomen`,
        { headers, params: { page: currentPage, limit: itemsPerPage } }
      );

      // setDropElements(!dropElements)
      //  const uniqueValue = fields.data.data.active_part_format.map((item)=>(item.is_unique===true &&item.variable_selected_type==='Counter'))
      //  const selectedType =fields.data.data.map((item)=>(item.variable_selected_type==='Counter')) 

      fields.data.data.active_part_format.forEach(item => {
        if (item.is_unique && item.variable_selected_type === 'Counter') {
          if (item.hasOwnProperty('default_value')) { // Check if ctr property exists
            setUniqueDefaultValue(item.default_value);
            item.default_value = incrementValue(item);
          }
        }
      });
      setRightSectionItems(fields.data.data.active_part_format);
      console.log(fields.data.data.active_part_format)
      // setPartName(fields.data.data.nomenclature.partNameNomenclature);
      //   generateDynamicPartName()
      // console.log(fields.data.data.active_part_format)
    } catch (error) {
      console.log(error);
    }
  };

  const partView = (id) => {

    nav(PARTVIEW(id));
  };

  const formatCreatedAtDate = (createdAt) => {
    const dateObject = new Date(createdAt);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(dateObject);

    return formattedDate;
  };
  // const itemsPerPage = 10;


  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  const handleTitleClick = (event, department) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(department);
  };
  const HandleTitleClose = () => {
    setTicketPopUp(false);
  };
  const statusOptions = ['Draft', 'Pending Approval', 'Released']
  // const uniqueOptions = statusOptions.map((option) => ({
  //   value: option,
  //   label: option,
  // }));

  // setAssignedOptions(statusOptions);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "30px",
      width: "200px",
      border: "1px solid #EDF2F7",
      padding: "0px 15px",
      boxSizing: "border-box",
      height: "40px",
    }),
  };


  const handleNavigate = (e, link,id) => {
    e.stopPropagation();
    if (!link) {
      nav(PARTVIEW(id))
      // If link is empty or undefined, do nothing
      return;
    }
    const url = link.includes('EC')
      ? ECVIEW(link)
      : DEVIATIONVIEW(link);
    
    window.open(url, '_blank');
  };
  return (
    <>

      <div className="part-number-list-container">
        <div className="part-number-list-title">
          <span>Parts</span>
          <div className="part-number-list-btns">
            {/* <button onClick={openCreatePartBOM}>
                <AddOutlinedIcon />
                Create part BOM
              </button> */}
            <button onClick={openCreatePartPopUp}>
              <AddOutlinedIcon />
              Create new part
            </button>
          </div>
        </div>
        <div className="part-number-list-filters">
         
          <SearchFilter searchTerm={searchTerm} handleSearchChange={handleSearchChange} listType='parts'/>
          <div className="part-number-list-spn">
            <span
              className={partAction === '' ? 'active' : ''}
              onClick={() => handleFilter('')}
            >
              All
            </span>
            <span
              className={partAction === 'creation' ? 'active' : ''}
              onClick={() => handleFilter('creation')}
            >
              Root part
            </span>
            <span
              className={partAction === 'variation' ? 'active' : ''}
              onClick={() => handleFilter('variation')}
            >
              Part variations
            </span>
            <span
              className={partAction === 'bom' ? 'active' : ''}
              onClick={() => handleFilter('bom')}
            >
              Part BOM
            </span>
          </div>
          <Select
            styles={customStyles}
            placeholder="Status"
            options={statusOptions.map((option) => ({
              value: option,
              label: option,
            }))}
            value={selectedStatus ? { value: selectedStatus, label: selectedStatus } : null}
            onChange={handleStatusChange} // Set the onChange event handler
          />
          {resetButton && <button
            className="reset-btn"
            onClick={handleReset}
            data-tour="step-11"
          >
            reset <TuneOutlinedIcon />
          </button>}

        </div>
        <div>


          <table className="part-number-list-table-container">
            <thead>
              <tr>
                <th className="part-number-list-th1" onClick={() => handleSort('part_number')} width='175px'>Part number 
               </th>
                <th className="part-number-list-th1" onClick={() => handleSort('part_title')}  width='260px'>Part title</th>
                <th className="part-number-list-th1" >EC </th>
                <th onClick={() => handleSort('created_by.fullName')} width='175px'>Created by</th>
                <th onClick={() => handleSort('createdAt')} width='175px'>Created on</th>
                {/* ec_dev_reference */}
                <th>Actions</th>
                <th >Status</th>
              </tr>
            </thead>
            {loading ? <Loading /> :
              <tbody>
                {partNumbers.map((num, index) => {
                  const matchingApprovalStatus = approvalStatus.find(item => item.part_number === num.part_number);
                  return (
                    <tr onClick={() => partView(num.part_number)} key={index}>
                      <td>{num.part_number}</td>
                      <td>

                        {/* {department.data.defaultFieldsData.ticket_title.value} */}
                        {num.part_title && num.part_title.length > 30 ? (
                          <>
                            {num.part_title.slice(
                              0,
                              24
                            )}
                            ...
                            <button
                              className="view-more-text"
                              onClick={(event) =>
                                handleTitleClick(event, num.part_title)
                              }
                            >
                              view
                            </button>
                          </>
                        ) : (
                          num.part_title
                        )}</td>
                      <td style={{ color: 'blue', textDecoration: 'underline' }} onClick={(e) => handleNavigate(e,num.ec_dev_reference,num.part_number)}>
                       
                        {num.ec_dev_reference}
                      </td>
                      <td className="part-number-photo">
                        <NameProfile userName={num.created_by.fullName} width='26px' memberPhoto={num.created_by.photo} fontweight='500' />
                        <span>{num.created_by.fullName}</span>
                      </td>
                      <td>
                        <DateFormateForList formateTime={num.createdAt}/>
                        </td>
                      <td>
                        {!num.is_variation ? '' : (
                          <button
                            className="part-number-list-table-container-btn"
                            onClick={(e) => openeditPartPopUp(e, num.part_number, num.part_number, num.part_action)}
                          >
                            Create part variation
                          </button>
                        )}
                      </td>
                      {matchingApprovalStatus ? (
                        <td>{matchingApprovalStatus.ec_approval_status}</td>
                      ) : (
                        <td></td>
                      )}
                    </tr>
                  );
                })}


              </tbody>
            }
          </table>

        </div>
        {totalPages > 1 && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}/>
        )}
      </div>


      {editPartPopUp && (
        <EditPartNumber
          handleClose={closeOpenPopUpEdit}
          handleopen={openeditPartPopUp}
          setRightSectionItems={setRightSectionItems}
          setAttributeOptions={setAttributeOptions}
          rightSectionItems={rightSectionItems}
          editPartPopUp={editPartPopUp}
          varientFrom={varientFrom}
          getPartNumberList={getPartNumberList}
          id={id}
          attributeOptions={attributeOptions}
          uniqueDefaultValue={uniqueDefaultValue}
          isVariation={true}
        />
      )}
      
      {createPartBOM && (
        <CreatePartBOM
          handleClose={closeCreatePartBOM}
          setRightSectionItems={setRightSectionItems}
          rightSectionItems={rightSectionItems}
          editPartPopUp={editPartPopUp}
          getPartNumberList={getPartNumberList}
          attributeOptions={attributeOptions}
          partLengthOfList={partLengthOfList}
          setAttributeOptions={setAttributeOptions}
          uniqueDefaultValue={uniqueDefaultValue}
          isVariation={false}
        // partNumberOptions={partNumberOptions}
        />
      )}
      {(ticketPopUp) && (
        <TicketTitlePopUp
        header='Part title'
          onClose={HandleTitleClose}
          ticketTitle={ticketTitle}
        />
      )}
    </>
  );
}

export default PartNumberList;
