import React, { useState, useEffect, useRef } from "react";
import { BASE_URL, HEADERS, LOCATIONVIEW, PHOTO_LINK, WAREHOUSEVIEW } from "../../constants/config";
import Loading from "../Common/Loading/Loading";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Pagination from "../Common/CommonJsx/Pagination";
import NameProfile from "../Common/CommonJsx/NameProfile";
import SearchFilter from "../Common/CommonJsx/SearchFilter";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./TicketList.css";
import WareHouse from './../Warehouse/WareHouse';
import CreateLocation from "../LocationPages/CreateLocation";
import TicketTitlePopUp from "../Common/CommonJsx/TitlePopUp";
import { toast } from "react-toastify";

function LocationList() {
  const [locations, setLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [resetButton, setResetButton] = useState(false);
  const nav = useNavigate();
  const [createWarehouse, setCreateWarehouse] = useState(false);
  const [ticketPopUp, setTicketPopUp] = useState(false);
  const [ticketTitle, setTicketTitle] = useState("");
  const [locationTemplate, setLocationTemplate] = useState(false);
  const fetchLocations = async () => {
    try {
      setLoading(true);


      const response = await axios.get(
        `${BASE_URL}/v1/location/get-location-list`,
        {
          params: {
            page: currentPage,
            limit: 10,
            search: searchTerm,
          },
          headers: HEADERS,
        }
      );
      setLocationTemplate(response.data.data.location_temp)
      const details = response.data.data.location_list;
      const paginationDetails = response.data.data.pagination;
      setLocations(details);
      setTotalPages(paginationDetails.total_pages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };



  useEffect(() => {
    fetchLocations();
  }, [currentPage, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setResetButton(true);
  };

  const handleReset = () => {
    setSearchTerm("");
    setResetButton(false);
    setCurrentPage(1);
  };

  const openCreatePartPopUp = () => {
    if(!locationTemplate){
      toast.error('Create location template before creating locations')
    }else{
      setCreateWarehouse(!createWarehouse);
    }
   
  };
  const handleTitleClick = (event, warehouse) => {
    event.stopPropagation();
    setTicketPopUp(true);
    setTicketTitle(warehouse.location_default_data.location_name.value);
  };
  return (
    <>

      <div className="depart-main" style={{ marginTop: "80px" }}>
        <div className="part-number-list-title">
          <span>Locations</span>
          <div className="part-number-list-btns">
            <button onClick={openCreatePartPopUp}>
              <AddOutlinedIcon />
              Create new Location
            </button>
          </div>
        </div>

        <div className="tck-fltr">
          <SearchFilter
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            listType="location..."
          />
          {resetButton && (
            <button className="reset-btn" onClick={handleReset} data-tour="step-11">
              reset <TuneOutlinedIcon />
            </button>
          )}
        </div>

        
              <div className="department-table">
                <table className="department-table-data">
                  <thead>
                    <tr>
                      <th className="depart-header">ID</th>
                      <th className="depart-header">Location name</th>
                     
                    </tr>
                  </thead>
                  {loading ? <Loading/> :  <tbody>
                    {locations.map((warehouse) => {
                      return (
                        <tr key={warehouse._id} onClick={() => nav(LOCATIONVIEW(warehouse.location_sequence_id))}>
                          <td>{warehouse.location_sequence_id}</td>
                         
                          <td title={warehouse.location_default_data.location_name.value}>
                            {warehouse.location_default_data.location_name.value.length > 30 ? (
                              <>
                                {warehouse.location_default_data.location_name.value.slice(0, 30)}
                                ...
                                <button className="view-more-text" onClick={(event) => handleTitleClick(event, warehouse)}>
                                  view
                                </button>
                              </>
                            ) : (
                              warehouse.location_default_data.location_name.value
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>}
                 
                </table>
              </div>
            

        {(totalPages > 1 && !loading)  && (
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
        )}
      </div>
      {ticketPopUp && (
        <TicketTitlePopUp
          header='location'
          onClose={()=>setTicketPopUp(!ticketPopUp)}
          ticketTitle={ticketTitle}
        />
      )}
      {createWarehouse && <CreateLocation handleClose={() => setCreateWarehouse(!createWarehouse)} />}
    </>
  );
}

export default LocationList;
