import React, { useState, useEffect } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import {
    ASSET_PREFIX_URL,
    BASE_URL,
    BUCKET,
    CATEGORY_UPLOAD_DESIGNS, HEADERS,GOOGLE_API_KEY
} from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper"
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AttachmentsFunction from "../Common/CommonJsx/AttachmentsFunction";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileUplaoding from "../Common/Loading/FileUplaoding";



function CreateDesignPopUp({ onClose, workspace_sequence_id, selectedFile }) {
   
    const [uploadedFiles, setUploadedFiles] = useState(
        selectedFile ? [
            {
                value: selectedFile.drive_file_id,
                label: selectedFile.name.length > 30 ? selectedFile.name.substring(0, 30) + '...' : selectedFile.name,
                ...selectedFile
            }
        ] : []
    );

    const [isLoading, setIsLoading] = useState(false);
    const [addUploadSelect, setAddUplaodSelect] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [isPickerReady, setIsPickerReady] = useState(false);
    const [name, setName] = useState('');
    const [fileUrl, setFileUrl] = useState(null);
    const [description, setDescription] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const [isFileUploading, setIsFileLoading] = useState(false);
   
    

    const handleFileDrop = async (event) => {
        event.preventDefault();
        setFileUrl('uploading')
        // setDragging(false);
        console.log(event)
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const selectedFiles = Array.from(files);
            const updatedFiles = selectedFiles.map((file) => ({
                name: file.name,
                file: file,
                key: URL.createObjectURL(file), // Create blob URL for the file
            }));

            setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
            // handleFile(file);
        }
    };


    

   
    const handleDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
    };
    
    const handleFileUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const updatedFiles = selectedFiles.map((file) => ({
            name: file.name,
            file: file,
            key: URL.createObjectURL(file), // Create blob URL for the file
        }));

        setUploadedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    };

    const upload = () => {
        document.getElementById("fileupld_stp").click();
    };
    


    const validateForm = () => {
        const errors = {};

        // 1. Validate Workspace Name (No special characters)

        if (!name) {
            errors.name = "Name is required.";
        }
        console.log(uploadedFiles, 'uploadedFiles')
        if (uploadedFiles.length === 0) {
            setUploadError("At least one file should be uploaded.");
        }

        setFieldErrors(errors);
        return Object.keys(errors).length === 0 && uploadError.length == 0;
    };

    const submitForm = async (e) => {
        e.preventDefault();
        setFieldErrors({});
        setUploadError('');
        if (!validateForm()) return;

        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("workspace_sequence_id", workspace_sequence_id);
        formData.append("headers", JSON.stringify(HEADERS));
        const selectedFilesFormatted = uploadedFiles
            .filter((file) => file.drive_file_id) // Filter files with a valid drive_file_id
            .map((file) => ({
                drive_file_id: file.drive_file_id,
                name: file.name,
                drive_version: file.version,
            }));

        formData.append("selected_files", JSON.stringify(selectedFilesFormatted));
        // Append each file
        const filesWithoutFileId = uploadedFiles.filter((fileObj) => !fileObj.fileId);

        // Example: Append only files without fileId to formData
        filesWithoutFileId.forEach((fileObj) => {
            formData.append("files", fileObj.file);
        });
        console.log(selectedFilesFormatted)
        try {
            setIsFileLoading(true)
            const response = await axios.post(`${BASE_URL}/v1/pdm/add-design`, formData, {
                headers: {
                    ...HEADERS,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Form submitted successfully:", response.data);
            if (!response.data?.meta?.success) {
                toast(response.data?.meta?.message);
            } else {

                onClose();
            }
            setIsFileLoading(false)
        } catch (error) {
            console.error("Error submitting form:", error);
        }

    };

    
   


    useEffect(() => {
        // Load Google Picker API
        const loadPickerApi = () => {
          const script = document.createElement('script');
          script.src = 'https://apis.google.com/js/api.js';
          script.onload = () => {
            window.gapi.load('picker', () => {
              setIsPickerReady(true); // Picker is ready to use
            });
          };
          document.body.appendChild(script);
        };
    
        if (!window.gapi) {
          loadPickerApi();
        } else if (!window.gapi.picker) {
          window.gapi.load('picker', () => setIsPickerReady(true));
        } else {
          setIsPickerReady(true);
        }
      }, []);
    
      const openPicker = (driveFolerId,accessToken) => {
        console.log(accessToken,driveFolerId)
        if (!isPickerReady) {
          console.error('Google Picker API is not ready.');
          return;
        }
    
        const picker = new window.google.picker.PickerBuilder()
        
       
        .setOAuthToken(accessToken)  
        .setDeveloperKey(GOOGLE_API_KEY)
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED) // Enable multi-select
          .addView(
            new window.google.picker.DocsView()
              .setIncludeFolders(true)
              .setParent(driveFolerId)
           
          )
          .setCallback((data) => {
            if (data.action === window.google.picker.Action.PICKED) {
              const files = data.docs.map(doc => ({
                drive_file_id: doc.id, // Use `drive_file_id` as the ID
                name: doc.name || doc.title, // Use the name of the file (or title if name is undefined)
              }));
              console.log('Selected files:', files);
              setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
          
            } else if (data.action === window.google.picker.Action.CANCEL) {
              console.log('Picker closed without selection.');
            }
          })
          .build();
          console.log('Access Token:', accessToken);
        picker.setVisible(true);
      };
    

    const uploadFromDrive =async()=>{
        try {
            const response = await axios.get(BASE_URL + "/v1/pdm/get-access-token", {
                params: { workspace_seq_id:workspace_sequence_id },
                headers: HEADERS,
            });
            if (!response.data?.meta?.success) {
                toast.error(response.data?.meta.message);
            } else {
                // setAccessToken(response.data.data.access_token)
                // setDriveFolderId(response.data.data.folder_id)
                openPicker(response.data.data.folder_id,response.data.data.access_token)
                setAddUplaodSelect(false)
            }
        } catch (error) {
            console.error("Error fetching files:", error);
        }
        
    }

    const openUpload=(e)=>{
        e.stopPropagation()
        setAddUplaodSelect(!addUploadSelect)
    }
    return (
        <>

            <div className='create-part-number-popup-page' onClick={()=>setAddUplaodSelect(false)}>
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Add design</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    <div className='create-part-num-div-form' style={{ overflowY: 'scroll' }}>
                        {isFileUploading ? <FileUplaoding /> :
                            <>
                                <div className="create-part-num-div-form-dynamic">
                                    <div className="create-part-num-div-form-label-input">
                                        <span>Name*</span>
                                        <div className="create-part-num-div-form-input">
                                            <input type="text"
                                                placeholder="Name"
                                                value={name}  // Bind input value to state
                                                onChange={(e) => setName(e.target.value)}  // Update state on input change
                                            />
                                        </div>
                                        {fieldErrors.name && (
                                            <span className="error-message">
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors.name}
                                            </span>
                                        )}
                                    </div>
                                    <div className="create-part-num-div-form-label-input">
                                        <span>Description</span>
                                        <div className="create-part-num-div-form-input">
                                            <textarea
                                                type="text"
                                                placeholder="Describe this Design (optional)"
                                                value={description}  // Bind textarea value to state
                                                onChange={(e) => setDescription(e.target.value)}  // Update state on input change
                                            />
                                        </div>
                                        {fieldErrors.description && (
                                            <span className="error-message">
                                                <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />{" "}
                                                {fieldErrors.description}
                                            </span>
                                        )}
                                    </div>
                                    
                                </div>

                                <div>
                                    <div className="create-part-num-div-form-label-attahment">
                                        <span>ADD files*</span>
                                        <div
                                            onDrop={handleFileDrop}
                                            onDragOver={handleDragOver}
                                            className="filedropzone"
                                            style={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%'}}
                                        >
                                            <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                            {isLoading ? <p>Uploading ....</p> :
                                                <div style={{display:'flex',alignItems:'center',gap:'10px',position:'relative'}}>Drop files to attach or<button style={{display:'flex',alignItems:'center',gap:'10px'}} className="add-upload-btn"
                                                onClick={(e)=>openUpload(e)}
                                                > Add <img src={`${ASSET_PREFIX_URL}upload-add-icon.svg`} alt="" style={{ width: '24px', height: '24px' }}/></button>
                                                {addUploadSelect &&  <div className="upload-type" >
                                                <div className="upload-type-btn" onClick={upload}>
                                                    <img src={`${ASSET_PREFIX_URL}computer-logo.png`} alt="" style={{ width: '24px', height: '24px' }}/>
                                                    <span style={{ color: 'black' }}>Upload from computer</span>
                                                </div>

                                                <div className="upload-type-btn" onClick={uploadFromDrive}>
                                                    <img src={`${ASSET_PREFIX_URL}google-drive-logo.png`} alt="" style={{ width: '24px', height: '24px' }}/>
                                                    <span style={{ color: 'black' }}>Add from Google drive</span>
                                                </div>
                                            </div>}
                                                
                                                
                                                </div>} 
                                                
                                           
                                        </div>
                                        <input
                                            type="file"
                                            id="fileupld_stp"
                                            style={{ display: "none" }}
                                            onChange={handleFileUpload}
                                            className="btn-upload"
                                            // accept=".stp, .STEP, .step, .docx, .pdf, .jpg, .png, .xlsx, .xls, .pptx, .ppt, .svg, .txt"
                                            multiple
                                        />
                                        {uploadError && <div className="error"><span className="error-message"
                                            style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {uploadError}</span></div>}
                                    </div>
                                    <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' uploadType='designs' />

                                </div>
                            </>
                        }


                        {/* {!uploadError && <div className="error">{uploadError}</div>} */}

                    </div>
                    <div className='create-part-num-div-btns'>
                        {!isFileUploading && <button onClick={submitForm} disabled={isFileUploading}>Add</button>}

                    </div>
                </div>

            </div>


        </>

    );

}
export default CreateDesignPopUp;
