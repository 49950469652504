import React, { useState } from "react";
import '../DepartmentPages/DepartmentForm.css'
import axios from "axios";
import {

    BASE_URL,

    HEADERS
} from "../../constants/config";
import PopupCloseButton from "../Common/PopupCloseButton/PopupCloseButton";
import { toast } from "react-toastify";




function DesignCheckoutPopUp({ row, onClose, onClosePopUp }) {
    const [minorVersion, setMinorVersion] = useState(row.latest_version.version.minor);
    const [majorVersion, setMajorVersion] = useState(row.latest_version.version.major);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState('');
    const validateForm = () => {
        if (row.latest_version.version.major == majorVersion && row.latest_version.version.minor == minorVersion) {
            setErrors("Increment either major or minor version");
        }
        return errors == '';
    };

    const submitForm = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        setErrors('')
        // if (!validateForm()) return; // Stop if validation fails
        // setIsSubmitting(true); // Disable submit button

        const formData = {
            design_id: row._id,
            comment,
            // minor_version: minorVersion,
            // major_version: majorVersion,
        };

        try {
            setLoading(true)
            const response = await axios.post(`${BASE_URL}/v1/pdm/checkout-design`, formData, {
                headers: HEADERS,
            });
            console.log("Form submitted successfully:", response.data);
            if (!response.data?.meta?.success) {
                toast(response.data?.meta.message);
            } else {
                onClosePopUp('download', 'Checkout was successful!');
            }
            setLoading(false)
            // navigate to details page
            // nav(WORKSPACE(response.data?.data.sequence_id))
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            // setIsSubmitting(false); // Re-enable submit button
        }
    };

    return (
        <>
            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        <span>Checkout details for {row.name}</span>
                        <PopupCloseButton handleClose={onClose} />
                    </div>
                    <div className='create-part-num-div-form' style={{ overflowY: 'scroll', height: 'auto' }}>
                        <div className="create-part-num-div-form-label-input" style={{ marginTop: '1%', flexDirection: 'column' }}>
                            <span>Add comment: </span>
                            <div className="create-part-num-div-form-input" >
                                <textarea
                                    style={{ width: '100%', height: '50px' }}
                                    type="text"
                                    placeholder="Add comment (optional)"
                                    value={comment} onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='create-part-num-div-btns'>
                        <button
                            onClick={submitForm}
                            disabled={loading} // Disable the button when loading is true
                            style={{
                                opacity: loading ? 0.5 : 1, // Apply opacity 0.5 when loading
                            }}
                        >
                            Checkout
                        </button>
                    </div>
                </div>

            </div>
        </>
    )
}
export default DesignCheckoutPopUp;
