import React from "react";
import "./Security.css";
import { ASSET_PREFIX_URL } from "../../../constants/config";

function Security() {
  return (
    <div className="security-page" id='security'>
      <div className="security-container">
        <div className="security-content">
          <span className="security-head">Designed for Security</span>
          <span className="security-desc">
            Capturing everything you see, say, and hear means trust and privacy
            is more important than anything else.
          </span>
          {/* <div className="security-btn">
            <button>Join the Waitlist</button>
          </div> */}
        </div>
        <div className="security-points">
          <div className="security-points-1">
            <div className="security-internal-points">
              <img src={`${ASSET_PREFIX_URL}points.webp`} alt="" />
              <span>Encryption at rest</span>
            </div>
            <div className="security-internal-points">
              <img src={`${ASSET_PREFIX_URL}points.webp`} alt="" />
              <span>Encryption in transit</span>
            </div>
            <div className="security-internal-points">
              <img src={`${ASSET_PREFIX_URL}points.webp`} alt="" />
              <span>Single-sign on</span>
            </div>
            <div className="security-internal-points">
              <img src={`${ASSET_PREFIX_URL}points.webp`} alt="" />
              <span>Multi-factor Authentication</span>
            </div>
          </div>
          <div className="security-points-1">
            <div className="security-internal-points">
              <img src={`${ASSET_PREFIX_URL}points.webp`} alt="" />
              <span>Role-based access controls</span>
            </div>
            <div className="security-internal-points">
              <img src={`${ASSET_PREFIX_URL}points.webp`} alt="" />
              <span>Logging, auditing and monitoring features</span>
            </div>
            <div className="security-internal-points">
              <img src={`${ASSET_PREFIX_URL}points.webp`} alt="" />
              <span>Features to enhance privacy of personal data</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;
