import React, { useState } from 'react'
import '../PopUpsFolder/Notification.css'
import PopupCloseButton from '../Common/PopupCloseButton/PopupCloseButton'
import DesignDropdown from '../Common/DesignDropdown'
import Select from "react-select";
import Cookies from 'js-cookie';
import axios from 'axios';
import { ASSET_PREFIX_URL, BASE_URL, HEADERS, PARTVIEW, TICKET_ATTACHMENT_BUCKET, CATEGORY_UPLOAD_PARTS, BUCKET } from "../../constants/config";
import { simpleUpload, multiUpload } from "../../constants/helper"
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import AttachmentsFunction from '../Common/CommonJsx/AttachmentsFunction';
import { useNavigate } from 'react-router-dom';
import EcPartsForm from '../EcPages/EcPartsForm';
import { makeByCodeOptions } from '../../constants/helper';



function CreatePartBOM({ handleClose,
    rightSectionItems,
    attributeOptions,
    setRightSectionItems,
    getPartNumberList, partLengthOfList, uniqueDefaultValue, isVariation }) {

    const nav = useNavigate()

    const [counterValues, setCounterValues] = useState([]);
    const [addButton, setAddButton] = useState(Array(rightSectionItems.length).fill(true));
    const [minusButton, setMinusButton] = useState(Array(rightSectionItems.length).fill(false));
    const [partTitle, setpartTitle] = useState('');
    const [partDesc, setpartDesc] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedCode, setSelectedCode] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [fileSizeError, setFileSizeError] = useState('');
    const [errors, setErrors] = useState('')
    const [openSecondStep, setOpenSecondStep] = useState(false);
    const [design, setDesign] = useState(null);
    const [selectedParts, setSelectedParts] = useState([]);

    const handleDesignSelectChange = (selectedDesign) => {
        // Extract all files from the selected design
        if (design) {
            // const allPreviouseFiles = [
            //     ...(design.latest_version?.files || []),
            //     ...(design.latest_version?.supporting_files || []),
            // ];

            // Remove all files from the previous design
            setUploadedFiles((prevUploadedFiles) =>
                prevUploadedFiles.filter(
                    (file) => !design.latest_version?.files.some((newFile) => newFile._id === file._id)
                )
            );
        }
        setDesign(selectedDesign);
      
        // Update state with all files
        setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            ...selectedDesign.latest_version?.files.map((file) => ({
                name: file.name,
                key: file.key,
                _id: file._id,  // Include _id if needed
            })),
        ]);
    }




    const handleCounterIncrease = (value, meaning, position, action, is_unique, item) => {

        const lastCharacter = value.slice(-1);
        const isAlphabetic = isNaN(parseInt(lastCharacter));

        const newValue = isAlphabetic
            ? value.slice(0, -1) + String.fromCharCode(lastCharacter.charCodeAt(0) + 1)
            : value.slice(0, -1) + (parseInt(lastCharacter, 10) + 1);

        setCounterValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[position] = newValue;
            return updatedValues;
        });

        const updatedRightSectionItems = [...rightSectionItems];
        updatedRightSectionItems[position].default_value = is_unique ? incrementValue(item) : newValue;

        setRightSectionItems(updatedRightSectionItems);

        // Update the specific button state
        setAddButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = false;
            return updatedButtons;
        });
        setMinusButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = true;
            return updatedButtons;
        });
    };

    const handleDecrement = (value, meaning, position, action) => {
        const lastCharacter = value.slice(-1);
        const isAlphabetic = isNaN(parseInt(lastCharacter));

        let newValue;
        if (isAlphabetic) {
            // Decrement the alphabetic character
            newValue = value.slice(0, -1) + String.fromCharCode(lastCharacter.charCodeAt(0) - 1);
        } else {
            const numericValue = parseInt(lastCharacter, 10);
            // Ensure the value is greater than 0 before decrementing
            if (numericValue > 0) {
                newValue = value.slice(0, -1) + (numericValue - 1);
            } else {
                // Optionally handle if the value is already at 0
                newValue = value;
            }
        }

        setCounterValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[position] = newValue;
            return updatedValues;
        });

        const updatedRightSectionItems = [...rightSectionItems];
        updatedRightSectionItems[position].default_value = newValue;
        setRightSectionItems(updatedRightSectionItems);

        // Update the specific button state
        setAddButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = true;
            return updatedButtons;
        });
        setMinusButton((prevButtons) => {
            const updatedButtons = [...prevButtons];
            updatedButtons[position] = false;
            return updatedButtons;
        });
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: "340px",
            height: '40px',
            backgroundColor: "transparent",
            border: "1px solid #EDF2F7",
            boxShadow: "none",
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),

        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? "transparent" : "white",
            color: state.isSelected ? "black" : "inherit",
            cursor: "pointer",
            ":hover": {
                background: "lightgray",
            },
        }),


    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        handleFile(file);
    };
    const upload = () => {
        document.getElementById("fileupld").click();
    };
    const handleFileDrop = async (event) => {
        event.preventDefault();
        // setDragging(false);

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];

            handleFile(file);
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        // setDragging(true);
    };

    const handleFile = async (file) => {
        setFileSizeError('');
        setUploadError('');
        const fileSizeMB = file.size / (1024 * 1024);  // size in MB
        if (fileSizeMB > 2) {
            setFileSizeError('File size cannot be more than 2 MB');  // Notify the user
            return;  // Exit the function
        }
        //fetch pre signed URL
        setIsLoading(true);
        try {

            const preSignedURL = await axios.post(
                `${BASE_URL}/v1/member/get-presigned-url`,
                { bucket_name: BUCKET, file: file.name, category: CATEGORY_UPLOAD_PARTS, filesize: fileSizeMB },
                { headers: HEADERS }
            );
            if (preSignedURL.data.meta.code === 200 && preSignedURL.data.meta.message === 'SUCCESS' && preSignedURL.data.data.url) {
                //upload to s3
                // await axios.put(preSignedURL.data.data.url, file, {
                //     headers: {
                //         'Content-Type': file.type
                //     }
                // });
                if (preSignedURL.data.data.is_mutipart) {
                    await multiUpload(preSignedURL.data.data, file, HEADERS);
                } else {
                    await simpleUpload(preSignedURL.data.data, file);
                }

                setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, { name: file.name, key: preSignedURL.data.data.key }]);
            } else {
                alert('Error generating signed URL')
            }
        } catch (e) {

            setUploadError('Error in uploading file');
        } finally {
            // Set isLoading back to false after upload (whether success or failure)
            setIsLoading(false);
        }
    }



    const handleSelectChange = (selected, meaning, position, default_value) => {
        const updatedRightSectionItems = [...rightSectionItems];

        updatedRightSectionItems[position].default_value = selected.value ? selected.value : default_value;



        setRightSectionItems(updatedRightSectionItems);


    };
    const handleCodeChange = (selected) => {
        setSelectedCode(selected.value)
    }


    const handlemeaningChange = (index, value, meaning, default_value) => {
        const updatedRightSectionItems = [...rightSectionItems];
        // Assuming each item in rightSectionItems has a 'partValue' property

        // If the input value is empty, set default_value to an empty string
        if (value === "") {
            updatedRightSectionItems[index].default_value = "";
        } else {
            // Otherwise, update default_value based on the input value
            updatedRightSectionItems[index].default_value = value;
        }

        setRightSectionItems(updatedRightSectionItems);
    };





    const generateDynamicPartName = () => {
        return rightSectionItems.map((item, index) => {
            let partValue = ""; // Initialize partValue variable

            // Determine the part value based on the conditions
            if (item.variable_selected_type === "Fixed") {
                partValue = item.default_value;
            } else if (item.character_type === 'special_character') {
                partValue = item.character_length;
            } else if (item.variable_selected_type === "Counter" && item.is_unique === false) {
                partValue = item.default_value;
            } else if (item.variable_selected_type === "Counter" && item.is_unique === true) {

                partValue = item.default_value
            } else if (item.character_type === "input_variable" || item.variable_selected_type === "Static") {
                partValue = item.default_value
            }

            // Render the part value with a conditional class or style if it's unique
            return (
                <span
                    key={index}
                    style={{ color: item.is_unique ? 'red' : 'black' }} // Apply red text color if is_unique is true
                >
                    {partValue}
                </span>
            );
        });
    };


    const dynamicPartNumber = generateDynamicPartName();

    const handlePartTitle = (e) => {
        setpartTitle(e.target.value)
    };




    const handlePartDesc = (e) => {
        setpartDesc(e.target.value)
    };

    const [selectedValues, setSelectedValues] = useState({});

    const handlemeasureChange = (selected, title) => {
        setSelectedValues(prevState => ({
            ...prevState,
            [title]: selected.value // Set the selected value for the attribute title
        }));
        console.log(selected.value)
    };


    const handleOpenSecondStep = () => {

        if (!partTitle) {
            // If partTitle is not filled, set an error message
            setErrors('Please fill all details');
        } else if (Object.keys(selectedValues).length !== attributeOptions.data.length) {
            setErrors('Please fill all attributes');
        } else {
            // If no errors, proceed to open the second step
            setErrors(''); // Clear any previous error messages
            setOpenSecondStep(!openSecondStep);
        }
    }


    const handlePrevStepToParts = () => {
        setOpenSecondStep(false)
    }

    const handleCreate = async () => {
      
        const headers = HEADERS

        const saveData = JSON.stringify(rightSectionItems);

        const part_name_nomen_id = rightSectionItems.map((item) => item.part_name_nomen_id
        )
        const is_variation = rightSectionItems.map((item) => item.is_variation
        )
        const attributes = JSON.stringify(attributeOptions.data.map(item => ({
            title: item.title,
            options: item.options.map(option => option),
            value: selectedValues[item.title]
        })))


        // else {
        try {
            const requestData = {
                data: saveData,
                part_title: partTitle,
                make_buy_code: selectedCode,
                description: partDesc,
                attachments: uploadedFiles,
                child_parts: selectedParts,
                part_action: selectedParts.length > 0 ? 'bom' : 'creation',
                part_name_nomen_id: part_name_nomen_id[0],
                is_variation: isVariation,
                attribute_data: attributes,
                unique_default_value: uniqueDefaultValue
            };
            // Conditionally add `design_id` if it exists
            if (design && design._id) {
                requestData.design_id = design._id;
                requestData.design_version_id = design.latest_version._id;
            }
            const response = await axios.post(
                `${BASE_URL}/v1/part-nomen/create-new-part`,
                requestData,
                { headers }
            );

            if (response.data.meta.success === false) {
                console.error(response.data.meta.message);
                setErrors(response.data.meta.message);
            } else {
                setErrors('');
                // console.log(response)
                nav(PARTVIEW(response.data.data.new_part))
                getPartNumberList();
                handleClose();

            }
        } catch (error) {
            console.error("An error occurred during the request:", error);
            setErrors("An error occurred during the request. Please try again.");
        }
        // }
    };



    function incrementValue(item) {
        const lastChar = item.default_value.slice(-1);
        let result;

        if (!isNaN(parseInt(lastChar, 10))) {
            result = parseInt(item.default_value, 10) + (partLengthOfList + 1);
        } else {
            const nextLetter = String.fromCharCode(lastChar.charCodeAt(0) + (partLengthOfList + 1));
            result = item.default_value.slice(0, -1) + nextLetter;
        }

        return result;
    }


    return (
        <>

            <div className='create-part-number-popup-page' >
                <div className='create-part-num-div' >
                    <div className='create-part-num-div-top'>
                        {!openSecondStep && <span>Create Part</span>}
                        {openSecondStep && <span>Add Child Part(s)</span>}
                        <PopupCloseButton handleClose={handleClose} />
                    </div>
                    <div className="dept-ticket-steps" style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                        {!openSecondStep && <span>step 1 of 2</span>}
                        {openSecondStep && <span>step 2 of 2</span>}
                    </div>
                    {!openSecondStep && <img
                        src={`${ASSET_PREFIX_URL}ticket-step1.svg`}
                        alt=""
                        width="95%" style={{ paddingLeft: '24px', paddingRight: '24px' }}
                    />}
                    {openSecondStep && <img
                        src={`${ASSET_PREFIX_URL}ticket-step2.svg`}
                        alt=""
                        width="95%" style={{ paddingLeft: '24px', paddingRight: '24px' }}
                    />}

                    {!openSecondStep && <div className="create-part-num-errors">
                        <div className="create-part-num-div-part-number">
                            <span>Part Number Preview</span>
                            <span className="create-part-title">
                                {generateDynamicPartName()}
                            </span>

                        </div>
                        {errors !== '' && <div className="errors-msg-div">
                            <img src={`${ASSET_PREFIX_URL}warning.svg`} alt="" />
                            <span className="error-message">{errors}</span>
                        </div>}

                    </div>}
                    {!openSecondStep && <div className='create-part-num-div-form' style={{ overflowY: 'scroll' }}>


                        <div className='create-part-num-div-form-dynamic'>
                            {rightSectionItems.map((item, index) => (
                                <div key={index} >

                                    {item.variable_selected_type === 'Counter' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            <div className='create-part-num-div-form-input'>
                                                <input
                                                    type='text'
                                                    // value={
                                                    //     item.is_unique
                                                    //         ? counterValues[item.position] ||
                                                    //         item.default_value.slice(0, -1) +
                                                    //         (parseInt(item.default_value.slice(-1), 10) + 1)
                                                    //         : counterValues[item.position] !== undefined
                                                    //             ? counterValues[item.position]
                                                    //             :  item.default_value
                                                    // }
                                                    value={
                                                        item.default_value
                                                    }
                                                    className='create-part-num-div-counter-input'
                                                    disabled
                                                    onChange={(e) =>
                                                        handlemeaningChange(
                                                            index,
                                                            e.target.value,
                                                            item.meaning,
                                                            item.default_value
                                                        )
                                                    }
                                                />

                                                {/* <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Plus Icon'  /> */}
                                                {item.is_unique === false ? <>
                                                    {(minusButton[index] === true && counterValues[index]) && <img src={`${ASSET_PREFIX_URL}create-count-minus.png`} alt='Plus Icon'
                                                        onClick={() => handleDecrement(item.default_value, item.meaning, index, 'minus')} />}
                                                    {addButton[index] === false && counterValues[index] ? (
                                                        <img src={`${ASSET_PREFIX_URL}create-count-plus-disable.png`} alt='Plus Icon' />
                                                    ) : (
                                                        <img
                                                            src={`${ASSET_PREFIX_URL}create-count-plus.png`}
                                                            alt='Plus Icon'
                                                            onClick={() => handleCounterIncrease(item.default_value, item.meaning, index, 'add', item.is_unique, item)}
                                                        />
                                                    )}
                                                </> : ''}

                                            </div>
                                        </div>
                                    )}
                                    {item.variable_selected_type === 'Fixed' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            {/* <div className='create-part-num-div-form-input'> */}
                                            <Select
                                                styles={customStyles}
                                                // value={{ value: item.partValue, label: item.partValue }}
                                                options={item.variable_meaning.map((item) => ({
                                                    value: item.variable,
                                                    label: item.variable,
                                                }))}
                                                value={{
                                                    value: item.default_value,
                                                    label: item.default_value
                                                }}
                                                onChange={(selected) =>
                                                    handleSelectChange(
                                                        selected,
                                                        item.meaning,
                                                        item.position,
                                                        item.default_value
                                                    )
                                                }
                                            />
                                            {/* </div> */}
                                        </div>
                                    )}
                                    {item.character_type === 'input_variable' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            <div className='create-part-num-div-form-input'>
                                                <input type='text'
                                                    value={item.default_value}
                                                    // value={item.default_value}
                                                    onChange={(e) =>
                                                        handlemeaningChange(
                                                            index,
                                                            e.target.value,
                                                            item.meaning,
                                                            item.default_value
                                                        )
                                                    } />

                                            </div>
                                        </div>
                                    )}
                                    {item.variable_selected_type === 'Static' && (
                                        <div className='create-part-num-div-form-label-input'>
                                            <span>{item.meaning}*</span>
                                            <div className='create-part-num-div-form-input'>
                                                <input type='text'
                                                    value={item.default_value}
                                                    disabled
                                                    // value={item.default_value}
                                                   />

                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="create-part-num-div-form-dynamic">
                            <div className="create-part-num-div-form-label-input">
                                <span>Part title*</span>
                                <div className="create-part-num-div-form-input">
                                    <input type="text" placeholder="Part Title" value={partTitle} onChange={(e) => handlePartTitle(e)} />
                                </div>
                            </div>
                            <div className="create-part-num-div-form-label-input">
                                <span>Description</span>
                                <div className="create-part-num-div-form-input">
                                    <textarea
                                        type="text"
                                        placeholder="Describe this Part (optional)"
                                        value={partDesc} onChange={(e) => handlePartDesc(e)}
                                    />
                                </div>
                            </div>
                            <div className="create-part-num-div-form-label-input">
                                <span>Make/buy code*</span>
                                {/* <div className="create-part-num-div-form-input"> */}
                                <Select styles={customStyles} options={makeByCodeOptions} onChange={(selected) => handleCodeChange(selected)} />
                                {/* <input type="text" placeholder="Part Title" value={partTitle} onChange={(e) => handlePartTitle(e)} /> */}
                                {/* </div> */}
                            </div>

                            {attributeOptions.data.map((item, index) => (
                                <div className="create-part-num-div-form-label-input" key={index}>
                                    <span>{item.title}*</span>
                                    {/* <div className="create-part-num-div-form-input"> */}
                                    {/* Render your Select component here */}
                                    <Select
                                        styles={customStyles}
                                        options={item.options.map(option => ({
                                            value: option,
                                            label: option,
                                        }))}
                                        placeholder={`Select ${item.title}`}
                                        value={selectedValues[item.title] ? { value: selectedValues[item.title], label: selectedValues[item.title] } : null}
                                        onChange={(selected) => handlemeasureChange(selected, item.title)}
                                    />

                                    {/* </div> */}
                                </div>
                            ))}
                            <div className="create-part-num-div-form-label-input">
                                <span>Select Design</span>
                                <DesignDropdown field={{
                                    placeholder: 'Select design',
                                    design
                                }} handleDesignSelectChange={handleDesignSelectChange} />
                            </div>

                        </div>


                        <div className="create-part-num-div-form-label-attahment">
                            <span>Attachments*</span>
                            <div
                                onDrop={handleFileDrop}
                                onDragOver={handleDragOver}
                                className="filedropzone"
                            >
                                <img src={`${ASSET_PREFIX_URL}uploading-icon.svg`} alt="" />
                                {isLoading ? <p>Uploading ....</p> :
                                    <p>Drop files to attach or<span onClick={upload}> Browse</span></p>}

                            </div>
                            <input
                                type="file"
                                id="fileupld"
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                                className="btn-upload"
                                multiple
                            />
                        </div>
                        {!uploadError && <div className="error">{uploadError}</div>}
                        {fileSizeError && <div className="error"><span className="error-message"
                            style={{ color: '#FF9845' }}><ErrorOutlineOutlinedIcon /> {fileSizeError}</span></div>}
                        <AttachmentsFunction uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} type='form-upload' />


                    </div>}
                    {openSecondStep && <EcPartsForm typeEC='part-create' selectedParts={selectedParts} setSelectedParts={setSelectedParts}
                        handlePrevStepToParts={handlePrevStepToParts} onClose={handleClose}  />}

                    <div className='create-part-num-div-btns'>
                        {!openSecondStep && <button onClick={handleOpenSecondStep}>Next</button>}
                        {openSecondStep && <button onClick={handleOpenSecondStep}>Prev</button>}
                        {openSecondStep && <button className="dept-form-submit" onClick={handleCreate}>
                            Create
                        </button>}
                        <button onClick={handleClose}>Cancel</button>
                    </div>
                </div>
            </div>

        </>

    )
}

export default CreatePartBOM