import React from 'react'
import './Loading.css'

function FileUplaoding() {
    return (
        <div>
            <div class="file-loading">
                <p>Please wait uploading your files...</p>
                <span><i></i><i></i></span>
            </div>

        </div>

    )
}

export default FileUplaoding