import React, { useState } from 'react';
import './PartPreview.css'
import Select from 'react-select'
import { ASSET_PREFIX_URL, DASHBOARD, PARTVIEW } from '../../constants/config'
import AssemblyParts from './AssemblyParts'
import BreadcrumbsNav from '../Common/BreadcrumbsNav'
import GridOnIcon from '@mui/icons-material/GridOn';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom'
import DimensionCube from './DimensionCube'

function PartDesignPreview({ children, filekey, handleRotate, handleZoomIn, handleZoomOut }) {
    const [openAssembly, setOpenAssembly] = useState(false)
    const [buttonPosition, setButtonPosition] = useState({ top: '90%', left: '50%' });
    const [openToolBar, setOpenToolBar] = useState(false)
    const [openExplode, setOpenExplode] = useState(false)
    const buttonStyle = {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '20px',
        color: 'white', // Set text color to white for visibility
        cursor: 'pointer',
        outline: 'none',
    };

    const containerStyle = {
        position: 'absolute', // Make the container overlay the canvas
        top: '10px', // Adjust position as needed
        right: '10px', // Adjust position as needed
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'red', // Red background for the container
        padding: '20px',
        borderRadius: '10px',
        zIndex: 10, // Ensure this is above the canvas
    };

    const handleOpenToolBar = () => {
        setButtonPosition({ top: '90%', left: '50%' })
        setOpenToolBar(!openToolBar)
    }
    const nav = useNavigate()
    const customStyles = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',  // Sets the minimum height of the control to 30px
            height: '30px',     // Sets the fixed height of the control to 30px
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '30px',    // Adjusts the height of the value container as well
            padding: '0 6px',  // Optional padding adjustment for the value container
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',     // Removes extra margin inside the input
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '30px',    // Sets the height of the indicators (like the dropdown arrow)
        }),
    };

    const handleDragStart = (e) => {
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = (e) => {
        // Set the new button position based on where it's dropped
        const newPosition = {
            top: `${e.clientY}px`,
            left: `${e.clientX}px`,
        };
        setButtonPosition(newPosition);
    };
    return (
        <div className='part-preview-page'>
            <div className='part-preview-page-top'>
                <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                    <img src={`${ASSET_PREFIX_URL}marathon-logo.svg`} style={{ cursor: 'pointer' }} onClick={() => nav(DASHBOARD)} />
                    <BreadcrumbsNav
                        links={[
                            { label: "Part view", href: PARTVIEW(filekey) },
                        ]}
                        current={'pen part'}
                    />
                </div>
                <div className='part-preview-page-top-right'>
                    <div className='part-preview-page-top-right'>
                        <Select
                            placeholder='Download'
                            styles={customStyles} // Apply the custom styles
                            options={[
                                { value: 'option1', label: 'Option 1' },
                                { value: 'option2', label: 'Option 2' },
                            ]}
                        />
                        {/* <button>Download</button> */}
                    </div>


                </div>
            </div>
            <div className='part-preview-page-bottom'>
                <div className='part-preview-sidebar' onClick={() => setOpenAssembly(!openAssembly)}>
                    <div className='part-preview-sidebar-icons'>
                        <img src={`${ASSET_PREFIX_URL}production_icon.svg`} alt='assembly' />
                    </div>
                </div>
                <div className='dimension-cube' style={containerStyle}>
                    {/* Up Arrow */}
                    <div style={{ marginBottom: '10px' }}>
                        <button style={buttonStyle} onClick={() => handleRotate('up')}>▲</button>
                    </div>

                    {/* Left and Right Arrows */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px' }}>
                        <button style={buttonStyle} onClick={() => handleRotate('left')}>◀</button>
                        <button style={{ ...buttonStyle, marginLeft: '20px' }} onClick={() => handleRotate('right')}>▶</button>
                    </div>

                    {/* Down Arrow */}
                    <div style={{ marginBottom: '20px' }}>
                        <button style={buttonStyle} onClick={() => handleRotate('down')}>▼</button>
                    </div>

                    {/* Zoom Controls */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                        <button style={buttonStyle}  onClick={handleZoomOut} >-</button>
                        <button style={buttonStyle} onClick={handleZoomIn}>+</button>
                    </div>
                </div>
                {openExplode && <input type='range' className='range-bar' />}
                {openAssembly && <AssemblyParts onclose={() => setOpenAssembly(!openAssembly)} />}

                {children}

                {!openToolBar && <div
                    className='draggable-button'
                    draggable
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    style={{
                        position: 'absolute',
                        top: buttonPosition.top,
                        left: buttonPosition.left,
                        cursor: 'move',
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: '5'
                    }}
                >
                    {/* <button>Download</button> */}
                    <div className='draggable-buttons'>
                        <button title='Reset'><FlipCameraAndroidIcon /></button>
                        <button title='Grid view'><GridOnIcon /></button>
                        <button title='Box measurements'><ViewInArIcon /></button>
                        <button title='Explode assembly' onClick={() => setOpenExplode(!openExplode)}><ViewInArIcon /></button>
                        <button title='Close' onClick={() => setOpenToolBar(!openToolBar)} style={{ width: '20px', borderLeft: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><KeyboardArrowRightIcon /></button>                    </div>
                    <span style={{ marginLeft: '5px', cursor: 'move' }}>⠿</span> {/* Drag Icon */}
                </div>}

                {openToolBar && <div className='expand-button' onClick={handleOpenToolBar}>
                    <button title='Expand' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><ArrowBackIosNewIcon /></button>
                </div>}

            </div>
        </div>
    )
}

export default PartDesignPreview