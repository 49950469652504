import React, { useState, useEffect } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import "./Department.css";
import axios from "axios";
import { BASE_URL, ASSET_PREFIX_URL, HEADERS } from "../../constants/config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import DepartmentForm from './DepartmentForm';
import { toast } from 'react-toastify';


function DepartmentTicketPopup({ onClose,toastStyle,type }) {
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [options, setOptions] = useState([]); // State to store the department options
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [departmentId,setDepartmentId] = useState('')
  
  const handleOpenPopup = () => {
    if (selectedOption) {
    
      setDepartmentId(selectedOption.id)
      // Add code to proceed to the next step or perform other actions here
    } else {
     toast.info("Please select a department before proceeding", toastStyle);
    }
      setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
      setIsPopupOpen(false);
    };
  const nav = useNavigate();

  useEffect(() => {
    fetchDepartList();
  }, []);
  const fetchDepartList = async () => {
    try {
      const headers = HEADERS;
      const response = await axios.get(BASE_URL + "/v1/depart/get-template-departments", { headers: headers });
     
      const departmentOptions = response.data.data.templateDepartments.map((item) => ({
        value: item.departmentNames,
        label: item.departmentNames,
        id: item.department_id
      }))
      setOptions(departmentOptions)
    
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };
  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    setIsOptionSelected(!!selected);
   
  };
 
  const customStyles = {
    menu: (provided) => ({
      ...provided,

      overflowY: "auto",
      overflow: "hidden", // Disable the default browser scrollbar
    }),
    menuList: (provided) => ({
      ...provided,
      "::-webkit-scrollbar": "auto",
      "::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "8px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "transparent" : "white", // Set the background color
      color: state.isSelected ? "black" : "inherit", // Set the text color
    }),
  };
  return (
    <>
     <div className='dept-ticket-page' >
      <div className='dept-ticket-container'>
        <div className='dept-ticket-top'>
          <div className='dept-ticket-head'>
            <span>Create new {type!=='template'&& 'EC'} ticket</span>
            <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
          </div>
          <div className='dept-ticket-steps'>
          {!isPopupOpen &&<span>step 1 of 2</span>}
          {isPopupOpen &&<span>step 2 of 2</span>}
            
          </div>
          <div>
          {!isPopupOpen &&<img src={`${ASSET_PREFIX_URL}ticket-step1.svg`} alt='' width='100%' />}
          {isPopupOpen &&<img src={`${ASSET_PREFIX_URL}ticket-step2.svg`} alt='' width='100%' />}
          </div>
        </div>
        <div className='dept-ticket-steps-track'></div>
        {!isPopupOpen && <>
          <div className='dept-ticket-select'>
          <span>Department</span>
          <Select
            styles={customStyles}
            className='dept-select'
            options={options}
            value={selectedOption}
            onChange={handleSelectChange}
          />
        </div>
        <div className='dept-ticket-btns'>
          <button className={`dept-ticket-btns-next ${isOptionSelected ? 'selected' : ''}`} onClick={handleOpenPopup} >Next</button>
          <button className='dept-ticket-btns-cancel' onClick={onClose}>Cancel</button>
        </div>
        </>}
        {isPopupOpen && <DepartmentForm onClose={handleClosePopup} departmentId={departmentId}/>}
      </div>
    </div>
    
    </>

  )
}

export default DepartmentTicketPopup
